import {
    JpTableV1,
    JpCurrencyNumber,
    dateLocalizer,
    JpText,
    JpDatePicker,
    parseMinutesToHours,
    HasPermissionModules,
    JpAvatar,
    JpFilterButtons,
    JpTableTitleName,
    JpPopover,
    JpButton,
    JpAvatarGroup,
    SweetModal
} from '@Intelli/utilities'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { Col, Row, Form } from 'reactstrap'
import moment from 'moment-timezone'
import {
    leftButtonStyleNew,
    rightButtonStyle,
    rightButtonStyleNew
} from '@src/constants/styles/borderButtonsCustom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import HoursGraphs from './HoursGraphs'
import { useDispatch } from 'react-redux'
import { parseName } from '@src/utility/Utils'
import { addBreadcrumbs } from '@src/redux/breadcrumbs'
import useSocket from '@src/hooks/useSocket'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import IconEditedWithTooltip from '@src/components/general/IconEditedWithTooltip'
import JpInputControllerCustom from '@src/components/input/JpInputControllerCustom'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'
import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'
import hoursStates from '../../constants/states/hours/hoursState'
import HourInput from '@src/components/input/InputHours'

const getHoursMinutesFromMinutes = minutes => {
    const duration = moment.duration(minutes, 'minutes')
    let hours = Math.floor(duration.asHours())
    let mins = Math.floor(duration.minutes())

    if (hours === 0 && mins === 0) {
        return '00:00'
    } else if (hours > 99 && mins < 99) {
        mins = mins + 60 * (hours - 99)
        hours = 99
    }

    const formattedHours = String(hours).padStart(2, '0')
    const formattedMinutes = String(mins).padStart(2, '0')

    return `${formattedHours}:${formattedMinutes}`
}

const TabActions = ({
    start_date,
    setStartDate,
    end_date,
    setEndDate,
    setCurrentPage,
    JpFilterButtons
}) => {
    return (
        <Row className="align-items-center justify-content-start">
            <Col
                sm="5"
                md="4"
                lg="3"
                xl="3"
                className=" d-flex table-filters"
            >
                <JpDatePicker
                    id="contractStartDate"
                    value={start_date}
                    setPicker={date => {
                        setCurrentPage(0)
                        setStartDate(date.format('YYYY-MM-DD'))
                    }}
                    maxDate={end_date}
                    className={'enabled-joobpay'}
                />

                <JpDatePicker
                    id="contractEndDate"
                    value={end_date}
                    minDate={moment(start_date).format('YYYY-MM-DD')}
                    maxDate={moment().format('YYYY-MM-DD')}
                    setPicker={date => {
                        setCurrentPage(0)
                        setEndDate(date.format('YYYY-MM-DD'))
                    }}
                    className={'enabled-joobpay ms-1'}
                />
            </Col>
            <Col
                sm="9"
                className="d-flex justify-content-center justify-content-md-end table-filters mt-1 mt-md-0"
            >
                {JpFilterButtons}
            </Col>
        </Row>
    )
}

const HoursDays = () => {
    const { modules } = useApplicationModules()
    const [params, setSearchParams] = useSearchParams()
    const [start_date, setStartDate] = useState(
        !!params.get('start_date')
            ? moment(params.get('start_date')).format('YYYY-MM-DD')
            : moment().subtract(15, 'day').format('YYYY-MM-DD')
    )
    const [end_date, setEndDate] = useState(
        !!params.get('end_date')
            ? moment(params.get('end_date')).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
    )
    const [currentPage, setCurrentPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [queries, setQueries] = useState('')
    const [sortBy, setSortBy] = useState('date__desc')
    const [statusType, setStatusType] = useState(
        !!params.get('filter') ? params.get('filter') : ''
    )
    const [idEditable, setIdEditable] = useState('')
    const [idEditableExtra, setIdEditableExtra] = useState('')
    const [hasHoursWrite, setHasHoursWrite] = useState(
        HasPermissionModules.WriteUpdate(modules, ['TIME', 'TIME-HOURS'])
    )
    const [hour, setHour] = useState('')

    const dispatch = useDispatch()
    const API = new AdminAPI()
    const { t } = useTranslation()
    const { id } = useParams()
    const queryClient = useQueryClient()

    const {
        data: dataProfile,
        status,
        refetch
    } = useQuery(['profile', id], () => API.getContractor(id), {
        onSuccess: data => {
            dispatch(
                addBreadcrumbs({
                    id,
                    value: parseName(
                        data.user.first_name || data.email,
                        data.user.last_name
                    )
                })
            )
        }
    })

    const {
        data: hoursData,
        isLoading: hoursLoading,
        refetch: hoursDataRefetch
    } = useQuery(
        [
            'hours-detailed',
            currentPage,
            limit,
            start_date,
            end_date,
            queries,
            sortBy,
            statusType
        ],
        () =>
            API.getTimeHoursContractors({
                offset: currentPage,
                limit,
                date__between: `${start_date},${end_date}`,
                contractor_id: id,
                query: queries,
                order: sortBy,
                [statusType.split(',')[0]]: statusType.split(',')[1]
            }),
        {
            keepPreviousData: true
        }
    )

    useEffect(() => {
        setIdEditable('')
        setIdEditableExtra('')
    }, [queries, start_date])

    const isInRange = date => {
        if (!date) return false
        return (
            moment(date) <= moment(end_date) &&
            moment(date) >= moment(start_date)
        )
    }

    useSocket(
        {
            [`/time/contractors/${id}/workday/hours/edited`]: {
                fn: value => {
                    isInRange(value.payload.date) && hoursDataRefetch()
                }
            }
        },
        [end_date, start_date]
    )

    useSocket(
        disabledRefetchSocket(end_date)
            ? {
                  [`/time/contractors/${id}/workday`]: {
                      fn: value => {
                          hoursDataRefetch()
                      }
                  }
              }
            : {},
        [end_date, start_date]
    )

    const SignupSchema = yup.object().shape({
        worked_hours_extra: yup.lazy(() => {
            if (idEditableExtra !== '') {
                return yup.string().required()
            }
            return yup.mixed().notRequired()
        }),
        worked_hours: yup.lazy(() => {
            if (idEditable !== '') {
                return yup.string().required()
            }
            return yup.mixed().notRequired()
        })
    })
    const {
        control,
        reset,
        setValue,
        handleSubmit,
        watch,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(SignupSchema)
    })

    const { mutate: editTimes } = useMutation(data => API.editTimes(data), {
        onSuccess: () => {
            setIdEditable('')
            setIdEditableExtra('')
            reset()
            hoursDataRefetch()
            queryClient.invalidateQueries(['time-extra'])
        }
    })

    const onSubmit = (data, time_type, time_id) => {
        const hoursSplit = hour.split(' ')
        const minutesTransform = hoursSplit
            .find(item => item.includes('m'))
            .replace(/[a-zA-Z]/g, '')

        if (minutesTransform > 59) {
            return SweetModal(
                'warning',
                t('attention'),
                t('views.time.minutesError'),
                t('Ok')
            )
        }

        const timeParsedToSend = hoursSplit
            .map(val => {
                const valueInNumber = val
                    .replace(/[a-zA-Z]/g, '')
                    .replace(/_/g, '')
                if (val.includes('h')) {
                    return Number(valueInNumber) * 60
                }
                return Number(valueInNumber)
            })
            .reduce((a, b) => a + b, 0)

        const dataToSend = {
            data: {
                [time_type]: timeParsedToSend,
                note: data.reason
            },
            id: time_id
        }
        editTimes(dataToSend)
    }

    const handleClickHrs = (name, id, time, note) => {
        if (name === 'worked_hours') {
            setIdEditable(id)
            setIdEditableExtra('')
        } else {
            setIdEditableExtra(`extra-${id}`)
            setIdEditable('')
        }

        setValue(name, parseMinutesToHours(time, true))
        setValue('reason', note)
    }

    const tabCols = [
        {
            sortable: false,
            width: '20px',
            cell: row => (
                <div className="d-flex align-items-end">
                    <JpAvatar
                        color={`light-primary`}
                        content={row?.date && `${moment(row?.date).date()}`}
                        imgClassName="avatar-cover cursor-default"
                    />
                </div>
            )
        },
        {
            name: `${t('views.documents.documentsTable.date')}`,
            sortable: 'date',
            minWidth: '120px',
            cell: row => (
                <div className="d-flex align-items-center justify-content-center user-info">
                    {row?.date ? (
                        <Link
                            to={`/admin/time/hours/${row?.contractor?.id}/days/details?date=${row?.date}`}
                            className="d-block fw-bold text-truncate "
                        >
                            {dateLocalizer.getMomentMediumDateWithDayWeek(row?.date)}
                        </Link>
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            name: `${t('views.time.firstMarking')}`,
            sortable: 'clock_in',
            grow: 1,
            cell: row => (
                <div className="d-flex align-items-center justify-content-center">
                    {row?.clock_in ? (
                        <JpText
                            type="span-table-date"
                            children={dateLocalizer.getIntlShortTime(
                                row?.clock_in
                            )}
                        />
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            name: t('views.time.lastMarking'),
            grow: 1,
            sortable: 'clock_out',
            cell: row => (
                <div className="d-flex align-items-center justify-content-center">
                    {row?.clock_out ? (
                        <JpText
                            type="span-table-date"
                            children={dateLocalizer.getIntlShortTime(
                                row?.clock_out
                            )}
                        />
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.time.regulars')}
                    tooltipText={t('views.time.regularHours')}
                />
            ),
            sortable: 'time_ordinary',
            minWidth: '210px',
            cell: row => {
                return (
                    <Col className="d-flex align-items-center hover-div-button">
                        {row?.contractor_day_id === idEditable ? (
                            <Col
                                className="d-flex align-items-center justify-content-center"
                                tag={Form}
                            >
                                <JpInputControllerCustom
                                    control={control}
                                    name="worked_hours"
                                    title={``}
                                    errors={errors}
                                    customInput={
                                        /* <Cleave
                                            id="timepicker"
                                            className="form-control"
                                            placeholder="00:00"
                                            value={getHoursMinutesFromMinutes(
                                                row?.time_ordinary
                                            )}
                                            options={{
                                                // time: true,
                                                // timePattern: ['h', 'm']
                                                blocks: [2, 2],
                                                delimiter: ':',
                                                numericOnly: true
                                            }}
                                            onChange={e => {
                                                setHour(e.target.value)
                                            }}
                                        />*/
                                        <HourInput
                                            newHour={getHoursMinutesFromMinutes(
                                                row?.time_ordinary
                                            )}
                                            id={idEditable}
                                            setNewHour={setHour}
                                        />
                                    }
                                    style={{
                                        ...rightButtonStyle,
                                        border: '1px solid #ced4da',
                                        outline: 'none !important',
                                        boxShadow: 'none'
                                    }}
                                    className={'no-outline'}
                                    customButtons={
                                        <>
                                            <JpButton
                                                type="add"
                                                size="xl"
                                                color="white"
                                                id="button-reason"
                                                className="py-25 px-50 d-flex align-items-center rounded-0 border"
                                                options={{
                                                    textLess: true,
                                                    customIcon: (
                                                        <FontAwesomeIcon icon="fa-note-sticky"></FontAwesomeIcon>
                                                    )
                                                }}
                                            />
                                            <JpPopover
                                                target={'button-reason'}
                                                popoverTitle={`${t(
                                                    'views.documentation.list.actions.motive'
                                                )}`}
                                                popoverBody={
                                                    <>
                                                        <JpInputControllerCustom
                                                            placeholder={t(
                                                                'views.documentation.list.actions.motiveChange'
                                                            )}
                                                            control={control}
                                                            name="reason"
                                                            title={``}
                                                            errors={errors}
                                                            type="text"
                                                            additionalComponent={
                                                                watch('reason')
                                                                    ?.length >
                                                                    0 && (
                                                                    <span
                                                                        className={`textarea-counter-value float-end me-0 ${
                                                                            watch(
                                                                                'reason'
                                                                            )
                                                                                .length >
                                                                            250
                                                                                ? 'bg-warning'
                                                                                : 'bg-info'
                                                                        }`}
                                                                    >
                                                                        {`${
                                                                            getValues(
                                                                                'reason'
                                                                            )
                                                                                ?.length
                                                                        }/250`}
                                                                    </span>
                                                                )
                                                            }
                                                        />
                                                    </>
                                                }
                                            />
                                            <div className="d-flex flex-column">
                                                <JpButton
                                                    type="add"
                                                    size="xl"
                                                    color="danger"
                                                    style={rightButtonStyleNew}
                                                    className="p-25 d-flex align-items-center justify-content-center border border-start-0"
                                                    options={{
                                                        onClick: () => {
                                                            setIdEditable('')
                                                        },
                                                        textLess: true,
                                                        customIcon: (
                                                            <FontAwesomeIcon icon="fa-regular fa-xmark"></FontAwesomeIcon>
                                                        )
                                                    }}
                                                />
                                                <JpButton
                                                    type="add"
                                                    size="xl"
                                                    color="green"
                                                    style={leftButtonStyleNew}
                                                    className="p-25 d-flex align-items-center justify-content-center border border-start-0"
                                                    options={{
                                                        onClick: handleSubmit(
                                                            data =>
                                                                onSubmit(
                                                                    data,
                                                                    'time_ordinary',
                                                                    row?.time_id
                                                                )
                                                        ),
                                                        textLess: true,
                                                        customIcon: (
                                                            <FontAwesomeIcon icon="fa-regular fa-check"></FontAwesomeIcon>
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                />
                            </Col>
                        ) : (
                            <>
                                {row?.time_log_ordinary ? (
                                    <IconEditedWithTooltip
                                        id={`ordinary-${row?.contractor_day_id}`}
                                        text={parseMinutesToHours(
                                            row?.time_ordinary
                                        )}
                                        first_name={
                                            row?.time_log_ordinary?.session
                                                ?.user?.first_name
                                        }
                                        last_name={
                                            row?.time_log_ordinary?.session
                                                ?.user?.last_name
                                        }
                                        created_at={dateLocalizer.getIntlLongDate(
                                            row?.time_log_ordinary?.created_at
                                        )}
                                        note={row?.time_log_ordinary?.note}
                                        work_time={parseMinutesToHours(
                                            row?.times_difference.worktime
                                                .ordinary
                                        )}
                                        difference={
                                            Number(
                                                row?.times_difference.difference
                                                    .ordinary || '0'
                                            ) * -1
                                        }
                                    />
                                ) : (
                                    <JpText
                                        type="span-table-text"
                                        children={parseMinutesToHours(
                                            row?.time_ordinary
                                        )}
                                    />
                                )}
                            </>
                        )}
                        {row?.contractor_day_id !== idEditable &&
                            hasHoursWrite && (
                                <>
                                    {row.time_id && (
                                        <FontAwesomeIcon
                                            onClick={() => {
                                                handleClickHrs(
                                                    'worked_hours',
                                                    row?.contractor_day_id,
                                                    row?.time_ordinary,
                                                    row?.time_log_ordinary?.note
                                                )
                                                /* setIdEditable(
                                                    row?.contractor_day_id
                                                )
                                                setIdEditableExtra('')
                                                setValue(
                                                    'worked_hours',
                                                    parseMinutesToHours(
                                                        row?.time_ordinary,
                                                        true
                                                    )
                                                )
                                                setValue(
                                                    'reason',
                                                    row?.time_log_ordinary?.note
                                                )
                                                setTimeout(() => {
                                                    document
                                                        .getElementById(
                                                            'timepicker'
                                                        )
                                                        .click()
                                                }, 100) */
                                            }}
                                            color="white"
                                            className="bg-primary p-25 ms-1 rounded cursor-pointer hover-button"
                                            icon="fa-solid fa-pen"
                                        ></FontAwesomeIcon>
                                    )}
                                    {!row?.time_ordinary_approved &&
                                        row?.time_ordinary > 0 && (
                                            <JpButton
                                                type="add"
                                                color="green"
                                                size="sm"
                                                style={leftButtonStyleNew}
                                                className="bg-green p-25 ms-25 rounded cursor-pointer hover-button"
                                                options={{
                                                    onClick: () =>
                                                        editTimes({
                                                            data: {
                                                                time_ordinary:
                                                                    Math.round(
                                                                        row?.time_ordinary
                                                                    )
                                                            },
                                                            id: row?.time_id
                                                        }),
                                                    textLess: true,
                                                    customIcon: (
                                                        <FontAwesomeIcon
                                                            color="white"
                                                            icon="fa-solid fa-check"
                                                        ></FontAwesomeIcon>
                                                    )
                                                }}
                                            />
                                        )}
                                </>
                            )}
                    </Col>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.time.hrsExtra')}
                    tooltipText={t('views.time.hrsExtra')}
                />
            ),
            minWidth: '210px',
            sortable: 'time_overtime',
            cell: row => (
                <>
                    <Col className="d-flex align-items-center hover-div-button">
                        {`extra-${row?.contractor_day_id}` ===
                        idEditableExtra ? (
                            <Col
                                className="d-flex align-items-center justify-content-center"
                                tag={Form}
                            >
                                <JpInputControllerCustom
                                    control={control}
                                    name="worked_hours_extra"
                                    title={''}
                                    errors={errors}
                                    customInput={
                                        <HourInput
                                                newHour={getHoursMinutesFromMinutes(
                                                    row?.time_overtime
                                                )}
                                                id={idEditableExtra}
                                                setNewHour={setHour}
                                            />
                                    }
                                    style={{
                                        ...rightButtonStyle,
                                        border: '1px solid #ced4da',
                                        outline: 'none !important',
                                        boxShadow: 'none'
                                    }}
                                    className={'no-outline'}
                                    customButtons={
                                        <>
                                            <JpButton
                                                type="add"
                                                size="xl"
                                                color="white"
                                                id="button_extra"
                                                className="py-25 px-50 d-flex align-items-center rounded-0 border"
                                                options={{
                                                    textLess: true,
                                                    customIcon: (
                                                        <FontAwesomeIcon icon="fa-regular fa-note-sticky"></FontAwesomeIcon>
                                                    )
                                                }}
                                            />
                                            <JpPopover
                                                target={'button_extra'}
                                                popoverTitle={`${t(
                                                    'views.documentation.list.actions.motive'
                                                )}`}
                                                popoverBody={
                                                    <>
                                                        <JpInputControllerCustom
                                                            placeholder={t(
                                                                'views.documentation.list.actions.motiveChange'
                                                            )}
                                                            control={control}
                                                            name="reason"
                                                            title={``}
                                                            errors={errors}
                                                            type="text"
                                                            additionalComponent={
                                                                watch('reason')
                                                                    ?.length >
                                                                    0 && (
                                                                    <span
                                                                        className={`textarea-counter-value float-end me-0 ${
                                                                            watch(
                                                                                'reason'
                                                                            )
                                                                                .length >
                                                                            250
                                                                                ? 'bg-warning'
                                                                                : 'bg-info'
                                                                        }`}
                                                                    >
                                                                        {`${
                                                                            getValues(
                                                                                'reason'
                                                                            )
                                                                                ?.length
                                                                        }/250`}
                                                                    </span>
                                                                )
                                                            }
                                                        />
                                                    </>
                                                }
                                            />
                                            <div className="d-flex flex-column">
                                                <JpButton
                                                    type="add"
                                                    size="xl"
                                                    color="danger"
                                                    style={rightButtonStyleNew}
                                                    className="p-25 d-flex align-items-center justify-content-center border border-start-0"
                                                    options={{
                                                        onClick: () => {
                                                            setIdEditableExtra(
                                                                ''
                                                            )
                                                        },
                                                        textLess: true,
                                                        customIcon: (
                                                            <FontAwesomeIcon icon="fa-regular fa-xmark"></FontAwesomeIcon>
                                                        )
                                                    }}
                                                />
                                                <JpButton
                                                    type="add"
                                                    size="xl"
                                                    color="green"
                                                    style={leftButtonStyleNew}
                                                    className="p-25 d-flex align-items-center justify-content-center border border-start-0"
                                                    options={{
                                                        onClick: handleSubmit(
                                                            data =>
                                                                onSubmit(
                                                                    data,
                                                                    'time_overtime',
                                                                    row?.time_id
                                                                )
                                                        ),
                                                        textLess: true,
                                                        customIcon: (
                                                            <FontAwesomeIcon icon="fa-regular fa-check"></FontAwesomeIcon>
                                                        )
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                />
                            </Col>
                        ) : (
                            <>
                                {row?.time_log_overtime ? (
                                    <IconEditedWithTooltip
                                        id={`extra-${row?.contractor_day_id}`}
                                        text={parseMinutesToHours(
                                            row?.time_overtime
                                        )}
                                        first_name={
                                            row?.time_log_overtime?.session
                                                ?.user?.first_name
                                        }
                                        last_name={
                                            row?.time_log_overtime?.session
                                                ?.user?.last_name
                                        }
                                        created_at={dateLocalizer.getIntlLongDate(
                                            row?.time_log_overtime?.created_at
                                        )}
                                        note={row?.time_log_overtime?.note}
                                        work_time={parseMinutesToHours(
                                            row?.times_difference.worktime
                                                .overtime
                                        )}
                                        difference={
                                            Number(
                                                row?.times_difference.difference
                                                    .overtime || '0'
                                            ) * -1
                                        }
                                    />
                                ) : (
                                    <JpText
                                        type="span-table-text"
                                        children={parseMinutesToHours(
                                            row?.time_overtime
                                        )}
                                    />
                                )}
                            </>
                        )}
                        {`extra-${row?.contractor_day_id}` !==
                            idEditableExtra &&
                            hasHoursWrite && (
                                <>
                                    {row.time_id && (
                                        <FontAwesomeIcon
                                            onClick={() => {
                                                handleClickHrs(
                                                    'worked_hours_extra',
                                                    row?.contractor_day_id,
                                                    row?.time_overtime,
                                                    row?.time_log_overtime?.note
                                                )
                                                
                                            }}
                                            color="white"
                                            className="bg-primary p-25 ms-1 rounded cursor-pointer hover-button"
                                            icon="fa-solid fa-pen"
                                        ></FontAwesomeIcon>
                                    )}
                                    {!row?.time_overtime_approved &&
                                        row?.time_overtime > 0 && (
                                            <JpButton
                                                type="add"
                                                color="green"
                                                size="sm"
                                                style={leftButtonStyleNew}
                                                className="bg-green p-25 ms-25 rounded cursor-pointer hover-button"
                                                options={{
                                                    onClick: () =>
                                                        editTimes({
                                                            data: {
                                                                time_overtime:
                                                                    Math.round(
                                                                        Number(
                                                                            row?.time_overtime
                                                                        ) * 100
                                                                    ) / 100
                                                            },
                                                            id: row?.time_id
                                                        }),
                                                    textLess: true,
                                                    customIcon: (
                                                        <FontAwesomeIcon
                                                            color="white"
                                                            icon="fa-solid fa-check"
                                                        ></FontAwesomeIcon>
                                                    )
                                                }}
                                            />
                                        )}
                                </>
                            )}
                    </Col>
                </>
            )
        },
        {
            name: t('views.invoices.amount'),
            minWidth: '140px',
            sortable: 'fee',
            cell: row => (
                <>
                    {row?.fee &&
                    ['CLASSIC', 'TIME'].includes(
                        row?.contractor?.contract_type?.name
                    ) ? (
                        <>
                            <JpCurrencyNumber value={row?.fee} />
                        </>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.state')}
                    tooltipText={t('views.time.workStatus')}
                />
            ),
            sortable: false,
            cell: (row, i) => {
                return (
                    <>
                        {row?.state?.length === 0 && '-'}

                        <JpAvatarGroup
                            key={row?.contractor_day_id}
                            data={row?.state
                                ?.slice(0, 4)
                                .sort((a, b) => b.priority - a.priority)
                                .map((state, index) => {
                                    const color =
                                        hoursStates[state?.value].color
                                    const icon =
                                        hoursStates[state?.value].iconAvatar

                                    if (index === 3) {
                                        return {
                                            content: `+${
                                                row?.state?.length - 3
                                            }`,
                                            id: `state-${i}-${index}`,
                                            color: color,
                                            imgClassName: 'shadow-none'
                                        }
                                    }
                                    return {
                                        title: t(
                                            `views.time.states.${state?.value}`
                                        ),
                                        icon: <i className={icon}></i>,
                                        color: color,
                                        id: `state-${i}-${index}`,
                                        imgClassName: 'shadow-none'
                                    }
                                })}
                        />
                    </>
                )
            }
        }
    ]

    const filters = [
        {
            icon: 'fa-solid fa-calendar-exclamation',
            color: 'light-info',
            state: 'state__in,ABSENT',
            text: t('views.time.daysAbsences')
        },
        {
            icon: 'fa-solid fa-calendar-lines',
            color: 'light-primary',
            state: 'time_overtime__gt,0',
            text: t('views.time.daysOvertime')
        },
        {
            icon: 'fa-solid fa-calendar-day',
            color: 'light-warning',
            state: 'state__in,PERMISSION',
            text: t('views.time.daysPermissions')
        },
        {
            icon: 'fa-solid fa-calendar-pen',
            color: 'light-success',
            state: 'is_locked,true',
            text: t('views.time.daysHoursEdited')
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    return (
        <>
            <HoursGraphs date={start_date} endDate={end_date} />
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={hoursData?.data}
                total={hoursData?.count}
                loading={hoursLoading}
                offset={currentPage}
                actions={
                    <TabActions
                        start_date={start_date}
                        setStartDate={setStartDate}
                        end_date={end_date}
                        setEndDate={setEndDate}
                        setCurrentPage={setCurrentPage}
                        JpFilterButtons={
                            <JpFilterButtons
                                filters={filters}
                                state={statusType}
                                setStatusType={setStatusType}
                                setCurrentPage={setCurrentPage}
                            />
                        }
                    />
                }
                search={false}
            />
        </>
    )
}

export default HoursDays
