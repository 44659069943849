import moment from "moment"

const datesInRange = (arrayDates, start_date_table, end_date_table) => {
    return arrayDates.find(
        item =>
            moment(item.start_date) <= moment(end_date_table) &&
            moment(item.end_date) >= moment(start_date_table)
    )
}

/*   
        isInRange('2023-12-01', '2023-12-31', '2024-01-01', '2024-01-10') false esta fuera del rango de la fecha buscada en el datepicker

        isInRange('2023-12-01', '2024-01-01', '2024-01-01', '2024-01-10') true, el incio no esta en el rango, pero el fin esta dentro del rango

        isInRange('2024-01-07', '2024-01-13', '2024-01-01', '2024-01-10') true, el inicio esta dentro del rango

        isInRange('2024-01-11', '2024-01-15', '2024-01-01', '2024-01-10')//false esta fuera del rango de la fecha buscada en el datepicker

 */


export default datesInRange
