import {
    JpTableActionsCol,
    JpTableV1,
    JpCurrencyNumber,
    JpAvatar,
    JpTableAction,
    dateLocalizer,
    JpText,
    JpDatePicker,
    JpTableTitleName,
    parseMinutesToHours,
    ContractorNameInfo,
    PROJECTS_STATE_COLORS
} from '@Intelli/utilities'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Badge, Col, Row, UncontrolledTooltip } from 'reactstrap'
import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { detailsIcon } from '@src/constants/icons/detailsIcon'
import PersonsProjectsTimeGraphs from './PersonProjectsTimeGraphs'

const PersonProjectsTime = () => {
    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState('')
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('start_date__asc')
    const [start_date, setStartDate] = useState('')
    const [end_date, setEndDate] = useState('')

    const { t } = useTranslation()
    const API = new AdminAPI()
    const { contractor_id, id } = useParams()
    const navigate = useNavigate()

    const {
        data: getPersonProjectsHours,
        isLoading: getPersonProjectsHoursLoading
    } = useQuery(
        [
            'getPersonProjectsHours',
            currentPage,
            limit,
            queries,
            sortBy,
            start_date,
            end_date
        ],
        () =>
            API.getPersonProjectsHours(contractor_id || id, {
                offset: currentPage,
                limit,
                query: queries,
                order: sortBy,
                start_date__gte: `${
                    start_date
                        ? moment(start_date)
                              .format('YYYY-MM-DD')
                        : ''
                }`,
                end_date__lte: `${
                    end_date ? moment(end_date).format('YYYY-MM-DD') : ''
                }`
            })
    )

    const tabCols = [
        {
            name: '',
            width: '20px',
            cell: row => (
                <div className="d-flex align-items-center">
                    <JpAvatar
                        color={`light-${
                            PROJECTS_STATE_COLORS[row?.contractor_status]
                        }`}
                        content={row?.name}
                        initials
                    />
                </div>
            )
        },
        {
            name: t('views.projects.project'),
            sortable: 'name',
            compact: true,
            minWidth: '250px',
            wrap: true,
            cell: row => (
                <>
                    <ContractorNameInfo
                        classNameContainer="d-flex flex-column user-info text-primary"
                        Tag={Link}
                        customNavigate={`/admin/projects/details/${row?.project_id}`}
                        largeName={row?.name}
                        contractor_id={row.project_id}
                    />
                </>
            )
        },
        {
            name: `${t('views.permissions.start')}`,
            sortable: 'start_date',
            cell: row => (
                <JpText type="span-table-text">
                    {row.start_date
                        ? dateLocalizer.getIntlLongDate(row.start_date)
                        : '-'}
                </JpText>
            )
        },
        {
            name: `${t('views.permissions.end')}`,
            sortable: 'end_date',
            cell: row => (
                <JpText type="span-table-text">
                    {row.end_date
                        ? dateLocalizer.getIntlLongDate(row.end_date)
                        : '-'}
                </JpText>
            )
        },
        {
            name: `${t('views.projects.hoursExecuted')}`,
            sortable: 'hours_executed',
            cell: row => (
                <>
                    {row?.hours_executed ? (
                        <>
                            <div id={`hoursExecuted-${row.project_id}`}>
                                {parseMinutesToHours(row?.hours_executed)}
                            </div>
                            {row?.hours_planned && (
                                <UncontrolledTooltip
                                    target={`hoursExecuted-${row.project_id}`}
                                >
                                    {`${t(
                                        'views.projects.planned'
                                    )} ${parseMinutesToHours(
                                        row?.hours_planned
                                    )}`}
                                </UncontrolledTooltip>
                            )}
                        </>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: `${t('views.projects.value_executed')}`,
            sortable: 'budget_executed',
            cell: row => (
                <>
                    {row?.budget_executed ? (
                        <>
                            <div id={`budget_executed-${row.project_id}`}>
                                <JpCurrencyNumber
                                    value={row?.budget_executed}
                                />
                            </div>
                            {row?.budget_planned ? (
                                <UncontrolledTooltip
                                    target={`budget_executed-${row.project_id}`}
                                >
                                    {`${t('views.projects.planned')} US$${
                                        row?.budget_planned
                                    }`}
                                </UncontrolledTooltip>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('Status')}
                    tooltipText={t('views.projects.personStatus')}
                />
            ),
            sortable: 'contractor_status',
            cell: row => (
                <>
                    {row?.contractor_status ? (
                        <Badge
                            color={
                                PROJECTS_STATE_COLORS[row?.contractor_status]
                            }
                        >
                            {t(
                                `views.projects.projectStates.${row?.contractor_status}`
                            )}
                        </Badge>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            allowOverflow: true,
            right: true,
            width: '120px',
            cell: row => {
                return (
                    <JpTableActionsCol>
                        <JpTableAction
                            icon={detailsIcon}
                            id={`details-${row.project_id}`}
                            tooltipText={t('views.historicalPayments.details')}
                            onClick={() => {
                                navigate(
                                    `/admin/projects/contractors/${contractor_id}/details/${row.project_id}?page=2`
                                )
                            }}
                            className="ms-75"
                        />
                    </JpTableActionsCol>
                )
            }
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    const TabActions = () => {
        return (
            <Row className="align-items-center justify-content-start">
                <Col
                    sm="4"
                    xl="4"
                    className="d-flex justify-content-start table-filters mt-1 mt-md-0"
                >
                    <JpDatePicker
                        clearable
                        id="contractStartDate"
                        tooltipText={t('startDate')}
                        value={start_date}
                        setPicker={date => {
                            setCurrentPage(0)
                            setStartDate(moment(date).format('YYYY-MM-DD'))
                        }}
                        customOnClear={() => {
                            setEndDate('')
                            setStartDate('')
                        }}
                        maxDate={end_date}
                        className={'enabled-joobpay'}
                    />

                    <JpDatePicker
                        clearable
                        disabled={!!!start_date}
                        id="contractEndDate"
                        tooltipText={t('endDate')}
                        value={end_date}
                        minDate={moment(start_date).format('YYYY-MM-DD')}
                        setPicker={date => {
                            setCurrentPage(0)
                            setEndDate(moment(date).format('YYYY-MM-DD'))
                        }}
                        customOnClear={() => {
                            setEndDate('')
                        }}
                        className={` ms-1 ${
                            !!!start_date ? 'disabled-bg' : ''
                        }`}
                    />
                </Col>
            </Row>
        )
    }

    return (
        <>
            <PersonsProjectsTimeGraphs
                startDate={start_date}
                endDate={end_date}
            />
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={getPersonProjectsHours?.projects}
                total={getPersonProjectsHours?.count}
                loading={getPersonProjectsHoursLoading}
                actions={<TabActions />}
            />
        </>
    )
}

export default PersonProjectsTime
