import { Controller, FormProvider, useForm } from 'react-hook-form'
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardSubtitle,
    CardText,
    CardTitle,
    Col,
    Container,
    FormFeedback,
    Input,
    InputGroup,
    InputGroupText,
    Row,
    UncontrolledTooltip
} from 'reactstrap'
import '../../../../@core/scss/react/libs/flatpickr/flatpickr.scss'
import { useTranslation } from 'react-i18next'
import AdminAPI from '@src/services/API'
import {
    BlueHeaderCard,
    SpinnerLoader,
    JpText,
    JpDatePicker,
    JpLabel,
    JpButton,
    JpModal,
    JpCountryInput,
    SweetModal
} from '@Intelli/utilities'
import { parseName } from '@src/utility/Utils'
import { useEffect, useState } from 'react'
import moment from 'moment'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from '@tanstack/react-query'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import JpAsyncSelectController from '@src/components/input/JpAsyncSelectController'
import ContractTemplatesList from '@src/components/contractors/ContractTemplatesList'
import CreateTemplateModal from '@src/components/contractors/modals/ContractTemplateModal'
import JpTextAreaController from '@src/components/input/JpTextAreaController'
import ContractClauseModal from '@src/components/contractors/modals/ContractClauseModal'
import ContractClausesList from '@src/components/contractors/ContractClausesList'
import { useParams } from 'react-router-dom'
import JpCurrencyInputController from '@src/components/input/JpCurrencyInputController'
import JpSwitchController from '@src/components/input/JpSwitchController'
import ContractLoaderModal from '@src/components/modals/ContractLoaderModal'

const SALARY_PER_TYPE = {
    TASKS: 'proyect_value',
    TIME: 'price_per_hour',
    CLASSIC: 'salary'
}

const ContractDataV2 = ({
    method,
    type,
    contractorData,
    defaultValues,
    stepper,
    setProccessData,
    renew = false,
    legalRepresentativesData,
    LRmatchesWithContractor
}) => {
    const contractSchema = type =>
        yup.object().shape({
            start_date: yup.string().required('START_DATE.required'),
            responsibilities: yup
                .string()
                .required('form.contractors.RESPONSIBILITIES.required')
                .max(1600, 'form.contractors.RESPONSIBILITIES.max'),
            position: yup
                .string()
                .required('POSITION.required')
                .max(60, 'POSITION.max'),
            salary: yup
                .number('form.contractors.SALARY.invalid')
                .transform(value => (isNaN(value) ? undefined : value))
                .positive('form.contractors.SALARY.required')
                .test('maxDigitsAfterDecimal', t('lessDecimals'), value => {
                    if (value !== undefined) {
                        const valueString = value.toString()
                        const decimalPart = valueString.split('.')[1]
                        return !decimalPart || decimalPart.length <= 3
                    }
                    return true
                })
                .required('form.contractors.SALARY.required')
                .max(99999999, 'form.contractors.SALARY.max'),
            end_date: yup.lazy(value => {
                if (type === 'TASKS') {
                    return yup.string().nullable().required('END_DATE.required')
                }
                return yup.mixed().nullable().notRequired()
            }),
            legal_representative_id: yup.lazy(() => {
                if (method === 'YOU_HIRE') {
                    return yup.object().shape({
                        value: yup
                            .string()
                            .required('form.contractors.SIGNER.required'),
                        label: yup
                            .string()
                            .required('form.contractors.SIGNER.required')
                    })
                }
                return yup.mixed().notRequired()
            }),
            payment_rule_id: yup.lazy(() => {
                if (type !== 'TASKS') {
                    return yup.object().shape({
                        value: yup
                            .string()
                            .required('form.contractors.PAYMENT_RULE.required'),
                        label: yup
                            .string()
                            .required('form.contractors.PAYMENT_RULE.required')
                    })
                }
                return yup.mixed().notRequired()
            }),
            hours_per_day: yup.lazy(() => {
                if (type === 'TIME') {
                    return yup
                        .number()
                        .transform(value => (isNaN(value) ? undefined : value))
                        .positive('HOURS_PER_DAY.required')
                        .max(24, 'HOURS_PER_DAY.max')
                        .required('HOURS_PER_DAY.required')
                }
                return yup.mixed().notRequired()
            }),
            contract_template_id: yup.lazy(() => {
                if (!renew) {
                    return yup.string().required('CONTRACT_TEMPLATE.required')
                }
                return yup.mixed().notRequired()
            }),
            productivity_profile_id: yup.lazy(() => {
                if (productivity) {
                    return yup.object().required('InputRequired').nullable()
                }
                return yup.mixed().notRequired()
            }),
            time_profile_id: yup.lazy(() => {
                if (timeTracking) {
                    return yup.object().required('InputRequired').nullable()
                }
                return yup.mixed().notRequired()
            }),
            holidays: yup.object().required('InputRequired').nullable()
        })

    const { t } = useTranslation()
    const { id } = useParams()
    const API = new AdminAPI()
    const [start_date, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [data, setData] = useState([])

    const [clauses, setClauses] = useState(contractorData?.clauses || [])
    const [createTemplateModal, setCreateTemplateModal] = useState(false)
    const [createClauseModal, setCreateClauseModal] = useState(false)
    const [temporalTemplate, setTemporalTemplate] = useState(
        localStorage.getItem('temporalTemplate')
    )
    const [refetchTemplates, setRefetchTemplates] = useState(false)
    const [timeTracking, setTimeTracking] = useState(true)
    const [productivity, setProductivity] = useState(true)
    const [hasProjects, setHasProjects] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [modalSigned, setModalSigned] = useState(false)

    const methods = useForm({
        resolver: yupResolver(contractSchema(type?.name)),
        defaultValues: defaultValues || {
            start_date: moment().format('YYYY-MM-DD'),
            end_date: null,
            responsibilities: '',
            payment_rule_id: null,
            hours_per_day: null,
            salary: 0,
            teams_id: []
        }
    })

    const {
        control,
        getValues,
        setValue,
        watch,
        handleSubmit,
        reset,
        clearErrors,
        formState: { errors },
        resetField
    } = methods

    const { data: getAvailableTeams, isSuccess: getAvailableTeamsSuccess } =
        useQuery(['getAvailableTeams'], () => API.getAvailableTeams(), {
            onSuccess: data => {
                const defaultTeam = data?.data.find(
                    item => item.default === true
                )
                defaultTeam &&
                    setValue('teams_id', [
                        {
                            value: defaultTeam.id,
                            label: defaultTeam.name
                        }
                    ])
            }
        })

    useEffect(() => {
        const data = legalRepresentativesData

        if (data) {
            if (
                contractorData?.legal_representative_id &&
                method === 'YOU_HIRE'
            ) {
                const legalRepresentative = data?.data?.find(
                    lr =>
                        lr.legal_representative_id ===
                        contractorData?.legal_representative_id
                )
                if (
                    legalRepresentative &&
                    legalRepresentative.legal_representative_id !==
                        LRmatchesWithContractor?.legal_representative_id
                ) {
                    resetField('legal_representative_id')
                    setValue('legal_representative_id', {
                        value: legalRepresentative.legal_representative_id,
                        label: parseName(
                            legalRepresentative.first_name,
                            legalRepresentative.last_name
                        )
                    })
                }
            } else if (data.count === 1) {
                resetField('legal_representative_id')
                setValue('legal_representative_id', {
                    value: data?.data[0].legal_representative_id,
                    label: parseName(
                        data?.data[0].first_name,
                        data?.data[0].last_name
                    )
                })
            }
        }
    }, [legalRepresentativesData])

    //Productivity Profile Select
    const productivityProfile = async value => {
        return await API.getProductivityProfile({
            query: value
        }).then(res =>
            res.data.map(i => ({
                value: i.id,
                label: i.name
            }))
        )
    }

    const {
        data: getProductivityProfile,
        isLoading: getProductivityProfileIsLoading
    } = useQuery(['getServicesType'], () => API.getProductivityProfile(), {
        onSuccess: ({ data }) => {
            const dfValue = data?.find(item => item.default)
            if (productivity) {
                setValue('productivity_profile_id', {
                    value: dfValue?.id,
                    label: dfValue?.name
                })
            }
        }
    })

    useEffect(() => {
        if (type?.name === 'TIME') {
            setDisabled(true)
            setTimeTracking(true)
        } else {
            setDisabled(false)
        }
    }, [type])

    const { data: paymentRulesData, isSuccess: paymentRulesIsSuccess } =
        useQuery(['paymentRules'], () => API.getPaymentRules(), {
            enabled: !!contractorData,
            onSuccess: data => {
                const paymentRuleId = contractorData?.payment_rule_id
                const paymentRules = data?.data
                const paymentRule =
                    paymentRuleId &&
                    paymentRules?.find(pr => pr.id === paymentRuleId)

                if (paymentRule && type?.name !== 'TASKS') {
                    resetField('payment_rule_id')
                    setValue('payment_rule_id', {
                        value: paymentRule.id,
                        label: t(
                            `views.newContract.steps.contract.form.payment_rules.${paymentRule.name}`
                        )
                    })
                } else {
                    const defaultPaymentRule = paymentRules?.find(
                        item => item.name === 'MONTHLY'
                    )

                    resetField('payment_rule_id')
                    setValue('payment_rule_id', {
                        value: defaultPaymentRule.id,
                        label: t(
                            `views.newContract.steps.contract.form.payment_rules.${defaultPaymentRule.name}`
                        )
                    })
                }
            }
        })

    const { data: templatesData, isSuccess: templatesIsSuccess } = useQuery(
        ['templates', type, method, refetchTemplates, contractorData],
        data =>
            API.getTemplatesList({
                contract_type_id: type.id,
                modality: method
            }),
        {
            enabled: !!type && !renew,
            onSuccess: data => {
                if (data?.data?.length) {
                    setData(data?.data)
                }
            }
        }
    )

    const { data: getHolidays } = useQuery(
        ['getHolidays', type],
        () => API.getHolidays(id),
        {
            onSuccess: ({ result }) => {
                const dfValue = result.find(item => item.default)
                if (type.name === 'TASKS') {
                    setValue('holidays', {
                        value: 'NOT_APPLY',
                        label: t('notApplicable')
                    })
                } else {
                    setValue('holidays', {
                        value: dfValue.value,
                        label: dfValue.name
                    })
                }
            }
        }
    )

    const { data: getTimeProfile } = useQuery(
        ['getTimeProfile'],
        () => API.getTimeProfile(),
        {
            onSuccess: ({ result }) => {
                const dfValue = result?.find(item => item.default)
                if (timeTracking) {
                    setValue('time_profile_id', {
                        value: dfValue?.id,
                        label: dfValue?.name
                    })
                }
            }
        }
    )

    const getTimeProfileSelect = async value => {
        return await API.getProductivityProfile({
            query: value
        }).then(res =>
            res.result.map(i => ({
                value: i.id,
                label: i.name
            }))
        )
    }

    const { data: temporalTemplatesData } = useQuery(
        ['temporalTemplates'],
        () => API.getTemplate(temporalTemplate),
        {
            enabled: !!temporalTemplate && !renew
        }
    )

    const shouldRetry = (failureCount, error) => {
        if (failureCount >= 3) return false
        if (!error.response.data.code === 'VLMD001') return true
        return true
    }

    const { mutate: setContractData, isLoading } = useMutation(
        data => API.changeContractorStatus(data),
        {
            onSuccess: data => {
                setModalSigned(false)
                localStorage.setItem('previewURL', data.previewUrl)
                stepper.next()
            },
            onError: d => {
                if (d.response.data.code === 'VLMD001') {
                    setModalSigned(false)
                    SweetModal(
                        'warning',
                        t('attention'),
                        t(`lessDecimals`),
                        t('Ok')
                    )
                }
            },
            retry: shouldRetry
        }
    )

    const setDate = (id, date) => {
        setValue(id, date ? date?.format('YYYY-MM-DD') : null)
        if (id === 'start_date') {
            setStartDate(date.format('YYYY-MM-DD'))
            setValue('end_date', date.add(1, 'day').format('YYYY-MM-DD'))
        }
    }

    const parsePaymentRuleOptions = data => {
        return data
            .sort((a, b) => a.order - b.order)
            .map(item => ({
                value: item.id,
                label: t(
                    `views.newContract.steps.contract.form.payment_rules.${item.name}`
                )
            }))
    }

    const parseLegalRepresentatives = data => {
        return data.map(item => ({
            value: item.legal_representative_id,
            label: parseName(item.first_name || item.email, item.last_name)
        }))
    }

    const handleNewClause = clause => {
        if (clauses.length === 0) {
            setClauses([...clauses, { ...clause, order: clauses.length + 1 }])
        } else {
            setClauses([
                ...clauses,
                { ...clause, order: clauses[clauses.length - 1].order + 1 }
            ])
        }
    }

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }
    }, [defaultValues])

    useEffect(() => {
        if (templatesIsSuccess && defaultValues) {
            if (!!defaultValues?.contract_template_id) {
                const defaultTempIndex = templatesData.data.indexOf(
                    templatesData.data.find(
                        template =>
                            template.id === defaultValues?.contract_template_id
                    )
                )
                setSelectedTemplate({
                    id: defaultValues.contract_template_id,
                    index: defaultTempIndex
                })
            }
        }
    }, [templatesIsSuccess, defaultValues])

    if (
        /* !legalRepresentativesIsSuccess || */
        !paymentRulesIsSuccess ||
        !type ||
        !contractorData ||
        (!templatesIsSuccess && !renew)
    ) {
        return <SpinnerLoader />
    }

    const onSubmit = data => {
        let dataToSend = {}
        delete data.has_project
        if (method !== 'YOU_HIRE') delete data.legal_representative_id
        if (type.name !== 'TIME') delete data.hours_per_day
        if (data.end_date === '') data.end_date = null
        if (data.contract_template_id === 'temporal')
            delete data.contract_template_id
        let idProfile = data.productivity_profile_id?.value
        let idProfileTime = data.time_profile_id?.value
        switch (type.name) {
            case 'TIME':
                delete data.productivity_profile_id
                delete data.productivity_active
                delete data.time_active
                delete data.time_profile_id
                dataToSend = {
                    productivity: {
                        active: productivity,
                        profile_id: idProfile || null
                    },
                    time: {
                        active: timeTracking,
                        profile_id: idProfileTime || null
                    },
                    contractor_contract_time: {
                        ...data,
                        holidays: data.holidays.value,
                        payment_rule_id: data.payment_rule_id.value,
                        teams_id: data.teams_id.map(team => team.value)
                    }
                }
                if (method === 'YOU_HIRE') {
                    dataToSend.contractor_contract_time.legal_representative_id =
                        data.legal_representative_id.value
                }
                break
            case 'TASKS':
                delete data.payment_rule_id
                delete data.productivity_profile_id
                delete data.time_profile_id
                delete data.productivity_active
                delete data.time_active
                dataToSend = {
                    productivity: {
                        active: productivity,
                        profile_id: idProfile || null
                    },
                    time: {
                        active: timeTracking,
                        profile_id: idProfileTime || null
                    },
                    contractor_contract_task: {
                        ...data,
                        holidays: data.holidays.value,
                        teams_id: data.teams_id.map(team => team.value)
                    }
                }
                if (method === 'YOU_HIRE') {
                    dataToSend.contractor_contract_task.legal_representative_id =
                        data.legal_representative_id.value
                }
                break
            case 'CLASSIC':
                delete data.productivity_profile_id
                delete data.time_profile_id
                delete data.time_active
                delete data.productivity_active
                dataToSend = {
                    productivity: {
                        active: productivity,
                        profile_id: idProfile || null
                    },
                    time: {
                        active: timeTracking,
                        profile_id: idProfileTime || null
                    },
                    contractor_contract_classic: {
                        ...data,
                        holidays: data.holidays.value,
                        payment_rule_id: data.payment_rule_id.value,
                        teams_id: data.teams_id.map(team => team.value)
                    }
                }
                if (method === 'YOU_HIRE') {
                    dataToSend.contractor_contract_classic.legal_representative_id =
                        data.legal_representative_id.value
                }
                break
            default:
                break
        }
        setContractData({ id, data: {...dataToSend,has_project:hasProjects} })
        setModalSigned(true)

        if (type.name !== 'TASKS') {
            const paymentRule = paymentRulesData.data?.find(
                pr => pr.id === data.payment_rule_id.value
            )
            setProccessData({
                contractorMetadata: {
                    ...data,
                    payment_rule_id: {
                        id: data.payment_rule_id.value,
                        label: paymentRule.name
                    },
                    teams_id: data.teams_id,
                }
            })
        } else {
            setProccessData({
                contractorMetadata: {
                    ...data,
                    teams_id: data.teams_id
                }
            })
        }
    }

    let salary = watch('salary')

    return (
        <>
            <JpModal
                isOpen={modalSigned}
                toggle={() => {}}
                headerContent={false}
                bodyContent={<ContractLoaderModal />}
            />
            <BlueHeaderCard
                headerContent={
                    <h4 className="text-white m-0">
                        {method
                            ? t(
                                  `views.newContract.steps.employer.${method}.title`
                              )
                            : t(
                                  'views.newContract.steps.contract.renewContract'
                              )}
                        {' - '}
                        {t(`views.settings.templates.types.${type?.name}`)}
                        {t('views.newContract.steps.contract.cardTitle')}
                    </h4>
                }
                bodyClassName="row"
                bodyContent={
                    <>
                        <Col md="3" sm="12">
                            <JpLabel labelOptions={{ for: 'start_date' }}>
                                {`${t(
                                    'views.newContract.steps.contract.form.start_date'
                                )}*`}
                            </JpLabel>
                            <Controller
                                control={control}
                                name="start_date"
                                render={({ field }) => (
                                    <JpDatePicker
                                        id="start_date"
                                        minDate={
                                            method !== 'YOU_HIRE'
                                                ? moment().format('YYYY-MM-DD')
                                                : null
                                        }
                                        setPicker={date =>
                                            setDate('start_date', date)
                                        }
                                        className={`ps-0 enabled-joobpay ${
                                            errors.start_date
                                                ? 'is-invalid'
                                                : 'mb-1'
                                        }`}
                                        {...field}
                                    />
                                )}
                            />
                            <FormFeedback invalid>
                                {t(
                                    `form.contractors.${errors.start_date?.message}`,
                                    {
                                        ns: 'errors'
                                    }
                                )}
                            </FormFeedback>
                            <JpLabel labelOptions={{ for: 'position' }}>
                                {`${t(
                                    'views.newContract.steps.contract.form.position'
                                )}*`}
                            </JpLabel>
                            <Controller
                                name={`position`}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        type="text"
                                        id="position"
                                        placeholder={t(
                                            'views.classicContract.labels.contractorPosition'
                                        )}
                                        invalid={errors.position && true}
                                        {...field}
                                    />
                                )}
                            />
                            <FormFeedback invalid>
                                {t(
                                    `form.contractors.${errors.position?.message}`,
                                    {
                                        ns: 'errors'
                                    }
                                )}
                            </FormFeedback>
                            <div className="mt-1">
                                <JpCurrencyInputController
                                    control={control}
                                    name="salary"
                                    title={`${t(
                                        `views.newContract.steps.contract.form.${
                                            SALARY_PER_TYPE[type?.name]
                                        }`
                                    )}*`}
                                    errors={errors}
                                    optionsInput={{
                                        placeholder: 'USD'
                                    }}
                                />
                            </div>
                            {type?.name !== 'TASKS' && (
                                <div className="mt-1">
                                    <JpAsyncSelectController
                                        control={control}
                                        name={`payment_rule_id`}
                                        errors={errors}
                                        title={t(
                                            'views.newContract.steps.contract.form.payment_rule'
                                        )}
                                        optionsAsyncSelect={{
                                            placeholder: `${t(
                                                'views.newContract.steps.contract.form.selectPaymentPeriod'
                                            )}`,
                                            defaultOptions:
                                                parsePaymentRuleOptions(
                                                    paymentRulesData.data
                                                )
                                        }}
                                        loadOptions={async value => {
                                            const res =
                                                await API.getPaymentRules(
                                                    value
                                                ).then(res => {
                                                    return parsePaymentRuleOptions(
                                                        res.data
                                                    )
                                                })
                                            return res
                                        }}
                                    />
                                </div>
                            )}
                            <div className="mt-1">
                                <JpLabel labelOptions={{ for: 'holidays' }}>
                                    {`${t(
                                        'views.settings.calendar.holidays'
                                    )}*`}
                                </JpLabel>
                                <Controller
                                    name="holidays"
                                    control={control}
                                    invalid={errors.holidays && true}
                                    render={({ field }) => (
                                        <JpCountryInput
                                            /* disabled={type.name === 'TASKS'} */
                                            field={field}
                                            enableCountrys={getHolidays?.result.map(
                                                item => item.value
                                            )}
                                            customOptions={[
                                                {
                                                    value: getHolidays?.result.find(
                                                        item =>
                                                            item.value ===
                                                            'NOT_APPLY'
                                                    ).value,
                                                    label: t('notApplicable')
                                                }
                                            ]}
                                        />
                                    )}
                                />
                                {errors.holidays && (
                                    <small className="text-danger">
                                        {t(`${errors.holidays.message}`)}
                                    </small>
                                )}
                            </div>
                        </Col>
                        <Col md="3" sm="12" className="mt-1 mt-md-0">
                            <JpLabel labelOptions={{ for: 'end_date' }}>
                                {`${t(
                                    'views.newContract.steps.contract.form.end_date'
                                )} ${type.name === 'TASKS' ? '*' : ''}`}
                            </JpLabel>
                            <Controller
                                control={control}
                                name="end_date"
                                render={({ field }) => (
                                    <JpDatePicker
                                        id="end_date"
                                        clearable={true}
                                        minDate={
                                            moment(start_date)
                                                .add(1, 'day')
                                                .format('YYYY-MM-DD') ||
                                            moment()
                                                .add(1, 'day')
                                                .format('YYYY-MM-DD')
                                        }
                                        setPicker={date => {
                                            field.onChange(
                                                date
                                                    ? date.format('YYYY-MM-DD')
                                                    : ''
                                            )
                                        }}
                                        className={`ps-0 enabled-joobpay ${
                                            !!errors.end_date
                                                ? 'is-invalid'
                                                : ''
                                        }`}
                                        {...field}
                                    />
                                )}
                            />
                            <FormFeedback invalid>
                                {t(
                                    `form.contractors.${errors.end_date?.message}`,
                                    {
                                        ns: 'errors'
                                    }
                                )}
                            </FormFeedback>
                            <div className="mt-1">
                                <JpAsyncSelectController
                                    name={`teams_id`}
                                    control={control}
                                    errors={errors}
                                    title={t(
                                        'views.newContract.steps.contract.form.team'
                                    )}
                                    optionsAsyncSelect={{
                                        isMulti: true,
                                        placeholder: `${t(
                                            'views.newContract.steps.contract.form.selectTeam'
                                        )}`
                                    }}
                                    loadOptions={async value => {
                                        const res = await API.getAvailableTeams(
                                            value
                                        ).then(res => {
                                            return res.data.map(item => ({
                                                value: item.id,
                                                label: item.name
                                            }))
                                        })
                                        return res
                                    }}
                                />
                            </div>
                            {type?.name === 'CLASSIC' ? (
                                <div className="mt-1">
                                    <JpLabel>
                                        {t(
                                            'views.newContract.steps.contract.form.annualSalaryCalculation'
                                        )}
                                    </JpLabel>
                                    <InputGroup>
                                        <InputGroupText>{'US$'}</InputGroupText>
                                        <Input
                                            name={t(
                                                `views.accounting.totalValue`
                                            )}
                                            type="number"
                                            disabled
                                            addon
                                            value={
                                                parseFloat(salary || 0, 1) * 12
                                            }
                                        />
                                    </InputGroup>
                                </div>
                            ) : type?.name === 'TIME' ? (
                                <>
                                    <JpLabel
                                        labelOptions={{
                                            for: 'hours_per_day',
                                            id: 'hours_per_dayTooltip'
                                        }}
                                        className="mt-1"
                                    >
                                        {`${t(
                                            'views.newContract.steps.contract.form.hours_per_day'
                                        )}*`}
                                    </JpLabel>
                                    <UncontrolledTooltip
                                        className="text-muted"
                                        target={'hours_per_dayTooltip'}
                                    >
                                        {t(
                                            'views.newContract.steps.contract.form.hours_per_dayTooltip'
                                        )}
                                    </UncontrolledTooltip>
                                    <Controller
                                        name={`hours_per_day`}
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                type="number"
                                                id="hours_per_day"
                                                placeholder={t(
                                                    'views.classicContract.labels.inHours'
                                                )}
                                                invalid={
                                                    errors.hours_per_day && true
                                                }
                                                className={'enabled-joobpay'}
                                                {...field}
                                                onChange={e => {
                                                    if (
                                                        parseInt(
                                                            e.target.value
                                                        ) > 24
                                                    ) {
                                                        field.onChange('24')
                                                        return
                                                    }
                                                    field.onChange(
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        )}
                                    />
                                    <FormFeedback invalid>
                                        {t(
                                            `form.contractors.${errors.hours_per_day?.message}`,
                                            {
                                                ns: 'errors'
                                            }
                                        )}
                                    </FormFeedback>
                                </>
                            ) : null}
                            {method === 'YOU_HIRE' &&
                                legalRepresentativesData?.count >= 1 && (
                                    <div className="mt-1">
                                        <JpAsyncSelectController
                                            control={control}
                                            name={`legal_representative_id`}
                                            errors={errors}
                                            title={`${t(
                                                'views.newContract.steps.contract.form.signer'
                                            )}*`}
                                            optionsAsyncSelect={{
                                                placeholder: `${t(
                                                    'views.newContract.steps.contract.form.selectSigner'
                                                )}`,
                                                defaultOptions:
                                                    parseLegalRepresentatives(
                                                        legalRepresentativesData?.data?.filter(
                                                            item =>
                                                                item.legal_representative_id !==
                                                                LRmatchesWithContractor?.legal_representative_id
                                                        )
                                                    )
                                            }}
                                            loadOptions={async value => {
                                                const res =
                                                    await API.getLegalRepresentatives(
                                                        value
                                                    ).then(res => {
                                                        return parseLegalRepresentatives(
                                                            res.data.filter(
                                                                item =>
                                                                    item.legal_representative_id !==
                                                                    LRmatchesWithContractor?.legal_representative_id
                                                            )
                                                        )
                                                    })
                                                return res
                                            }}
                                        />
                                    </div>
                                )}
                        </Col>
                        <Col md="6" sm="12" className="mt-1 mt-md-0">
                            <div>
                                <JpTextAreaController
                                    maxStringLength={1600}
                                    name={`responsibilities`}
                                    control={control}
                                    errors={errors}
                                    title={`${t(
                                        'views.newContract.steps.contract.form.responsibilities'
                                    )}*`}
                                    optionsInput={{
                                        maxStringLength: 1000,
                                        rows: 11,
                                        style: {}
                                    }}
                                />
                            </div>
                        </Col>

                        <Row className="d-flex flex-column flex-sm-row border-top mt-1 align-items-start">
                            <h4 tag="h4" className="py-2">
                                <JpText
                                    type="card-title"
                                    children={t('accessProfile')}
                                />
                            </h4>
                        </Row>
                        <Row className="createContractSwitchs">
                            <Col xs="12" xl="2" className="mt-1">
                                <JpSwitchController
                                    name="time_active"
                                    control={control}
                                    id="switch-info"
                                    className="form-switch form-check-info"
                                    label={t(
                                        'views.profile.payments.measuringTime'
                                    )}
                                    optionsLabel={{
                                        className: 'form-check-label text-break'
                                    }}
                                    optionsInput={{
                                        disabled: disabled,
                                        checked: timeTracking,
                                        onClick: () =>
                                            setTimeTracking(!timeTracking)
                                    }}
                                />
                            </Col>

                            <Col xs="12" xl="3" className="">
                                {timeTracking && (
                                    <>
                                        <JpAsyncSelectController
                                            name="time_profile_id"
                                            control={control}
                                            errors={errors}
                                            optionsAsyncSelect={{
                                                defaultOptions:
                                                    getTimeProfile?.result?.map(
                                                        item => ({
                                                            value: item.id,
                                                            label: item.name,
                                                            name: item.name
                                                        })
                                                    ),
                                                styles: {
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        width: 230,
                                                        marginTop: 5
                                                    })
                                                }
                                            }}
                                            loadOptions={getTimeProfileSelect}
                                        />
                                    </>
                                )}
                            </Col>

                            <Col xs="12" xl="2" className="mt-1">
                                {timeTracking && (
                                    <JpSwitchController
                                        name="productivity_active"
                                        control={control}
                                        id="switch-info"
                                        className="form-switch form-check-info"
                                        label={t(
                                            'views.profile.payments.productivity'
                                        )}
                                        optionsLabel={{
                                            className:
                                                'form-check-label text-break'
                                        }}
                                        optionsInput={{
                                            checked: productivity,
                                            onClick: () =>
                                                setProductivity(!productivity)
                                        }}
                                    />
                                )}
                            </Col>
                            <Col xs="12" xl="3" className="">
                                {timeTracking && productivity && (
                                    <>
                                        <JpAsyncSelectController
                                            name="productivity_profile_id"
                                            control={control}
                                            errors={errors}
                                            placeHolder={t(
                                                'views.profile.payments.productivityProfile'
                                            )}
                                            optionsAsyncSelect={{
                                                defaultOptions:
                                                    getProductivityProfile?.data?.map(
                                                        item => ({
                                                            value: item.id,
                                                            label: item.name,
                                                            name: item.name
                                                        })
                                                    ),
                                                styles: {
                                                    control: (
                                                        baseStyles,
                                                        state
                                                    ) => ({
                                                        ...baseStyles,
                                                        width: 230,
                                                        marginTop: 5
                                                    })
                                                }
                                            }}
                                            loadOptions={productivityProfile}
                                        />
                                    </>
                                )}
                            </Col>
                            <Col xs="12" xl="2" className="mt-1">
                                <JpSwitchController
                                    name="has_project"
                                    control={control}
                                    id="switch-info"
                                    className="form-switch form-check-info"
                                    label={`${t('views.projects.project')}s`}
                                    optionsLabel={{
                                        className: 'form-check-label text-break'
                                    }}
                                    optionsInput={{
                                        checked: hasProjects,
                                        onClick: () => {
                                            setHasProjects(!hasProjects)
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </>
                }
            />
            {!renew && (
                <FormProvider {...methods}>
                    <Container fluid>
                        <Row className="justify-content-between mt-2">
                            <Col md="6" sm="11" className="ps-0">
                                <Card className="h-100">
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            {t(
                                                'views.newContract.steps.contract.contractTemplate'
                                            )}
                                        </CardTitle>
                                        {method === 'YOU_HIRE' && (
                                            <>
                                                <div className="text-end">
                                                    <Button
                                                        color="info"
                                                        type="button"
                                                        onClick={() =>
                                                            setCreateTemplateModal(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faPlus}
                                                            className="me-75"
                                                        />
                                                        {t(
                                                            'views.newContract.steps.contract.addTemplate'
                                                        )}
                                                    </Button>
                                                </div>
                                                <CreateTemplateModal
                                                    isOpen={createTemplateModal}
                                                    toggle={() =>
                                                        setCreateTemplateModal(
                                                            false
                                                        )
                                                    }
                                                    setTemporalTemplate={
                                                        setTemporalTemplate
                                                    }
                                                    setRefetchTemplates={() =>
                                                        setRefetchTemplates(
                                                            !refetchTemplates
                                                        )
                                                    }
                                                />
                                            </>
                                        )}
                                    </CardHeader>
                                    <CardBody>
                                        <CardSubtitle className="mb-1">
                                            <CardText>
                                                {t(
                                                    'views.newContract.steps.contract.contractTemplateSubtitle'
                                                )}
                                            </CardText>
                                        </CardSubtitle>
                                        <ContractTemplatesList
                                            creatable={method === 'YOU_HIRE'}
                                            templates={
                                                temporalTemplatesData &&
                                                temporalTemplate
                                                    ? [
                                                          ...data,
                                                          {
                                                              ...temporalTemplatesData,
                                                              erasable: true
                                                          }
                                                      ]
                                                    : data
                                            }
                                            selectedTemplate={selectedTemplate}
                                            setSelectedTemplate={
                                                setSelectedTemplate
                                            }
                                            setTemporalTemplate={
                                                setTemporalTemplate
                                            }
                                            setData={setData}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="6" sm="11" className="pe-0 mt-1 mt-md-0">
                                <Card className="h-100">
                                    <CardHeader>
                                        <CardTitle tag="h4">
                                            {t(
                                                'views.newContract.steps.contract.clauses'
                                            )}
                                        </CardTitle>
                                        <div>
                                            <ContractClauseModal
                                                isOpen={createClauseModal}
                                                toggle={() =>
                                                    setCreateClauseModal(false)
                                                }
                                                handleNewClause={
                                                    handleNewClause
                                                }
                                            />
                                            {clauses.length < 10 && (
                                                <Button
                                                    color="info"
                                                    type="button"
                                                    onClick={() =>
                                                        setCreateClauseModal(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        className="me-75"
                                                    />
                                                    {t(
                                                        'views.newContract.steps.contract.addClause'
                                                    )}
                                                </Button>
                                            )}
                                        </div>
                                    </CardHeader>
                                    <CardBody>
                                        <CardSubtitle className="mb-1">
                                            <CardText>
                                                {t(
                                                    'views.newContract.steps.contract.clausesSubtitle'
                                                )}
                                            </CardText>
                                        </CardSubtitle>
                                        <ContractClausesList
                                            clauses={clauses}
                                            setClauses={setClauses}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </FormProvider>
            )}

            <div
                className={`d-flex ${
                    renew ? 'justify-content-end' : 'justify-content-between'
                } mt-2`}
            >
                {!renew && (
                    <JpButton
                        type="back"
                        options={{
                            onClick: () => {
                                stepper.previous()
                                clearErrors('end_date')
                            },
                            iconPosition: 'left'
                        }}
                    />
                )}
                <JpButton
                    type="next"
                    loading={isLoading}
                    options={{ onClick: handleSubmit(onSubmit) }}
                />
            </div>
        </>
    )
}

export default ContractDataV2
