import { createSlice } from '@reduxjs/toolkit'

export const backofficeUser = createSlice({
  name: 'backofficeUser',
  initialState: { userAuth: null, userAdmin: null },
  reducers: {
    setBackofficeUser: (state, action) => {
      return { ...state, ...action.payload }
    }
  }
})

export const { setBackofficeUser } = backofficeUser.actions

export default backofficeUser.reducer
