import axios from 'axios'
import { API_URL } from '@src/configs/environment'
import {
    parseQueries,
    parseQueriesLegacy,
    stringQueryByJson
} from '../utility/Query'

import { globalHandler } from '@Intelli/utilities'
import moment from 'moment-timezone'

/**
 * @Description Servicio API del admin
 */
export default class AdminAPI {
    constructor(baseURL = API_URL) {
        this.API = new axios.create({
            baseURL,
            withCredentials: true,
            headers: {
                lng: localStorage.getItem('i18nextLng')
                    ? localStorage.getItem('i18nextLng')
                    : 'en',
                timezone: moment.tz.guess(true)
            }
        })
        this.API.interceptors.response.use(
            request => {
                return request
            },
            error => {
                globalHandler(error.response.data)
                return Promise.reject(error)
            }
        )
    }

    /**
     * @Description Gets user data
     * @param {String} id
     * @returns
     */
    async getProfile(id) {
        return await this.API.get(`v1/admin/profile/${id}`)
    }

    payrollHistoryTransactionTokenPayment(id) {
        return this.API.get(`v1/admin/payroll/historics/${id}/token_payment`)
    }
    /**
     * @Description Updates the user data
     * @param {Object<User>} obj
     * @returns
     */
    async updateProfile(data) {
        try {
            const response = await this.API.put('v1/auth/user', data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * @Description Gets user data
     * @param {String} id
     * @returns
     */
    async getProfile(id) {
        return await this.API.get(`v1/admin/profile/${id}`)
    }

    /**
     * @Description Updates the user data
     * @param {Object<User>} obj
     * @returns
     */
    async updateProfile(data) {
        try {
            const response = await this.API.put('v1/auth/user', data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * @Description Delete document
     * @param {Object<User>} obj
     * @returns
     */
    async deleteContractorDocument(data) {
        try {
            const response = await this.API.delete(
                `v1/admin/contractors/${data.contractor_id}/documents/${data.document_id}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    /**
     * @Description Updates the contractor data
     * @param {Object<User>} obj
     * @returns
     */
    async updateContractorDirection({ data, id }) {
        try {
            const response = await this.API.put(
                `v1/admin/contractors/${id}`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * @Description Updates the contract data of contractor
     * @param {Object<User>} obj
     * @returns
     */
    async updateContractorDataContract({ contractor_profile, id }) {
        try {
            const response = await this.API.put(`v1/admin/contractors/${id}`, {
                contractor_profile
            })
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * @Description Updates the contractor data
     * @param {Object<User>} obj
     * @returns
     */
    async updateContractorDirection({ data, id }) {
        try {
            const response = await this.API.put(
                `v1/admin/contractors/${id}`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * @Description Updates the user password
     * @param {Object<User>} obj
     * @returns
     */
    async UpdatePassword(data) {
        try {
            const response = await this.API.put(
                'v1/auth/user/password',
                data.data,
                {
                    headers: { TwoFactorId: data.token }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * @Description Envía la invitación al operador con los permisos
     * @param {Object<Invitation>} invitation
     * @returns {Axios response}
     */
    async sendOperatorInvitation(invitation) {
        return await this.API.post('v1/admin/invitation', invitation)
    }
    /**
     * Actualiza los permisos del usuario
     * @param {String} user_id
     * @param {Object<Invitation>} data
     * @returns
     */
    async updateOperatorPermissions(user_id, data = {}) {
        return await this.API.put(`v1/admin/users/permission/${user_id}`, data)
    }
    /**
     * @Description Reenvía la invitación al operador
     * @param {String} email
     * @returns {Axios response}
     */
    async resendOperatorInvitation(email) {
        return await this.API.post('v1/admin/invitation', { email })
    }
    /**
     * Cambia el status del access
     * @param {String} id
     * @param {Number} status
     * @returns {Axios response}
     */
    async activeUser(data) {
        try {
            const response = await this.API.put(`v1/admin/users/${data.id}`, {
                status: data.status
            })
            return response
        } catch (e) {
            throw e
        }
    }

    /* async getOperatorProfile(id) {
        try {
            const response = await this.API.get(`v1/admin/users/${id}`)
            return response.data
        } catch (e) {
            throw e
        }
    } */

    async getOperatorProfile(id) {
        try {
            const response = await this.API.get(
                `v1/admin/users/${id}/permissions`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * Restaura el acceso a nivel de usuario (schema oauth )
     * @param {String} id
     * @param {*} data
     * @returns {Axios response}
     */
    async restoreUserAccess(data) {
        try {
            const response = await this.API.put(
                `v1/admin/users/${data.id}`,
                data.data
            )
            return response
        } catch (error) {
            throw error
        }
    }
    /**
     * @Description Obtiene todas las invitaciones del admin
     * @param {Number} offset
     * @param {Number} limit Total de registros por página
     * @returns {Axios response}
     */
    async getAdminrInvitations(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/users?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * @Description Obtiene todas las invitaciones del backoffice
     * @param {Number} offset
     * @param {Number} limit Total de registros por página
     * @returns {Axios response}
     */
    async getAccessInvitation(offset = 0, limit = 10) {
        return await this.API.get(
            `v1/admin/invitation?limit=${limit}&offset=${offset}`
        )
    }

    /**
     * @Description Obtiene la información de perfil de un usuario
     * @param {String} id
     * @returns {Axios response}
     */
    async getAdminProfile(id) {
        try {
            const response = await this.API.get(`v1/admin/users/${id}`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    /**
     * @Description Obtiene los documentos ligados a un operador
     * @param {String} id
     * @param {Array<Queries>} queries
     * @returns {Axios response}
     */
    async getAdminDocuments(id, queries = []) {
        try {
            const response = await this.API.get(
                `v1/admin/users/${id}/documents?${parseQueriesLegacy(queries)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    /**
     * @Description Actualiza los datos de perfil de un operator
     * @param {String} id
     * @param {Object<Profile>} data
     * @returns {Axios response}
     */
    async updateOperatorProfile(id, data = {}) {
        return await this.API.put(`v1/admin/profile/${id}`, data)
    }
    /**
     * @Description Actualiza la contraseña del usuario operador
     * @param {String} id
     * @param {Object<Credentials>} data
     * @returns {Axios response}
     */
    async updateOperatorPassword(id, data = {}) {
        return await await this.API.put(
            `v1/admin/profile/security/updatePassword/${id}`,
            data
        )
    }

    async getDashboardClient() {
        try {
            const response = await this.API.get(
                `v1/admin/dashboard/chart/client`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getDashboardContracts() {
        try {
            const response = await this.API.get(
                `v1/admin/dashboard/chart/active_contracts`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getDashboardMonthPayments() {
        try {
            const response = await this.API.get(
                `v1/admin/dashboard/chart/payroll`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getDashboardPayrollByContract() {
        try {
            const response = await this.API.get(
                `v1/admin/dashboard/chart/payroll_by_contract_type`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getHistoricPayrollDashboard() {
        try {
            const response = await this.API.get(
                `v1/admin/dashboard/chart/payroll_historic`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorsSelect(id) {
        try {
            const response = await this.API.get(`v1/admin/contractors/search`)
            return response.data
        } catch (e) {
            throw e
        }
    }
    /**
     *
     * @param {String} id id del usuario
     * @returns {Axios response}
     */
    async getUserPermissions(id) {
        return await this.API.get(`v1/admin/users/permission/${id}`)
    }

    async getModules() {
        try {
            const response = await this.API.get('v1/admin/modules')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getOperations() {
        try {
            const response = await this.API.get('v1/admin/operations')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getRoles() {
        try {
            const response = await this.API.get('v1/admin/roles')
            return response.data
        } catch (e) {
            throw e
        }
    }
    async getAccessSetings() {
        return await this.API.get('v1/admin/user/modules')
    }
    /**
     * @Description Envía la invitación al correo
     * @param {Object<Invitation>} invitation
     * @returns {Axios response}
     */

    async editPermisUser(access_id, role_id = {}) {
        return await this.API.put(`v1/admin/users/${access_id}`, role_id)
    }
    async updateOperatorInformation(data) {
        try {
            const response = await this.API.put(
                `v1/admin/users/${data.id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }
    async getEditUser(access_id) {
        return await this.API.get(`v1/admin/users/${access_id}`)
    }
    /**
     * @Description Editar Permisos
     * @param {Object<Invitation>} invitation
     * @returns {Axios response}
     */
    async resendInvitation(access_id) {
        return await this.API.post(`v1/admin/invitation/${access_id}/resend`)
    }

    async getAdminUsers() {
        try {
            const response = await this.API.get('v1/admin/users')
            return response.data
        } catch (e) {
            throw e
        }
    }

    // Endpoints añadidos post-update

    async logout() {
        try {
            const response = await this.API.post('v1/auth/logout')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAdminUserInfo() {
        try {
            const response = await this.API.get('v1/admin/user')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getUser() {
        try {
            const response = await this.API.get('v1/auth/user')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getMenu() {
        try {
            const response = await this.API.get('v1/admin/user/modules')
            return response.data
        } catch (e) {
            throw e
        }
    }

    async send2faCode() {
        try {
            const response = await this.API.post(
                'v1/verification/twoFactor/send'
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async validate2faCode(data) {
        try {
            const response = await this.API.post(
                'v1/verification/twoFactor/validate',
                data
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async set2fa(data) {
        try {
            const response = await this.API.post(
                'v1/auth/session/twoFactor',
                data
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async SendPhoneCode(data) {
        try {
            const response = await this.API.post(
                'v1/verification/phone/send',
                data.phone,
                {
                    headers: { TwoFactorId: data.token }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async VerifyPhoneCode(code) {
        try {
            await this.API.post('v1/verification/phone/validate', code.data, {
                headers: { TwoFactorId: code.token }
            })
            return true
        } catch (e) {
            throw e
        }
    }

    async SendEmailCode(data) {
        try {
            return await this.API.post(
                'v1/verification/email/send',
                { email: data.email },
                {
                    headers: { TwoFactorId: data.token }
                }
            )
        } catch (e) {
            throw e
        }
    }

    async VerifyEmailCode(code) {
        try {
            await this.API.post('v1/verification/email/validate', code.data, {
                headers: { TwoFactorId: code.token }
            })
            return true
        } catch (e) {
            throw e
        }
    }

    async send2faCode() {
        try {
            const response = await this.API.post(
                'v1/verification/twoFactor/send'
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async validate2faCode(data) {
        try {
            const response = await this.API.post(
                'v1/verification/twoFactor/validate',
                data
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async set2fa(data) {
        try {
            const response = await this.API.post(
                'v1/auth/session/twoFactor',
                data
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getTeams(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/teams?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createTeams(teamData) {
        try {
            const response = await this.API.post('v1/admin/teams', teamData)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteTeams(id) {
        try {
            const response = await this.API.delete(`v1/admin/teams/${id}`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editTeams(data) {
        try {
            const response = await this.API.put(
                `v1/admin/teams/${data.id}`,
                data.data
            )
            return response.data
        } catch (e) {
            throw e.response
        }
    }

    async getActiveContractors(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getContractor(contractor_id) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${contractor_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async changeContractorStatus(data) {
        try {
            const response = await this.API.put(
                `v1/admin/contractors/${data.id}`,
                data.data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createNoContractProcessContractor(data) {
        try {
            const response = await this.API.put(
                `v1/admin/contractors/${data.id}/no_contract_process`,
                data.data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async inviteFromContractors(invitation) {
        try {
            const response = await this.API.post(
                'v1/admin/contractors/invitation',
                invitation
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async resendContractorInvitation(id) {
        try {
            const response = await this.API.post(
                `v1/admin/contractors/invitation/${id}/resend`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteContractor(contractor_id) {
        try {
            const response = await this.API.delete(
                `v1/admin/contractors/${contractor_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async changeContractorWorkspace(data) {
        try {
            const response = await this.API.post(
                'v1/auth/session/workspace/contractor',
                data
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getOwnDocuments(offset, limit, queries) {
        try {
            const response = await this.API.get(
                `v1/auth/user/documents?limit=${limit}&offset=${offset}${parseQueriesLegacy(
                    queries
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async availableContractors(query = '') {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/search?contractor_status=ACTIVED&order=first_name${
                    query && `&query=${query}`
                }`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async availableContractorsForTeams(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/teams/${
                    query.team_id
                }/members/new/search?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorDocuments(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${id}/documents?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTeamInfo(team_id) {
        try {
            const response = await this.API.get(`v1/admin/teams/${team_id}`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getMembers(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/teams/${query.team_id}/members?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addMembers(data) {
        try {
            const response = await this.API.post(
                `v1/admin/teams/${data.team_id}/members`,
                data.data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteMembers(data) {
        try {
            const response = await this.API.delete(
                `v1/admin/teams/${data.team_id}/members/${data.contractor_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPaymentRules(query = '') {
        try {
            const response = await this.API.get(
                `v1/admin/payment_rules/search${query && `?query=${query}`}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getHolidays(contractor_id, query = '') {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${contractor_id}/holidays/search${
                    query && `?query=${query}`
                }`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAvailableTeams(query = '') {
        try {
            const response = await this.API.get(
                `v1/admin/teams/search${query && `?query=${query}`}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractTypes(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contract_type/search?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getModalities() {
        try {
            const response = await this.API.get(`v1/admin/modalities`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createTemplate(data) {
        try {
            const response = await this.API.post(
                'v1/admin/contract_templates',
                { ...data.data, file: data.file },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async updateTemplate(data) {
        try {
            const response = await this.API.put(
                `v1/admin/contract_templates/${data.id}`,
                { ...data.data, file: data.file },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteTemplate(id) {
        try {
            const response = await this.API.delete(
                `v1/admin/contract_templates/${id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTemplates(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contract_templates?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTemplatesList(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contract_templates?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTemplate(id) {
        try {
            const response = await this.API.get(
                `v1/admin/contract_templates/${id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createContractorTemplate(data) {
        try {
            const response = await this.API.post(
                `v1/admin/contractors/${data.contractor_id}/contract_templates`,
                data.data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteTemporalTemplate(id) {
        try {
            const response = await this.API.delete(
                `v1/admin/contractors/${id}/tmp_document`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getLegalRepresentatives(query = '') {
        try {
            const response = await this.API.get(
                `v1/admin/user/legal_representative${
                    query && `?query=${query}`
                }`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getLegalRepresentativeStatusCount() {
        try {
            const response = await this.API.get(
                `v1/admin/user/legal_representative/status_count`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async sendContract(data) {
        try {
            const response = await this.API.post(
                `v1/admin/contractors/${data.id}/envelope`,
                data.data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractSignUrl(id) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${id}/contract_sign`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractSignUrlFromToken(token) {
        try {
            const response = await this.API.get(
                'v1/admin/contractors/contract_sign',
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getRejectReasons(id) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${id}/rejected`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getDocuments(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/user/documents?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContracts(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${id}/contracts?${stringQueryByJson(
                    query
                )}`
            )
            return {
                count: response.data.count,
                data: response.data.data.filter(
                    contract => contract.contract_type_name
                )
            }
        } catch (e) {
            throw e
        }
    }

    async getContractsHistoic(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${id}/contracts/historics?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async generateNewContract(id) {
        try {
            const response = await this.API.post(
                `v1/admin/contractors/${id}/new`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async postDocument(data) {
        try {
            const response = await this.API.post(
                `v1/admin/contractors/${data.id}/documents`,
                data.data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    //Get type of documents
    async getDocumentsTypeUser(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/documents_type/user?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getDocumentsType() {
        try {
            const response = await this.API.get('v1/admin/documents_type/admin')
            return response.data
        } catch (error) {
            throw error
        }
    }

    //Post Documents in my profile table
    async postAdminDocuments(data) {
        try {
            const response = await this.API.post(
                'v1/admin/user/documents',
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //Delete documents on my profile table
    async deleteDocument(id) {
        try {
            const response = await this.API.delete(
                `v1/admin/user/documents/${id}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    //diego
    async renewContractData(id) {
        try {
            const response = await this.API.post(
                `v1/admin/contractors/${id}/renew`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //Statistics

    async totalToPay() {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/chart/total_to_pay`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async totalToReview() {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/chart/total_to_review`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async forContractType() {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/chart/for_contract_type`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //Unpaid Section (Payment)

    async getUnpaidList(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPayrollList({ state }) {
        try {
            let url = `v1/admin/payroll/payments/list`
            if (state) {
                url += `?state=${state}`
            }
            const response = await this.API.get(url)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPayrollIndex(id) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/${id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorsSelect(id) {
        try {
            const response = await this.API.get(`v1/admin/contractors/search`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    //revert transaction historics
    async revertTransactionHistoric({ data }) {
        await this.API.post(`v1/admin/payroll/historics/${data.id}/revert`, {
            state: data.state
        })
    }

    //payrolls/historics
    async getPayrollsHistorics(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/historics?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //payrolls/details
    async getPayrollsDetails(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/historics/${
                    query.id
                }/details?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //payrolls/details/transaction
    async getPayrollsTransaction(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/historics/${id}/logs?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //payrolls/details/ -> paidpayrolls bodycontent
    async getPaidPayrollsDetails(id) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/historics/${id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //payrolls/details/contractor
    async getPayrollsDetailsContractor(id, transaction_payroll_id) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/historics/${id}/details/${transaction_payroll_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //statistics historics

    async getContractTypeStatistics() {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/historics/chart/for_contract_type`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getLastMonthStatistics() {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/historics/chart/total_paid_last_month`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTotalPaidStatistics() {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/historics/chart/total_paid`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    //Get pay periods
    async getPayPeriods() {
        try {
            const response = await this.API.get('v1/admin/payment_rules')
            return response.data
        } catch (error) {
            throw error
        }
    }

    //Edit pay periods
    async editPayPeriods(data) {
        try {
            const response = await this.API.put('v1/admin/payment_rules', data)
            return response.data
        } catch (error) {
            throw error
        }
    }

    //Get config of contract types
    async getContractTypesConfig() {
        try {
            const response = await this.API.get('v1/admin/contract_type')
            return response.data
        } catch (error) {
            throw error
        }
    }

    //Edit config of contract types
    async editContractTypesConfig(data) {
        try {
            const response = await this.API.put('v1/admin/contract_type', data)
            return response.data
        } catch (error) {
            throw error
        }
    }

    async setPayrollStatus(data) {
        try {
            const response = await this.API.put(
                `v1/admin/payroll/payments/${data.contractor_id}/${data.payroll_id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async getContractorsWithOutPayroll(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/without_payroll/search?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addContractorsPayroll(data) {
        try {
            const response = await this.API.post(
                `v1/admin/payroll/payments`,
                data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async deleteContractorPayroll(data) {
        try {
            const response = await this.API.delete(
                `v1/admin/payroll/payments/${data.contractor_id}/${data.payroll_id}`
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async addNews(data) {
        try {
            const response = await this.API.post(
                `v1/admin/payroll/concepts/${data.id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async deleteNews(data) {
        try {
            const response = await this.API.delete(
                `v1/admin/payroll/concepts/${data?.payroll_id}/${data?.payroll_concept_id}`
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async reverseChangesNews(id) {
        try {
            const response = await this.API.put(
                `v1/admin/payroll/concepts/${id}/reverse`
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async modifyNews(data) {
        try {
            const response = await this.API.put(
                `v1/admin/payroll/concepts/${data.payroll_id}/${data.payroll_concept_id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async getPayrollsConcepts(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/concepts?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getPayrollsApproved(
        offset = 0,
        limit = 10,
        queries = [],
        contractType
    ) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/approved?limit=${limit}&offset=${offset}${parseQueriesLegacy(
                    queries
                )}${contractType ? `&query=${contractType}` : ''}`
            )
            return response.data
        } catch (e) {
            throw e.response
        }
    }

    async getApprovedToPay() {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/chart/approved/total_to_pay`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getApprovedForContractType() {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/chart/approved/for_contract_type`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async payPayroll() {
        try {
            const response = await this.API.post(
                `v1/admin/payroll/payments/approved`
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async veriffLegalRepresentative() {
        try {
            const response = await this.API.get(`v1/admin/user`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getActiveContractorsChart() {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/chart/active_contracts`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getForContractTypeChart() {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/chart/for_contract_type`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    /* async getIndustryType() {
        try {
            const response = await this.API.get(`v1/admin/industry_type/search`)
            return response.data
        } catch (e) {
            throw e
        }
    } */

    async getIndustryType(query = '') {
        try {
            const response = await this.API.get(
                `v1/admin/industry_type/search${
                    query && `?${stringQueryByJson(query)}`
                }`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getClientDetails() {
        try {
            const response = await this.API.get(`v1/admin/clients`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editClientDetails(data) {
        try {
            const response = await this.API.put(`v1/admin/clients`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async SetClientLogo(data) {
        try {
            const formData = new FormData()
            formData.append('file', data.file)
            const response = await this.API.put(
                `v1/admin/clients/logo`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async getContractorPayroll(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${id}/payroll?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editProfilePic(data) {
        try {
            const formData = new FormData()
            formData.append('file', data)
            const response = await this.API.post(
                `v1/auth/user/photo`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async getInvoices(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/invoices?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProductivityProfile(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/search?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async invoicesPaidLastMonthChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/invoices/chart/total_paid?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async servicesPaidLastMonthChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/services/total_paid?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPorductivityClients(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async invoicesLastPayrollChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/invoices/chart/payroll?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async servicesCount(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/services/count_services?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getSelectScreenshots(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/search/screenshots?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async invoicesServicesChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/invoices/chart/services?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async servicesHistoricChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/services/historic_payroll?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getSelectCallMinutes(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/search/call_minutes?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async invoicesBalanceChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/invoices/chart/balance?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async servicesPendingsChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/services/total_in_paid?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getSelectTimeWorked(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/search/time_worked?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getInvoicesDetailHeader(invoice_id) {
        try {
            const response = await this.API.get(
                `v1/admin/invoices/${invoice_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getSelectTimeAfk(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/search/time_afk?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getInvoicesDetailTable(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/invoices/${
                    query.invoice_id
                }/details?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProductivityProfileData(profile_client_id) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/${profile_client_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async payInvoices(data) {
        try {
            const response = await this.API.post(
                `v1/admin/invoices/${data.invoice_id}/token_payment`,
                { services: data.arrayServices }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async payServices(data) {
        try {
            const response = await this.API.post(
                `v1/admin/clients/token_payment_services`,
                { services: data.services }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAppProductive(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/${
                    query.profile_client_id
                }/apps/productive?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getHours(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/client?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTimeHoursContractors(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/client/hours?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAppUnroductive(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/${
                    query.profile_client_id
                }/apps/unproductive?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAppNeutral(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/profile/${
                    query.profile_client_id
                }/apps/neutral?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteProductivityProfile(profile_client_id) {
        try {
            const response = await this.API.delete(
                `v1/productivity/profile/${profile_client_id}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async editCreateProductivityProfile(data) {
        try {
            const response = await this.API.put(
                `v1/productivity/profile/${data.profile_client_id}`,
                data.data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getIdForProductivyProfile() {
        try {
            const response = await this.API.post(`v1/productivity/profile`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editAppsDaD(data) {
        try {
            const response = await this.API.put(
                `v1/productivity/profile/${data.profile_client_id}/app`,
                data.data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProductivityDashboard(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/dashboard?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    //Charts Productivity

    async getProductivityChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/chart/productivity?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAppsChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/chart/apps?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getStatusChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/chart/status?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getCurrentChart(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/chart/current?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProductivityTimeLine(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/timeline?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProductivityDetailsDashboard(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/contractors/${
                    query.contractor_id
                }/details?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorProductivityCharts(contractor_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/chart/contractors/${contractor_id}/productivity?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProductivityCharts(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/client/chart/times?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorScreenshotsLast(contractor_id) {
        try {
            const response = await this.API.get(
                `v1/productivity/contractors/${contractor_id}/screenshots/last`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAllAppsSession(data, query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/contractors/${data.contractor_id}/timeline/${
                    data.session_id
                }?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTimelineDetails(query = {}, contractor_id) {
        try {
            const response = await this.API.get(
                `v1/productivity/contractors/${contractor_id}/timeline?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorHours(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/contractors/${id}?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getScreenshots(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/screenshots?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    async getHoursStats(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/client/chart/stats?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getScreenshotDetailedData(id) {
        try {
            const response = await this.API.get(
                `v1/productivity/screenshots/${id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorHoursStats(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/contractors/${id}/chart/stats?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorScreenshots(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/contractors/${id}/screenshots?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getWeeklyWorkedHours(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/client/chart/week?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorRealtime(id) {
        try {
            const response = await this.API.get(
                `v1/productivity/contractors/${id}/realtime`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async takeScreenshot(id) {
        try {
            const response = await this.API.post(
                `v1/productivity/contractors/${id}/screenshots/take`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async takeScreenshotBtnActive(id) {
        try {
            const response = await this.API.get(
                `v1/productivity/contractors/${id}/screenshots/active`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteScreenshot(id) {
        try {
            const response = await this.API.delete(
                `v1/productivity/screenshots/${id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getApps(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/applications?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorApps(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/contractors/${id}/applications?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getMostUsedAppsPerType(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/chart/contractors/${id}/apps?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorWeeklyWorkedHours(id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/contractors/${id}/chart/week?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorTimeChart(id) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${id}/chart/time`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorProductivityDetailChart(contractor_id) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${contractor_id}/chart/productivity`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTimeExtra(query = {}) {
        try {
            const response = await this.API.get(
                `v1/productivity/time/client/chart/extra_time?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async screenshotsCarousel(query = {}, screenshot_id) {
        try {
            const response = await this.API.get(
                `v1/productivity/screenshots/${screenshot_id}/carrusel?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAdminHolidays(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/holidays?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    async getHolidaysCountry(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/holidays/search/country?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getHolidaysDays(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/holidays/search/year?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTimePermissions(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addTimePermissions(data) {
        try {
            const response = await this.API.post(
                `v1/admin/time/permissions`,
                data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async editTimePermissions(data) {
        try {
            const response = await this.API.put(
                `v1/admin/time/permissions/${data.permission_id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async deleteTimePermissions(permission_id) {
        try {
            const response = await this.API.delete(
                `v1/admin/time/permissions/${permission_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addHoliday(data) {
        try {
            const response = await this.API.post(`v1/admin/time/holidays`, data)
            return response
        } catch (e) {
            throw e
        }
    }

    async deleteHoliday(holiday_id) {
        try {
            const response = await this.API.delete(
                `v1/admin/time/holidays/${holiday_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editHoliday(data) {
        try {
            const response = await this.API.put(
                `v1/admin/time/holidays/${data.id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async getTimeProfiles(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/profiles?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTimeProfile(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/profiles/search?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addProfile(data) {
        try {
            const response = await this.API.post(`v1/admin/time/profiles`, data)
            return response
        } catch (e) {
            throw e
        }
    }

    async deleteTimeProfile(profile_id) {
        try {
            const response = await this.API.delete(
                `v1/admin/time/profiles/${profile_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTimeHours(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/hours?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editProfile(data) {
        try {
            const response = await this.API.put(
                `v1/admin/time/profiles/${data.id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async editHoursTime(data) {
        try {
            const response = await this.API.put(
                `v1/admin/time/hours/${data.hours_id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }
    async getProfilesType() {
        try {
            const response = await this.API.get(
                `v1/admin/time/profiles/search/type`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editTimes(data) {
        try {
            const response = await this.API.put(
                `v1/productivity/time/client/time/${data.id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async getContractorPermission(contractor_permission_id) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions/contractors/${contractor_permission_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorPermissions(contractor_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions/contractors/details/${contractor_id}?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorsPermissions(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions/contractors?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deletePermission(permission_id) {
        try {
            const response = await this.API.delete(
                `v1/admin/time/permissions/contractors/${permission_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }
    async addContractorPermission(data) {
        try {
            const response = await this.API.post(
                `v1/admin/time/permissions/contractors`,
                data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async editContractorPermission(data) {
        try {
            const response = await this.API.put(
                `v1/admin/time/permissions/contractors/${data.contractor_permission_id}`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async getAvailablePermissions(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions/search?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async changeStatePermission(data) {
        try {
            const response = await this.API.put(
                `v1/admin/time/permissions/contractors/${data?.contractor_permission_id}/state`,
                data.data
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async getContractorContract(contractor_id) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${contractor_id}/contract`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPermissionAmountGraph(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions/contractors/charts/amount_to_pay?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPermissionTypesGraph(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions/contractors/charts/top_five?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPermissionsByState(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions/contractors/charts/grouped_by_state?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPermissionsByMonths(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/time/permissions/contractors/charts/grouped_by_month?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async PaymentGatewayPaymentLink(checkout_session_id) {
        try {
            return await this.API.get(
                `v1/payment_gateway/checkout_session/${checkout_session_id}/payment`
            )
        } catch (e) {
            throw e
        }
    }

    async PaymentGatewayChangeCashInMethod(checkout_session_id, data) {
        try {
            const response = await this.API.post(
                `v1/payment_gateway/checkout_session/${checkout_session_id}/change`,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async bankTransfersSelect(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/bank_transfers?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async bankTransfersInfo(bank_transfer_id) {
        try {
            const response = await this.API.get(
                `v1/admin/payroll/payments/bank_transfers/${bank_transfer_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getCashinMethods(checkout_session_id) {
        try {
            const response = await this.API.get(
                `v1/payment_gateway/cashin_methods/checkout_session/${checkout_session_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async uploadVoucherTransfer(data, checkout_session_id) {
        try {
            const response = await this.API.post(
                `v1/payment_gateway/checkout_session/${checkout_session_id}/bank_transfer`,
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async clientServices() {
        try {
            const response = await this.API.get(`v1/admin/clients/services`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async clientServicesInvoices(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/clients/services/invoices?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getContractorServices(contractor_id) {
        try {
            const response = await this.API.get(
                `v1/admin/contractors/${contractor_id}/metadata`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addContractExist(data, contractor_id) {
        try {
            const response = await this.API.post(
                `v1/admin/contractors/${contractor_id}/no_contract_process_file`,
                { ...data.data, file: data.file },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getServicesDays(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/clients/services/search/year?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getInvoicesDays(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/invoices/search/year?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getClientsCreditCards(query = {}) {
        try {
            const response = await this.API.get(
                `v1/admin/payment_method?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createCheckoutSessionAddCreditCard() {
        try {
            const response = await this.API.post(
                `v1/payment_gateway/customer/payment_method/session`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addCreditCardSucceeded(data) {
        try {
            const response = await this.API.post(
                `v1/payment_gateway/customer/payment_method/session/succeeded`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deletePaymentMethodCard(payment_method_id) {
        try {
            const response = await this.API.delete(
                `v1/admin/payment_method/${payment_method_id}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async selectMainPaymentMethod(data) {
        try {
            const response = await this.API.put(
                `v1/admin/payment_method/${data.payment_method_id}`,
                { ...data.data }
            )
            return response
        } catch (e) {
            throw e
        }
    }

    async clientHealth() {
        try {
            const response = await this.API.get(`v1/admin/clients/health`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getAllProjects(query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createProject(data) {
        try {
            const response = await this.API.post(`v1/project/project`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editProject(data, porject_id) {
        try {
            const response = await this.API.put(
                `v1/project/project/${porject_id}`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async archivePorject(data, porject_id) {
        try {
            const response = await this.API.put(
                `v1/project/project/${porject_id}/status`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteProject(porject_id) {
        try {
            const response = await this.API.delete(
                `v1/project/project/${porject_id}`
            )
            return response.data
        } catch (error) {
            throw error
        }
    }

    async getPersonsInProjects(query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/contractors?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProjectBudgetGraph(query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/budget?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProjectHoursGraph(query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/estimated_hours?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProjectsTasksAssignedGraph(query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/task_asigned?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProjectDeviationGraph(query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/desviations?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProjectStatesGraph(query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/status_projects?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProjectKanbanColumns(project_id) {
        try {
            const response = await this.API.get(
                `v1/project/project/${project_id}/columns`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProjectKanbanTasks(project_column_id) {
        try {
            const response = await this.API.get(
                `v1/project/project/${project_column_id}/column`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProjectInfo(project_id) {
        try {
            const response = await this.API.get(
                `v1/project/project/${project_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getBudgetOneProject(project_id) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/budget/${project_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getHoursOneProject(project_id) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/estimated_hours/${project_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTaskAssignedOneProject(project_id) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/task_asigned/${project_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getDeviationOneProject(project_id) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/desviations/${project_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createColumnKanban(data, project_id) {
        try {
            const response = await this.API.post(
                `v1/project/project/${project_id}/column`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editColumnKanban(data, project_column_id) {
        try {
            const response = await this.API.put(
                `v1/project/project/${project_column_id}/column`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteColumnKanban(project_column_id) {
        try {
            const response = await this.API.delete(
                `v1/project/project/${project_column_id}/column`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteAllTasksInColumnKanban(project_column_id) {
        try {
            const response = await this.API.delete(
                `v1/project/project/${project_column_id}/column/tasks`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async createTaskKanban(data) {
        try {
            const response = await this.API.post(`v1/project/tasks`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editTaskKanban(data, task_id) {
        try {
            const response = await this.API.put(
                `v1/project/tasks/${task_id}`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async moveTaskKanban(data, task_id) {
        try {
            const response = await this.API.put(
                `v1/project/tasks/${task_id}/moved`,
                data
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addAttachmentInTask(data, task_id) {
        try {
            const formData = new FormData()

            data.file.forEach(file => {
                formData.append('file', file)
            })
            /* formData.append('file', data.file) */
            const response = await this.API.post(
                `v1/project/tasks/attachment/${task_id}`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getHoursMembersInProject(project_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/contractors/${project_id}/hours?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProductivityMembersInProject(project_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/contractors/${project_id}/productivity?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonsInOneProject(project_id, query = '') {
        try {
            const response = await this.API.get(
                `v1/project/project/contractors/${project_id}?${
                    query && `&query=${query}`
                }`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTaskInfo(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/tasks/${task_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTaskChecklist(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/tasks/${task_id}/checks`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTaskAttachments(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/tasks/${task_id}/attachments`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addChecklistItem(data) {
        try {
            const response = await this.API.post(`v1/project/tasks/check_list`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editChecklistItem(checklist_item_id,data) {
        try {
            const response = await this.API.put(`v1/project/tasks/${checklist_item_id}/check_list`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async addCommentInTask(data) {
        try {
            const response = await this.API.post(`v1/project/tasks/comment`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteTask(task_id) {
        try {
            const response = await this.API.delete(
                `v1/project/tasks/${task_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteChecklistItem(checklist_item_id) {
        try {
            const response = await this.API.delete(
                `v1/project/tasks/${checklist_item_id}/check_list`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getHoursOneTaskGraph(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/estimated_hours/task/${task_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getBudgetOneTaskGraph(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/budget/task/${task_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getChecklistOneTaskGraph(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/status_checks/task/${task_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getDeviationOneTaskGraph(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/desviations/task/${task_id}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getCommentsOneTask(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/tasks/${task_id}/comments`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteAttachmentInTask(data) {
        try {
            const response = await this.API.delete(`v1/project/tasks/attachment/delete`,{data})
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getArchivedTasks(project_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/${project_id}/tasks_archived?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async archiveTask(task_id,data) {
        try {
            const response = await this.API.post(`v1/project/tasks/${task_id}/archived`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async approveDisapprovedTask(task_id, data) {
        try {
            const response = await this.API.post(`v1/project/tasks/${task_id}/approve`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async deleteCommentInTask(comment_id) {
        try {
            const response = await this.API.delete(`v1/project/tasks/${comment_id}/comment`)
            return response.data
        } catch (e) {
            throw e
        }
    }

    async editCommentInTask(comment_id,data) {
        try {
            const response = await this.API.put(`v1/project/tasks/${comment_id}/comment`, data)
            return response.data
        } catch (e) {
            throw e
        }
    }
    
    async getHoursMembersInTask(task_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/tasks/${task_id}/hours?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getProductivityMembersInTask(task_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/tasks/${task_id}/productivity?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getTasksTable(task_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/${task_id}/tasks?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getColumnOptionsSelect(project_id, query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/${project_id}/columns_select?${stringQueryByJson(
                    query
                )}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsBudgetGraph(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/budget/contractor/${contractor_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsHoursGraph(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/estimated_hours/contractor/${contractor_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsStatesGraph(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/status_projects/contractor/${contractor_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsDeviationGraph(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/desviations/contractor/${contractor_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjects(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/contractor/${contractor_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsHours(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/contractor/${contractor_id}/hours?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsProductivity(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/contractor/${contractor_id}/productivity?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getActivityTask(task_id) {
        try {
            const response = await this.API.get(
                `v1/project/tasks/${task_id}/activity`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTimeBudgetGraph(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/budget/contractor/${contractor_id}/projects?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTimeHoursGraph(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/estimated_hours/contractor/${contractor_id}/projects?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTimeStatusGraph(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/status_projects/contractor/${contractor_id}/projects?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTimeDeviationGraph(contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/desviations/contractor/${contractor_id}/projects?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTasksTable(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/contractor/${contractor_id}/project/${project_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTasksBudgetGraph(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/budget/contractor/${contractor_id}/projects/${project_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTasksHoursGraph(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/estimated_hours/contractor/${contractor_id}/projects/${project_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTasksStatusTaskGraph(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/status_tasks/contractor/${contractor_id}/projects/${project_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTasksDeviationGraph(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/desviations/contractor/${contractor_id}/projects/${project_id}?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsTasksTime(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/contractor/${contractor_id}/project/${project_id}/hours?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectDetailTimeHoursGraph(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/estimated_hours/contractor/${contractor_id}/projects/${project_id}/hours?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectDetailTimeBudgetGraph(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/budget/contractor/${contractor_id}/projects/${project_id}/hours?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectDetailTimeStatusTasksGraph(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/status_tasks/contractor/${contractor_id}/projects/${project_id}/hours?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectDetailTimeDeviationGraph(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/dashboard/desviations/contractor/${contractor_id}/projects/${project_id}/hours?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonProjectsDetailProductivity(contractor_id,project_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/project/contractor/${contractor_id}/project/${project_id}/productivity?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonMarkingsInTask(task_id,contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/time/task/${task_id}/contractor/${contractor_id}/?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

    async getPersonMarkingsDaysInTask(task_id,contractor_id,query = {}) {
        try {
            const response = await this.API.get(
                `v1/project/time/task/${task_id}/contractor/${contractor_id}/days?${stringQueryByJson(query)}`
            )
            return response.data
        } catch (e) {
            throw e
        }
    }

}
