import { createSlice } from '@reduxjs/toolkit'

const initialState = []
export const payrollDetailPositionSlice = createSlice({
  name: 'payrollDetailPositionSlice',
  initialState,
  reducers: {
    setPayrollDetailPosition: (state, action) => {
      return (state = action.payload)
    }
  }
})
export const { setPayrollDetailPosition } = payrollDetailPositionSlice.actions
export default payrollDetailPositionSlice.reducer
