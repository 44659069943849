import { useEffect, useState } from 'react'
import { HasPermissionModules } from '@Intelli/utilities'
import { useApplicationModules } from './ApplicationModules.hook'
import searchFirstModuleActiveFunction from '@src/utility/searchFirstModuleActiveFunction'

const usePermissionsProductivity = () => {
    const { modules } = useApplicationModules()

    const searchFirstModuleActive =
        searchFirstModuleActiveFunction('PRODUCTIVITY')
    const [permissionDashboard, setPermissionDashboard] = useState(false)
    const [permissionApps, setPermissionApps] = useState(false)
    const [permissionTimeline, setPermissionTimeline] = useState(false)
    const [permissionScreenshots, setPermissionScreenshots] = useState(false)
    const [permissionWriteScreenshots, setPermissionWriteScreenshots] =
        useState(false)

    useEffect(() => {
        setPermissionDashboard(
            HasPermissionModules.Read(modules, [
                'PRODUCTIVITY',
                'PRODUCTIVITY-DASHBOARD'
            ])
        )
        setPermissionApps(
            HasPermissionModules.Read(modules, [
                'PRODUCTIVITY',
                'PRODUCTIVITY-APPLICATIONS'
            ])
        )
        setPermissionTimeline(
            HasPermissionModules.Read(modules, [
                'PRODUCTIVITY',
                'PRODUCTIVITY-TIMELINE'
            ])
        )
        setPermissionScreenshots(
            HasPermissionModules.Read(modules, [
                'PRODUCTIVITY',
                'PRODUCTIVITY-SCREENSHOTS'
            ])
        )

        setPermissionWriteScreenshots(
            HasPermissionModules.WriteUpdate(modules, [
                'PRODUCTIVITY',
                'PRODUCTIVITY-SCREENSHOTS'
            ])
        )
    }, [])

    return {
        searchFirstModuleActive,
        permissionDashboard,
        permissionApps,
        permissionTimeline,
        permissionScreenshots,
        permissionWriteScreenshots
    }
}

export default usePermissionsProductivity
