import { faPlus, faText, faTrashCan } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Button, FormFeedback, Input, ListGroup, ListGroupItem, UncontrolledTooltip } from "reactstrap"
import ContractClauseModal from "./modals/ContractClauseModal"
import { SpinnerLoader, JpAvatar } from "@Intelli/utilities"

const ContractClausesList = ({ clauses, setClauses }) => {

    const { t } = useTranslation()

    const [createModal, setCreateModal] = useState(false)

    const handleClauseDelete = (order) => {
        setClauses(clauses.filter(clause => clause.order !== order))
    }

    const handleNewClause = (clause) => {
        if (clauses.length === 0) {
            setClauses([...clauses, {...clause, order: clauses.length + 1 }])
        } else {
            setClauses([...clauses, {...clause, order: clauses[clauses.length - 1].order + 1 }])
        }
    }

    const formCtx = useFormContext()

    useEffect(() => {
        if (formCtx) {
            formCtx.setValue('clauses', clauses)
        }
    }, [clauses])

    if (!formCtx) return <SpinnerLoader />

    const { 
        control,
        formState: { errors } 
    } = formCtx

    return (
        <>
            {/* <div className="contract-clauses-list scroll-list pe-1"> */}
            <ListGroup flush className="scroll-list">
                {clauses.map((clause, index) => (
                    <ListGroupItem className="d-flex justify-content-between p-1" key={index}> 
                        <div className="d-flex align-items-center">
                            <JpAvatar
                                className="me-75"
                                icon={<FontAwesomeIcon icon={faText} />}
                                color="light-success"
                            />
                            <p className="m-0">{clause.title}</p>
                        </div>
                        <UncontrolledTooltip
                            placement="top"
                            target={`delete-${clause.order}`}
                        >
                            {t('views.newContract.steps.contract.deleteClause')}
                        </UncontrolledTooltip>
                        <FontAwesomeIcon id={`delete-${clause.order}`} className="table-action text-primary" icon={faTrashCan} onClick={() => handleClauseDelete(clause.order)}/>
                    </ListGroupItem>
                ))}
            {/* </div> */}
            </ListGroup>
            <Controller
                name="clauses"
                control={control}
                render={({ field }) => (
                    <Input
                        type="hidden"
                        invalid={errors?.clauses && true}
                        {...field}
                    />
                )}
            />
            <FormFeedback invalid> {errors?.clauses?.message} </FormFeedback>
            {/* <div className="text-end mt-2">
                {clauses.length < 10 && <Button color="info" type="button" onClick={() => setCreateModal(true)}>
                    <FontAwesomeIcon icon={faPlus} className="me-75" />
                    {t('views.newContract.steps.contract.addClause')}
                </Button>}
            </div>
            <ContractClauseModal isOpen={createModal} toggle={() => setCreateModal(false)} handleNewClause={handleNewClause}/> */}
        </>
    )
}

export default ContractClausesList