import {
    JpStatsIconCard,
    JpCurrencyNumber,
    parseMinutesToHours,
    JpDonutWithTextGraph,
    JpListCardGraph,
    PROJECTS_STATE_COLORS
} from '@Intelli/utilities'
import { Col, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import AdminAPI from '@src/services/API'
import { useParams } from 'react-router-dom'

const PersonsProjectsGraphs = ({ startDate, endDate }) => {
    const { t } = useTranslation()
    const API = new AdminAPI()
    const { contractor_id, id } = useParams()

    const {
        data: budgetGraph,
        isLoading: budgetGraphLoading,
        refetch: budgetGraphRefetch
    } = useQuery(
        ['getPersonProjectsBudgetGraph', startDate, endDate],
        () =>
            API.getPersonProjectsBudgetGraph(contractor_id || id, {
                start_date__gte: `${
                    startDate
                        ? moment(startDate)
                              .format('YYYY-MM-DD')
                        : ''
                }`,
                end_date__lte: `${
                    endDate ? moment(endDate).format('YYYY-MM-DD') : ''
                }`
            }),
        {
            keepPreviousData: true
        }
    )

    const {
        data: hoursGraph,
        isLoading: hoursGraphLoading,
        refetch: hoursGraphRefetch
    } = useQuery(
        ['getProjectHoursGraph', startDate, endDate],
        () =>
            API.getPersonProjectsHoursGraph(contractor_id || id, {
                start_date__gte: `${
                    startDate
                        ? moment(startDate)
                              .format('YYYY-MM-DD')
                        : ''
                }`,
                end_date__lte: `${
                    endDate ? moment(endDate).format('YYYY-MM-DD') : ''
                }`
            }),
        {
            keepPreviousData: true
        }
    )

    const {
        data: statesGraph,
        isLoading: statesGraphLoading,
        refetch: statesGraphhRefetch
    } = useQuery(
        ['getPersonProjectsStatesGraph', startDate, endDate],
        () =>
            API.getPersonProjectsStatesGraph(contractor_id || id, {
                start_date__gte: `${
                    startDate
                        ? moment(startDate)
                              .format('YYYY-MM-DD')
                        : ''
                }`,
                end_date__lte: `${
                    endDate ? moment(endDate).format('YYYY-MM-DD') : ''
                }`
            }),
        {
            keepPreviousData: true
        }
    )

    const {
        data: deviationGraph,
        isLoading: deviationGraphLoading,
        refetch: deviationGraphRefetch
    } = useQuery(
        ['getPersonProjectsDeviationGraph', startDate, endDate],
        () =>
            API.getPersonProjectsDeviationGraph(contractor_id || id, {
                start_date__gte: `${
                    startDate
                        ? moment(startDate)
                              .format('YYYY-MM-DD')
                        : ''
                }`,
                end_date__lte: `${
                    endDate ? moment(endDate).format('YYYY-MM-DD') : ''
                }`
            }),
        {
            keepPreviousData: true
        }
    )

    const statesData = statesGraph?.projects
        ? Object.entries(statesGraph?.projects)
              .filter(([key]) => key !== 'count')
              .map(([key, value]) => ({
                  status: key,
                  ...value
              }))
              .map(item => ({
                  color: PROJECTS_STATE_COLORS[item.status.toUpperCase()],
                  percent: item.percent,
                  name: t(
                      `views.projects.projectStates.${item.status.toUpperCase()}`
                  ),
                  key: item.status.toUpperCase(),
                  quantity: item.quantity
              }))
        : []

    const labelsStatesProjects = statesGraph?.projects
        ? Object.keys(statesGraph?.projects)
              .filter(item => item !== 'count')
              .map(item => {
                  return {
                      key: item,
                      name: t(
                          `views.projects.projectStates.${item.toUpperCase()}`
                      )
                  }
              })
        : []

    const percentStateProjects = labelsStatesProjects?.map(key =>
        parseFloat(statesGraph?.projects[key.key].quantity)
    )
    const colorStateProjects = labelsStatesProjects?.map(
        key => PROJECTS_STATE_COLORS[key.key.toUpperCase()]
    )

    return (
        <Row className="mb-2">
            <Col lg="3" md="6" xs="12" className="py-50  py-lg-0">
                <JpStatsIconCard
                    loading={budgetGraphLoading}
                    stat={
                        <JpCurrencyNumber value={budgetGraph?.budgetExecuted} />
                    }
                    statSize="sm"
                    icon={<FontAwesomeIcon icon={'fa-solid fa-dollar-sign'} />}
                    iconBgColor="green"
                    iconSize="xl"
                    iconDirection="left"
                    title={`${t('views.projects.budget')} ${t(
                        'views.projects.executed'
                    ).toLowerCase()}`}
                    subtitlePercentage={false}
                    spanText={
                        <div>
                            <span className="me-50">{t('assigned')}</span>

                            <JpCurrencyNumber
                                value={budgetGraph?.totalBudget}
                            />
                        </div>
                    }
                    bodyClassName="py-0"
                />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-50  py-lg-0">
                <JpStatsIconCard
                    loading={hoursGraphLoading}
                    stat={parseMinutesToHours(hoursGraph?.hoursExecuted)}
                    statSize="sm"
                    icon={<FontAwesomeIcon icon={'fa-solid fa-clock'} />}
                    iconBgColor="info"
                    iconSize="xl"
                    iconDirection="left"
                    title={`${t('views.projects.hoursExecuted')}`}
                    subtitlePercentage={false}
                    spanText={
                        <div>
                            <span className="me-50">
                                {t('views.projects.planneds')}
                            </span>
                            <span>
                                {parseMinutesToHours(hoursGraph?.totalHours)}
                            </span>
                        </div>
                    }
                    bodyClassName="py-0"
                />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-50  py-lg-0">
                <JpListCardGraph
                    title={t('views.projects.deviation')}
                    dataValues={deviationGraph}
                    loading={deviationGraphLoading}
                    containerClassName={'my-75'}
                />
            </Col>
            <Col lg="3" md="6" xs="12" className="py-50  py-lg-0">
                <JpDonutWithTextGraph
                    isLoading={statesGraphLoading}
                    title={t('views.projects.projectStatesTitle')}
                    bodyClassName=""
                    series={percentStateProjects}
                    labels={labelsStatesProjects.map(item => item.name)}
                    headerClassName="pb-0 pb-0 pt-75"
                    colors={colorStateProjects}
                    defaults={{
                        label: `Total`,
                        value: parseFloat(statesGraph?.projects?.count)
                    }}
                    IntLabelFormat
                    customFormatterTotal={val => {
                        return percentStateProjects.every(item => item === 0)
                            ? 0
                            : val?.config?.series?.reduce(
                                  (acc, value) => acc + value,
                                  0
                              )
                    }}
                    customSize="65%"
                    height={100}
                    customBody={
                        <div className="d-flex h-100 flex-column  justify-content-center">
                            {statesData.map(item => (
                                <div
                                    key={item.key}
                                    className="d-flex my-50 gap-1"
                                >
                                    <div
                                        className={`bg-${item.color}`}
                                        style={{
                                            borderRadius: '90px 90px 90px 90px',
                                            width: '16px',
                                            height: '16px',
                                            marginLeft: 7,
                                            border: 'solid white 0.5px'
                                        }}
                                    ></div>
                                    <small>{`${item.name}: ${item.quantity}`}</small>
                                </div>
                            ))}
                        </div>
                    }
                />
            </Col>
        </Row>
    )
}

export default PersonsProjectsGraphs
