import {
    JpAvatar,
    JpText,
    JpLabel,
    JpModal,
    RatingStars,
    JpCurrencyNumber,
    dateLocalizer
} from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'reactstrap'
import { parseName } from '@src/utility/Utils'
import { Link } from 'react-router-dom'

const RatingModalInfo = ({
    isOpen,
    onClose,
    ratingData = [],
    customHeader
}) => {
    if (!isOpen) {
        return ''
    }

    const { t } = useTranslation()

    const title = ratingData.rating_of
        ? `${ratingData.rating_of} - ${t('views.projects.ratings')}`
        : t('views.projects.ratings')

    return (
        <>
            <JpModal
                isOpen={isOpen}
                toggle={onClose}
                headerContent={<>{title}</>}
                options={{
                    modalOptions: {
                        size: 'lg',
                        centered: true
                    }
                }}
                bodyContent={
                    <div
                        className="rate-modal-height"
                        style={{
                            maxHeight: '600px',
                            overflow: 'auto',
                            overflowX: 'hidden'
                        }}
                    >
                        <Row className="mb-1">
                            {customHeader && customHeader}
                            {ratingData.allRatings.length > 0
                                ? ratingData.allRatings.map((rating, index) => {
                                      const isLast =
                                          index ===
                                          ratingData.allRatings.length - 1
                                      return (
                                          <Row
                                              key={`rating-${index}`}
                                              className={`align-items-center justify-content-between ${
                                                  !isLast
                                                      ? 'border-bottom pb-1 mb-1'
                                                      : ''
                                              }`}
                                          >
                                              <Row className='align-items-center'>
                                                  <Col
                                                      sm="4"
                                                      className="d-flex align-items-center gap-1"
                                                  >
                                                      {rating?.user_photo
                                                          ?.photo ? (
                                                          <div className="d-flex align-items-end">
                                                              <JpAvatar
                                                                  color={`light-info`}
                                                                  img={
                                                                      rating
                                                                          ?.user_photo
                                                                          ?.photo
                                                                  }
                                                                  imgClassName="avatar-cover cursor-default"
                                                              />
                                                          </div>
                                                      ) : (
                                                          <div className="d-flex align-items-end">
                                                              <JpAvatar
                                                                  color={`light-info`}
                                                                  content={`${rating.first_name}`}
                                                                  initials
                                                                  imgClassName="avatar-cover cursor-default"
                                                              />
                                                          </div>
                                                      )}
                                                      <div>
                                                          <Link
                                                              to={
                                                                  rating.url_link
                                                              }
                                                              className="user-info"
                                                          >
                                                              <div>
                                                                  <span className="d-block text-truncate">
                                                                      {parseName(
                                                                          rating.first_name,
                                                                          rating.last_name
                                                                      )}
                                                                  </span>
                                                              </div>
                                                          </Link>
                                                          <small className="fs-75 text-truncate">
                                                              {rating.position}
                                                          </small>
                                                      </div>
                                                  </Col>

                                                  {rating.budget ? (
                                                      <Col
                                                          sm="4"
                                                          className="d-flex flex-column"
                                                      >
                                                          <JpLabel>{`${t(
                                                              'views.projects.budgetForTask'
                                                          )}`}</JpLabel>

                                                          <JpCurrencyNumber
                                                              value={
                                                                  rating.budget
                                                              }
                                                          />
                                                      </Col>
                                                  ) : (
                                                      ''
                                                  )}

                                                  <Col sm="4">
                                                      <RatingStars
                                                          initialRating={
                                                              rating.rating
                                                          }
                                                          readonly={true}
                                                      />
                                                  </Col>
                                              </Row>

                                              <Row className="my-1 align-items-center justify-content-between gap-1 gap-md-0">
                                                  <Col
                                                      sm="12"
                                                      md="4"
                                                      className="d-flex flex-column"
                                                  >
                                                      <JpLabel>{`${t(
                                                          'views.documents.documentsTable.date'
                                                      )}:`}</JpLabel>

                                                      <JpText
                                                          type="p-text"
                                                          children={`${dateLocalizer.getIntlLongDate(
                                                              rating.rating_date
                                                          )}`}
                                                      />
                                                  </Col>
                                                  <Col sm="12" md="4">
                                                      <JpLabel>{`${t(
                                                          'views.projects.project'
                                                      )}:`}</JpLabel>

                                                      <JpText
                                                          type="p-text"
                                                          children={`${rating.name_project}`}
                                                      />
                                                  </Col>
                                                  <Col sm="12" md="4">
                                                      <JpLabel>{`${t(
                                                          'views.projects.task'
                                                      )}:`}</JpLabel>

                                                      <JpText
                                                          type="p-text"
                                                          children={`${rating.name_task}`}
                                                      />
                                                  </Col>
                                              </Row>

                                              {rating.comment ? (
                                                  <Col sm="12" className="mt-1">
                                                      <JpLabel>{`${t(
                                                          'views.projects.comment'
                                                      )}:`}</JpLabel>

                                                      <JpText
                                                          type="p-text"
                                                          children={`"${rating.comment}"`}
                                                      />
                                                  </Col>
                                              ) : (
                                                  ''
                                              )}
                                          </Row>
                                      )
                                  })
                                : ''}
                        </Row>
                    </div>
                }
            />
        </>
    )
}

export default RatingModalInfo
