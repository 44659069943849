import Wizard from '@src/components/general/stepper'
import AdminAPI from '@src/services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Methods from './steps/Methods'
import Types from './steps/Types'
import {
    BlueHeaderCard,
    SpinnerLoader,
    SweetModal,
    JpPDF,
    JpPDFVertical,
    JpButton,
    parseName
} from '@Intelli/utilities'
import ContractorInfo from './steps/ContractorInfo'
import ContractDataV2 from './steps/ContractDataV2'
import moment from 'moment'
import Summary from './steps/Summary'
import { useDispatch } from 'react-redux'
import { addBreadcrumbs } from '@src/redux/breadcrumbs'

const getDefaultValues = contractorData => {
    const additionalFields = [
        'HOURS_PER_DAY',
        'START_DATE',
        'END_DATE',
        'POSITION',
        'SALARY',
        'RESPONSIBILITIES'
    ]

    const baseDefaultValues = {
        start_date: moment().format('YYYY-MM-DD'),
        // end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
        end_date: '',
        responsibilities: '',
        legal_representative_id: undefined,
        payment_rule_id: null,
        salary: 0,
        teams_id: [],
        productivity_profile_id: null,
        productivity_active: false,
        time_active: false
    }

    if (!!contractorData.teams) {
        baseDefaultValues.teams_id = contractorData.teams.map(team => ({
            value: team.id,
            label: team.name
        }))
    }

    if (!!contractorData.contractor_metadata.CONTRACT_TEMPLATE_ID) {
        baseDefaultValues.contract_template_id =
            contractorData.contractor_metadata.CONTRACT_TEMPLATE_ID
    }

    if (contractorData.productivity.profile !== null) {
        baseDefaultValues.productivity_profile_id = {
            value: contractorData.productivity.profile?.id,
            label: contractorData.productivity.profile?.name
        }
    }
    if (!!contractorData.time.active) {
        baseDefaultValues.time_active = contractorData?.time?.active
    }

    if (!!contractorData.productivity.active) {
        baseDefaultValues.productivity_active =
            contractorData?.productivity?.active
    }

    if (contractorData.contractor_metadata) {
        const additionalDefaultValues = {}
        additionalFields.forEach(field => {
            if (!!contractorData.contractor_metadata[field]) {
                additionalDefaultValues[field.toLowerCase()] =
                    contractorData.contractor_metadata[field]
            }
        })
        return { ...baseDefaultValues, ...additionalDefaultValues }
    }

    return baseDefaultValues
}

const NewContractV2 = () => {
    const [method, setMethod] = useState(null)
    const [types, setTypes] = useState([])
    const [stepper, setStepper] = useState()
    const [type, setType] = useState('')
    const [activeIndex, setActiveIndex] = useState(0)
    const [defaultValues, setDefaultValues] = useState(null)
    const [proccessData, setProccessData] = useState(null)
    const refStepper = useRef(null)
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const API = new AdminAPI()
    const { id } = useParams()
    const navigate = useNavigate()

    const [shouldContinue, setShouldContinue] = useState(true)
    const [shouldUpdate, setShouldUpdate] = useState(false)

    const {
        data: contractorData,
        isSuccess: contractorIsSuccess,
        refetch
    } = useQuery(['contractorData', id, shouldUpdate], () =>
        API.getContractor(id)
    )

    const {
        data: legalRepresentativesData,
        isSuccess: legalRepresentativesIsSuccess
    } = useQuery(
        ['legalRepresentatives'],
        () => API.getLegalRepresentatives(),
        {
            enabled: !!contractorData
        }
    )

    const LRmatchesWithContractor = legalRepresentativesData?.data?.find(
        item => item.user_id === contractorData?.user.id
    )

    useEffect(() => {
        if (legalRepresentativesData?.count === 1 && LRmatchesWithContractor) {

            SweetModal(
                'warning',
                'Error',
                t('views.newContract.LRMatchesContractor'),
                t('Ok'),
                () => {
                    navigate(-1)
                }
            )
        }
    }, [LRmatchesWithContractor, legalRepresentativesData])

    dispatch(
        addBreadcrumbs({
            id,
            value: [
                {
                    name: parseName(
                        contractorData?.user?.first_name,
                        contractorData?.user?.last_name
                    ),
                    last: true
                }
            ]
        })
    )

    /*     useEffect(() => {
        if (contractorIsSuccess && stepper) {
            let skipTo = 0
            if (contractorData.modality) {
                setMethod(contractorData.modality)
                skipTo = 2
            }
            if (
                contractorData.contract_type_name &&
                contractorData.contract_type_id
            ) {
                setType({
                    name: contractorData.contract_type_name,
                    id: contractorData.contract_type_id
                })
                skipTo = 3
            }
            if (skipTo > 0) {
                stepper.to(skipTo)
            }

            if (!defaultValues) {
                const newDefaultValues = getDefaultValues(contractorData)
                setDefaultValues(newDefaultValues)
            }
        }
    }, [contractorIsSuccess, stepper]) */

    const { mutate: setContractData } = useMutation(
        data => API.changeContractorStatus(data),
        {
            onSuccess: (data, variables) => {
                if (variables.data.modality === 'WE_EMPLOY_FOR_YOU') {
                    stepper.to(3)
                } else {
                    stepper.next()
                }
            }
        }
    )

    const steps = [
        {
            id: 'methods',
            subtitle: t('views.newContract.steps.employer.title'),
            containerClass: 'card-min-height',
            content: <Methods method={method} setMethod={setMethod} />
        },
        {
            id: 'types',
            subtitle: t('views.newContract.steps.type.title'),
            containerClass: 'card-min-height',
            content: (
                <Types
                    type={type}
                    setType={setType}
                    setTypes={setTypes}
                    method={method}
                />
            )
        },
        {
            id: 'contractor',
            subtitle: t('views.newContract.steps.contractor.title'),
            content: (
                <BlueHeaderCard
                    headerContent={
                        <h4 className="text-white m-0">
                            {t('views.newContract.contractorBasicData')}
                        </h4>
                    }
                    bodyClassName="row"
                    bodyContent={
                        <ContractorInfo
                            contractorData={contractorData}
                            setShouldContinue={setShouldContinue}
                        />
                    }
                />
            )
        },
        {
            id: 'contract',
            subtitle: t('views.newContract.steps.contract.title'),
            containerClass: 'bg-transparent border-0 shadow-none',
            content: (
                <ContractDataV2
                    legalRepresentativesData={legalRepresentativesData}
                    LRmatchesWithContractor={LRmatchesWithContractor}
                    method={method}
                    type={type}
                    contractorData={contractorData}
                    defaultValues={defaultValues}
                    stepper={stepper}
                    setProccessData={setProccessData}
                />
            )
        },
        {
            id: 'previrew',
            subtitle: t('views.newContract.steps.preview.title'),
            content: (
                <JpPDFVertical
                    // title={t('views.newContract.steps.preview.previewContract')}
                    // headerColor="info"
                    pdf={localStorage.getItem('previewURL') || ''}
                />
            ),
            containerClass: 'bg-transparent border-0 shadow-none'
        },
        {
            id: 'summary',
            subtitle: t('views.newContract.steps.summary.title'),
            containerClass: 'bg-transparent border-0 shadow-none',
            content: (
                <Summary
                    contractorData={contractorData}
                    data={proccessData}
                    type={type}
                    stepper={stepper}
                />
            )
        }
    ]

    const handleNext = () => {
        switch (activeIndex) {
            case 0:
                if (!method)
                    return SweetModal(
                        'warning',
                        'Error',
                        t('form.contractors.MODALITY.required', {
                            ns: 'errors'
                        }),
                        'Ok'
                    )
                else {
                    const typeData =
                        method === 'WE_EMPLOY_FOR_YOU'
                            ? {
                                  contract_type_id: types.filter(
                                      type => type.name === 'CLASSIC'
                                  )[0].id
                              }
                            : {}
                    setContractData({
                        data: { modality: method, ...typeData },
                        id
                    })
                    setShouldUpdate(!shouldUpdate)
                }
                break
            case 1:
                if (!type)
                    return SweetModal(
                        'warning',
                        'Error',
                        t('form.contractors.CONTRACT_TYPE.required', {
                            ns: 'errors'
                        }),
                        'Ok'
                    )
                else {
                    setContractData({ data: { contract_type_id: type.id }, id })
                }
                break
            default:
                stepper.next()
        }
    }

    if (!contractorIsSuccess) return <SpinnerLoader />
    return (
        <>
            <Wizard
                type="modern-horizontal"
                boxType="circle"
                stepTextPosition="bottom"
                ref={refStepper}
                steps={steps}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                instance={el => setStepper(el)}
                headerClassName="d-flex justify-content-center p-0"
                contentWrapperClassName="p-0 mt-1 card"
                className="register-multi-steps-wizard shadow-none h-100"
            />
            <div
                className={
                    activeIndex !== 0
                        ? 'd-flex justify-content-between'
                        : 'text-end'
                }
            >
                {![0, 3, 5].includes(activeIndex) && (
                    <JpButton
                        options={{
                            onClick: () => {
                                if (
                                    activeIndex === 2 &&
                                    method === 'WE_EMPLOY_FOR_YOU'
                                ) {
                                    stepper.to(1)
                                } else {
                                    stepper.previous()
                                }
                            },
                            iconPosition: 'left'
                        }}
                        type="back"
                    />
                )}
                {![3, 5].includes(activeIndex) && (
                    <JpButton
                        options={{
                            onClick: () => handleNext()
                        }}
                        type="next"
                        buttonType={
                            [3, 4].includes(activeIndex) ? 'submit' : 'button'
                        }
                    />
                )}
            </div>
        </>
    )
}

export default NewContractV2
