import {
    JpStatsIconCard,
    JpCurrencyNumber,
    SpinnerLoader,
    JpPieCardTime,
    parseMinutesToHours,
    JpVerticalBarsCard,
    JpPercentageText,
    JpDonutWithTextGraph,
    JpBarsCardV1
} from '@Intelli/utilities'
import {
    faDollarSign,
    faFileInvoiceDollar,
    faUserTieHair,
    faWatch
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useSocket from '@src/hooks/useSocket'
import AdminAPI from '@src/services/API'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'
import updateChartSocket from '@src/utility/updateChartSocket'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { CardTitle, Col, Row, UncontrolledTooltip } from 'reactstrap'

const HoursGraphs = ({ date, endDate }) => {
    const { t } = useTranslation()
    const API = new AdminAPI()
    const { id } = useParams()
    const queryClient = useQueryClient()

    const dateQuery = {
        [endDate ? 'date__between' : 'date']: `${date}${
            endDate ? `,${endDate}` : ''
        }`
    }

    const {
        data,
        isLoading: dataIsLoading,
        refetch: hoursGraphsRefetch
    } = useQuery(
        ['getHoursGraphs', 'lastMonth',date, endDate],
        () =>
            id
                ? API.getContractorHoursStats(id, dateQuery)
                : API.getHoursStats({
                      date: date
                  }),
        {
            keepPreviousData: true
        }
    )

    const {
        data: weeklyWorkedData,
        isLoading: weeklyWorkedLoading,
        refetch: weeklyDataRefetch
    } = useQuery(
        ['weekly-worked',date, endDate],
        () =>
            id
                ? API.getContractorWeeklyWorkedHours(id, dateQuery)
                : API.getWeeklyWorkedHours({
                      date: date
                  }),
        {
            keepPreviousData: true
        }
    )

    const {
        data: timeExtra,
        isLoading: timeExtraLoading,
        refetch: timeExtraRefetch
    } = useQuery(
        ['time-extra',date, endDate],
        () =>
            id
                ? API.getTimeExtra({
                      ...dateQuery,
                      contractor_id: id
                  })
                : API.getTimeExtra({ date: date }),
        {
            keepPreviousData: true
        }
    )

    /* useEffect(() => {
        timeExtraRefetch()
        hoursGraphsRefetch()
        weeklyDataRefetch()
    }, [date, endDate]) */

    const subscriptionDay = endDate || date

    useSocket(
        id && subscriptionDay && disabledRefetchSocket(subscriptionDay)
            ? {
                  [`/time/contractors/${id}/charts/stats`]: {
                      fn: value => {
                          hoursGraphsRefetch()
                      }
                  },
                  [`/time/contractors/${id}/charts/week`]: {
                      fn: value => {
                          weeklyDataRefetch()
                      }
                  },
                  [`/time/contractors/${id}/charts/extra_time`]: {
                      fn: value => {
                          weeklyDataRefetch()
                      }
                  }
              }
            : disabledRefetchSocket(date)
            ? {
                  '/time/charts/stats': {
                      fn: value => {
                          updateChartSocket(
                              queryClient,
                              ['getHoursGraphs', 'lastMonth'],
                              value
                          )
                      }
                  },
                  '/time/charts/week': {
                      fn: value => {
                          updateChartSocket(
                              queryClient,
                              ['weekly-worked'],
                              value
                          )
                      }
                  },
                  '/time/charts/extra_time': {
                      fn: value => {
                          updateChartSocket(queryClient, ['time-extra'], value)
                      }
                  }
              }
            : {},
        [date, endDate]
    )

    const isInRange = value => {
        if (!value || !endDate) return false
        return moment(value) <= moment(endDate) && moment(value) >= moment(date)
    }

    useSocket(
        id
            ? {
                  [`/time/contractors/${id}/workday/hours/edited`]: {
                      fn: value => {
                          const isEqualsDate =
                              moment(value.payload.date).format(
                                  'YYYY-MM-DD'
                              ) === date

                          const range = isInRange(value.payload.date)

                          if (isEqualsDate || range) {
                              timeExtraRefetch()
                          }
                      }
                  }
              }
            : {
                  '/time/workdays/hours/edited': {
                      fn: value => {
                          const isEqualsDate =
                              moment(value.payload.date).format(
                                  'YYYY-MM-DD'
                              ) === date

                          const range = isInRange(value.payload.date)
                          if (isEqualsDate || range) {
                              timeExtraRefetch()
                          }
                      }
                  }
              },
        [date, endDate]
    )

    return (
        <>
            <Row className="mb-2">
                <Col lg="2" sm="6" xs="12" className="py-1 py-lg-0">
                    <JpStatsIconCard
                        stat={
                            <>
                                <span>
                                    {parseMinutesToHours(
                                        data?.hours?.total?.current
                                    )}
                                </span>
                                {Number(data?.hours?.edited) ? (
                                    <>
                                        <FontAwesomeIcon
                                            id="graph-tooltip"
                                            className="text-yellow"
                                            size={'xs'}
                                            icon="fa-solid fa-circle-exclamation"
                                        />
                                        <UncontrolledTooltip
                                            target={'graph-tooltip'}
                                        >
                                            <div className="d-flex flex-column">
                                                <span>{`${t(
                                                    'views.time.timeWorked'
                                                )}: ${parseMinutesToHours(
                                                    data?.hours?.real
                                                )}`}</span>

                                                <span>
                                                    {`${t(
                                                        'views.time.graphs.modifiedTime'
                                                    )}: ${parseMinutesToHours(
                                                        data?.hours?.edited
                                                    )}`}
                                                </span>
                                            </div>
                                        </UncontrolledTooltip>
                                    </>
                                ) : (
                                    ''
                                )}
                            </>
                        }
                        /*  stat={parseMinutesToHours(data?.hours?.current)} */
                        statSize="xs"
                        icon={<FontAwesomeIcon icon={faWatch} />}
                        iconBgColor="info"
                        iconSize="lg"
                        /* spanText={`${50} ${t('Companies')}`} */
                        iconDirection="top"
                        title={t('views.time.graphs.hoursWorked')}
                        subtitle={
                            id
                                ? t('views.time.graphs.lastPeriod')
                                : t('views.time.graphs.lastDay')
                        }
                        subtitlePercentage={parseInt(
                            data?.hours?.total?.percent
                        )}
                        statPosition="bottom"
                        bodyClassName="justify-content-sm-start py-0"
                        className={' text-start'}
                        loading={dataIsLoading}
                    />
                </Col>

                <Col lg="2" sm="6" xs="12" className="py-1 py-lg-0">
                    <JpStatsIconCard
                        stat={<JpCurrencyNumber value={data?.fees?.current} />}
                        statSize="xs"
                        icon={<FontAwesomeIcon icon={faDollarSign} />}
                        iconBgColor="green"
                        iconSize="lg"
                        /* spanText={`${50} ${t('Companies')}`} */
                        iconDirection="top"
                        title={t('views.time.graphs.amountPay')}
                        subtitle={
                            id
                                ? t('views.time.graphs.lastPeriod')
                                : t('views.time.graphs.lastDay')
                        }
                        subtitlePercentage={parseInt(data?.fees?.percent)}
                        statPosition="bottom"
                        bodyClassName="justify-content-sm-start py-0 "
                        className={' text-start'}
                        loading={dataIsLoading}
                    />
                </Col>
                <Col lg="4" sm="12" xs="12">
                    <JpDonutWithTextGraph
                        isLoading={timeExtraLoading}
                        title={t('views.time.graphs.timeExtra')}
                        bodyClassName=""
                        series={[
                            parseFloat(timeExtra?.percent) /* valor revisado */,
                            parseFloat(
                                (100 - timeExtra?.percent).toFixed(2)
                            ) /* valor pendiente */
                        ]}
                        labels={[t('APPROVED'), t('PENDING')]}
                        defaultLabel={t('APPROVED')}
                        headerClassName="pb-0"
                        customBody={
                            <div className="mt-1">
                                <div className="mb-1">
                                    <h5
                                        className="mb-0 fw-normal mb-25"
                                        id="extraTimeWorked"
                                    >
                                        {t('views.time.hrsExtra')}
                                    </h5>
                                    <UncontrolledTooltip
                                        className="text-muted"
                                        target="extraTimeWorked"
                                    >
                                        {t(
                                            'views.time.graphs.extraTimeWorkedTooltip'
                                        )}
                                    </UncontrolledTooltip>
                                    <span>
                                        {parseMinutesToHours(timeExtra?.total)}
                                    </span>
                                </div>
                                <>
                                    <h5
                                        className="fw-normal"
                                        id="extraTimeApproved"
                                    >
                                        {t(
                                            'views.time.graphs.extraTimeApproved'
                                        )}
                                    </h5>
                                    <UncontrolledTooltip
                                        className="text-muted"
                                        target="extraTimeApproved"
                                    >
                                        {t(
                                            'views.time.graphs.extraTimeApprovedTooltip'
                                        )}
                                    </UncontrolledTooltip>
                                    <span>
                                        {parseMinutesToHours(
                                            timeExtra?.approved
                                        )}
                                    </span>
                                </>
                                {/* <JpCurrencyNumber
                                    className="mb-1"
                                    value={
                                        dashboardMonthPaymentsData?.balances
                                            ?.current
                                    }
                                />
                                <div className="d-flex mt-25">
                                    <JpPercentageText
                                        percentage={parseFloat(
                                            dashboardMonthPaymentsData?.balances
                                                ?.percent
                                        )}
                                        periodText={t(
                                            'views.profile.security.thanLastMonth'
                                        )}
                                    />
                                </div> */}
                            </div>
                        }
                    />
                    {/* <JpPieCardTime
                        data={timeExtra}
                        cardClassName="h-100"
                        loading={timeExtraLoading}
                        bodyClassName="pt-75 pb-1"
                        leftInfo={
                            <div className="d-flex flex-column align-items-between justify-content-between h-100">
                                <CardTitle>
                                    {t('views.time.graphs.timeVsExtra')}
                                </CardTitle>
                                <div>
                                    <small className="d-flex my-50">
                                        <JpPercentageText
                                            percentage={timeExtra?.percent}
                                            periodText={`${t(
                                                'views.time.graphs.lastPeriod'
                                            )}`}
                                        />
                                    </small>
                                </div>
                            </div>
                        }
                    /> */}
                </Col>
                {/*                 <Col lg="5" sm="12" xs="12" className="mt-1 mt-lg-0">
                    <JpVerticalBarsCard
                        bodyClassName="justify-content-between pt-75 pb-1"
                        cardClassName="h-100"
                        headerClassName="pb-0"
                        footerClassName="py-50"
                        cardBodyHeight={{ height: 125 }}
                        loading={weeklyWorkedLoading}
                        additionalInfo={
                            <div className="d-flex flex-column align-items-between justify-content-between h-100">
                                <CardTitle>{t('Time')}</CardTitle>
                                <div>
                                    <span>
                                        <h5
                                            id="worked-time"
                                            className="mb-0 fw-normal mb-25"
                                        >
                                            {t('views.time.graphs.workedTime')}
                                        </h5>
                                        <UncontrolledTooltip
                                            target={'worked-time'}
                                        >
                                            {t(
                                                'views.time.graphs.workedTimeTool'
                                            )}
                                        </UncontrolledTooltip>
                                        <h6 className="m-0 fw-bolder">
                                            {parseMinutesToHours(
                                                weeklyWorkedData?.hours?.current
                                            )}
                                        </h6>
                                    </span>
                                    <small className="d-flex my-50">
                                        <JpPercentageText
                                            percentage={parseFloat(
                                                weeklyWorkedData?.hours?.percent
                                            )}
                                            periodText={`${t(
                                                'views.time.graphs.lastPeriod'
                                            )}`}
                                        />
                                    </small>
                                </div>
                            </div>
                        }
                        data={weeklyWorkedData?.week
                            ?.map(item => parseFloat(item.work_time.value))
                            .reverse()}
                        labels={Array.from({ length: 7 }, (_, i) =>
                            moment(id ? endDate : date)
                                .subtract(i, 'days')
                                .format('dd')
                        ).reverse()}
                        tooltipFormatter={value => {
                            return parseMinutesToHours(value)
                        }}
                        isToday={
                            id
                                ? moment().format('YYYY-MM-DD') === endDate
                                : moment().format('YYYY-MM-DD') === date
                        }
                        color="info"
                        seriesLabel={
                            id
                                ? moment().format('YYYY-MM-DD') === endDate
                                    ? t('today')
                                    : moment(endDate).format('dd')
                                : moment().format('YYYY-MM-DD') === date
                                ? t('today')
                                : moment(date).format('dd')
                        }
                    />
                </Col> */}
                <Col lg="4" sm="12" xs="12" className="mt-1 mt-lg-0">
                    <JpBarsCardV1
                        title={`${t('Time')} ${t(
                            'views.time.graphs.workedTime'
                        )}`}
                        cardClassName={'mb-0'}
                        percentage={weeklyWorkedData?.hours?.percent}
                        statPosition="right"
                        stat={`${parseMinutesToHours(
                            weeklyWorkedData?.hours?.current
                        )}`}
                        statClassName={'mb-0 ms-75'}
                        lastPeriodText={t('views.time.graphs.lastPeriod')}
                        barsData={[
                            {
                                data: weeklyWorkedData?.week
                                    ?.map(item => {
                                        return Number(item.work_time.value)
                                    })
                                    ?.reverse()
                            }
                        ]}
                        colors={['#0198FE']}
                        yTooltipFormatter={val => `${parseMinutesToHours(val)}`}
                        seriesLabels={Array.from({ length: 7 }, (_, i) =>
                            moment(id ? endDate : date)
                                .subtract(i, 'days')
                                .format('dd')
                        ).reverse()}
                        loading={weeklyWorkedLoading}
                        stacked
                        chartHeight={150}
                        showXToolTip={false}
                        cardBodyClassName="pb-0"
                    />
                </Col>
            </Row>
        </>
    )
}

export default HoursGraphs
