// ** React Imports
import { useState, useEffect, Fragment } from 'react'
// ** Third Party Components
import { useForm, Controller } from 'react-hook-form'
import 'cleave.js/dist/addons/cleave-phone.us'
// ** Http-common
import { useMutation, useQuery } from '@tanstack/react-query'
// ** Reactstrap Imports
import {
    Row,
    Col,
    Form,
    Card,
    Input,
    CardBody,
    CardTitle,
    CardHeader,
    FormFeedback,
    UncontrolledTooltip,
    Button
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { getDataSet, reduce } from 'iso3166-2-db'
import i18n from 'i18next'
import moment from 'moment'
import parsePhoneNumberFromString, { getPhoneCode } from 'libphonenumber-js'
import AdminAPI from '@src/services/API'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import {
    JpButton,
    JpLabel,
    JpText,
    JpCountryInput,
    dateLocalizer,
    SweetModal,
    JpAvatarGroup,
    JpDatePicker,
    JpCurrencyNumber,
    JpCountryFlag,
    JpStateInput,
    JpModal,
    JpAvatar,
    CONTRACT_MODALITY_VARIABLES
} from '@Intelli/utilities'
import { useNavigate, useParams } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import JpCurrencyInputController from '@src/components/input/JpCurrencyInputController'
import JpSwitchController from '@src/components/input/JpSwitchController'
import JpAsyncSelectController from '@src/components/input/JpAsyncSelectController'
import { faList } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { contracts } from '../Active'

const SHOWN_PAYMENTS = 2

const AccountTabs = ({
    data,
    shouldRefetch,
    setShouldRefetch,
    dataProfileRefetch,
    hasWritePermission
}) => {
    const listOfCountries = reduce(getDataSet(), i18n.language)
    const API = new AdminAPI()
    const { id } = useParams()
    const { t } = useTranslation()
    // ** States
    const [sortBy, setSortBy] = useState('payment_date__desc')
    const [start_date, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [isDisabled, setIsDisabled] = useState(true)
    const [timeTracking, setTimeTracking] = useState(data?.time?.active)
    const [hasPayroll, setHasPayroll] = useState(
        data?.contractor_metadata?.HAS_PAYROLL
            ? data?.contractor_metadata?.HAS_PAYROLL
            : false
    )
    const [hasProjects, setHasProjects] = useState(
        data?.contractor_metadata?.HAS_PROJECT
        ? data?.contractor_metadata?.HAS_PROJECT
        :  false
    )
    const [productivity, setProductivity] = useState(data?.productivity?.active)
    const [picker, setPicker] = useState(
        data?.metadata?.birthday
            ? moment(data?.metadata?.birthday, 'YYYY-MM-DD')
            : null
    )
    const [isOpen, setIsOpen] = useState(false)
    const [modalInfo, setModalInfo] = useState([])
    const [modalTitle, setModalTitle] = useState('')

    const navigate = useNavigate()
    const countryCode = data?.phone
        ? parsePhoneNumberFromString(data.phone).country
        : 'US'
    // ** Hooks
    useEffect(() => {
        return () => setIsDisabled(false)
    }, [])

    useEffect(() => {
        if (timeTracking === false) {
            setProductivity(false)
        }
    }, [timeTracking])

    const { data: dataPayroll, isSuccess: dataPayrollIsSuccess } = useQuery(
        ['payroll', id, sortBy],
        () => API.getContractorPayroll(id, { order: sortBy })
    )

    const SignupSchema = yup.object().shape({
        //country: yup.string().required('COUNTRY.required'),
        region: yup.object().required('STATE_REGION.required'),
        city: yup.string().required('CITY.required').max(60, 'max60'),
        first_line: yup.string().required('LINE1.required').max(60, 'max60'),
        second_line: yup.string().max(60, 'max60'),
        zip_code: yup
            .string()
            .min(1, 'ZIP_CODE.min')
            .max(10, 'ZIP_CODE.max')
            .required('ZIP_CODE.required'),
        country: yup.object().required('COUNTRY.required'),
        salary: yup.lazy(() => {
            if (data.contractor_status !== 'PENDING') {
                return yup
                    .number()
                    .transform(value => (isNaN(value) ? undefined : value))
                    .positive('form.contractors.SALARY.min')
                    .test('maxDigitsAfterDecimal', t('lessDecimals'), value => {
                        if (value !== undefined) {
                            const valueString = value.toString()
                            const decimalPart = valueString.split('.')[1]
                            return !decimalPart || decimalPart.length <= 3
                        }
                        return true
                    })
                    .max(99999999, 'form.contractors.SALARY.max')
                    .required('form.contractors.SALARY.required')
            }
            return yup.mixed().notRequired()
        }),
        position: yup.lazy(() => {
            if (data.contractor_status !== 'PENDING') {
                return yup.string().required('POSITION.required')
            }
            return yup.mixed().notRequired()
        }),
        productivity_profile_id: yup.lazy(() => {
            if (productivity) {
                return yup.object().required('InputRequired').nullable()
            }
            return yup.mixed().notRequired()
        }),
        time_profile_id: yup.lazy(() => {
            if (timeTracking) {
                return yup.object().required('InputRequired').nullable()
            }
            return yup.mixed().notRequired()
        })
    })

    const defaultValues = {
        first_line: data?.address?.first_line,
        second_line: data?.address?.second_line,
        city: data?.address?.city,
        region: data?.address?.region
            ? {
                  value: data?.address?.region,
                  label: listOfCountries[data?.address?.country]?.regions?.find(
                      item => item.iso === data?.address?.region
                  )?.name
              }
            : 'AL',
        country: data?.address?.country
            ? {
                  value: data?.address?.country,
                  label: listOfCountries[data?.address?.country]?.name
              }
            : {
                  value: 'US',
                  label: listOfCountries['US']?.name
              },
        zip_code: data?.address?.zip_code,
        birthday: picker,
        contractType: data?.contract_type_name
            ? {
                  value: data?.contract_type_id,
                  name: data?.contract_type_name,
                  label: t(
                      `views.settings.templates.types.${data?.contract_type_name}`
                  )
              }
            : {
                  value: 'b5fcc74e-01b4-46c9-96ae-ab90ebc0122a',
                  label: t(`views.settings.templates.types.CLASSIC`)
              },
        salary: data?.contractor_metadata?.SALARY,
        stateContract: data.contractor_status
            ? t(`${data.contractor_status}`)
            : '-',
        position: data?.contractor_metadata?.POSITION,
        team: data?.teams?.map(item => ({
            value: item.id,
            label: item.name
        })),
        contractStartDate: data?.contractor_metadata?.START_DATE,
        time_active: data?.time?.active,
        productivity_active: data?.productivity?.active,
        productivity_profile_id: data?.productivity?.profile
            ? {
                  value: data?.productivity?.profile?.id,
                  label: data?.productivity?.profile?.name
              }
            : null,
        holidays:
            data.contractor_metadata.HOLIDAYS !== 'NOT_APPLY'
                ? {
                      value: data.contractor_metadata.HOLIDAYS,
                      label: listOfCountries[data.contractor_metadata.HOLIDAYS]
                          ?.name
                  }
                : {
                      value: 'NOT_APPLY',
                      label: t('notApplicable')
                  },
        time_profile_id: data.time.profile
            ? {
                  value: data.time.profile.id,
                  label: (
                      <p className="m-0">
                          {data.time.profile.name}{' '}
                          {data?.time.profile.hours + '/hrs'}
                      </p>
                  ),
                  time: data?.time?.profile?.hours
              }
            : null,
        has_payroll: data?.contractor_metadata?.HAS_PAYROLL
            ? data?.contractor_metadata?.HAS_PAYROLL
            : false
    }

    const {
        control,
        handleSubmit,
        setValue,
        reset,
        watch,
        formState: { errors }
    } = useForm({ defaultValues, resolver: yupResolver(SignupSchema) })

    const { mutate: updateUserDirection } = useMutation(
        data => API.updateContractorDirection(data),
        {
            onSuccess: () => {
                setShouldRefetch(!shouldRefetch)
                setIsDisabled(true)
                SweetModal(
                    'success',
                    t('Success'),
                    t('modals.editContractor'),
                    t('Ok')
                )
            }
        }
    )

    const isTasksContract = watch('contractType')?.name === 'TASKS'
    const isTimeContract = watch('contractType')?.name === 'TIME'

    const { data: getHolidays } = useQuery(
        ['getHolidays', isTasksContract],
        () => API.getHolidays(id),
        {
            onSuccess: ({ result }) => {
                if (isTasksContract) {
                    setValue('holidays', {
                        value: 'NOT_APPLY',
                        label: t('notApplicable')
                    })
                }
            }
        }
    )

    const { data: getTimeProfile } = useQuery(['getTimeProfile'], () =>
        API.getTimeProfile()
    )

    const getTimeProfileSelect = async value => {
        return await API.getProductivityProfile({
            query: value
        }).then(res =>
            res.result.map(i => ({
                value: i.id,
                label: i.name,
                time: i.hours
            }))
        )
    }

    const productivityProfile = async value => {
        return await API.getProductivityProfile({
            query: value
        }).then(res =>
            res.data.map(i => ({
                value: i.id,
                label: i.name
            }))
        )
    }

    const { mutate: renewContract } = useMutation(
        id => API.renewContractData(id),
        {
            onSuccess: data => {
                navigate(
                    `/admin/contractors/renew-contract/${data.contractor_id}`
                )
            }
        }
    )

    const { mutate: generateNewContract } = useMutation(
        id => API.generateNewContract(id),
        {
            onSuccess: data => {
                navigate(
                    `/admin/contractors/new-contract/${data.contractor_id}`
                )
            }
        }
    )

    const { mutate: updateAccess } = useMutation(
        data => API.changeContractorStatus(data),
        {
            onSuccess: () => {
                setShouldRefetch(!shouldRefetch)
                SweetModal(
                    'success',
                    t('Success'),
                    data?.contractor_status === 'ACTIVED'
                        ? t('modals.userDisabled')
                        : t('modals.userEnabled'),
                    t('Ok')
                )
            }
        }
    )

    const handleStatus = id => {
        if (data?.contractor_status === 'ACTIVED') {
            SweetModal(
                'warning',
                t('views.activeContractors.table.modal.inactiveTitle'),
                t('views.activeContractors.table.modal.inactiveText'),
                t('Ok'),
                ({ isConfirmed }) => {
                    if (isConfirmed) {
                        updateAccess({ id, data: { status: 'DISABLED' } })
                    }
                },
                { showCancelButton: true, cancelButtonText: t('CANCEL') }
            )
        } else {
            SweetModal(
                'warning',
                t('views.inactiveContractors.modals.activateContractor'),
                t(
                    'views.inactiveContractors.modals.activateContractorConfirmation'
                ),
                t('Ok'),
                ({ isConfirmed }) => {
                    if (isConfirmed) {
                        updateAccess({ id, data: { status: 'ACTIVED' } })
                    }
                },
                { showCancelButton: true, cancelButtonText: t('CANCEL') }
            )
        }
    }

    //Productivity Profile Select
    const {
        data: getProductivityProfile,
        isLoading: getProductivityProfileIsLoading
    } = useQuery(['getServicesType'], () => API.getProductivityProfile())

    const onClose = () => {
        setHasPayroll(
            data?.contractor_metadata?.HAS_PAYROLL
                ? data?.contractor_metadata?.HAS_PAYROLL
                : false
        )
        setHasProjects(
            data?.contractor_metadata?.HAS_PROJECT
                ? data?.contractor_metadata?.HAS_PROJECT
                : false
        )
        setIsDisabled(true)
        setTimeTracking(data?.time?.active)
        setProductivity(data?.productivity?.active)
        reset()
        setValue('region', {
            value: data?.address?.region,
            label: listOfCountries[data?.address?.country]?.regions?.find(
                region => region.iso === data?.address?.region
            )?.name
        })
    }

    //** Handles
    const onSubmit = dataForm => {
        SweetModal(
            'warning',
            t('updateProfileTitle'),
            t('updateProfileMsg'),
            t('Ok'),
            result => {
                /*  if (result.isConfirmed) {
                    if (data.is_contract_process) {
                        updateUserDirection({
                            data: {
                                address: {
                                    first_line: dataForm.first_line,
                                    second_line: dataForm.second_line,
                                    city: dataForm.city,
                                    country: dataForm.country.value,
                                    zip_code: dataForm.zip_code,
                                    region: dataForm.region.value
                                },
                                teams_id: dataForm.team.map(team => team.value),
                                productivity: {
                                    active: dataForm?.productivity_active,
                                    profile_id: dataForm?.productivity_active
                                        ? dataForm?.productivity_profile_id
                                              ?.value
                                        : null
                                },
                                time: {
                                    active: dataForm?.time_active,
                                    profile_id: dataForm?.time_active
                                        ? dataForm.time_profile_id.value
                                        : null
                                },
                                has_payroll: dataForm?.has_payroll,
                                has_projects: dataForm?.has_projects
                            },
                            id
                        })
                       
                    } else {
                        updateUserDirection({
                            data: {
                                address: {
                                    first_line: dataForm.first_line,
                                    second_line: dataForm.second_line,
                                    city: dataForm.city,
                                    country: dataForm.country.value,
                                    zip_code: dataForm.zip_code,
                                    region: dataForm.region.value
                                },
                                contractor_profile: {
                                    status: dataForm.status,
                                    salary: Number(dataForm.salary),
                                    start_date: dataForm.contractStartDate,
                                    end_date: dataForm.contractEndDate,
                                    position: dataForm.position,
                                    holidays: dataForm.holidays.value,
                                    teams_id: dataForm.team.map(
                                        team => team.value
                                    ),
                                    contract_type_id:
                                        dataForm.contractType.value
                                },
                                time: {
                                    active: dataForm?.time_active,
                                    profile_id: dataForm?.time_active
                                        ? dataForm.time_profile_id.value
                                        : null
                                },
                                productivity: {
                                    active: dataForm?.productivity_active,
                                    profile_id: dataForm?.productivity_active
                                        ? dataForm?.productivity_profile_id
                                              ?.value
                                        : null
                                },
                                has_payroll: dataForm?.has_payroll,
                                has_projects: dataForm?.has_projects
                            },
                            id
                        })

                        
                    }
                } */
                if (result.isConfirmed) {
                    const commonData = {
                        address: {
                            first_line: dataForm.first_line,
                            second_line: dataForm.second_line,
                            city: dataForm.city,
                            country: dataForm.country.value,
                            zip_code: dataForm.zip_code,
                            region: dataForm.region.value
                        },
                        time: {
                            active: dataForm?.time_active,
                            profile_id: dataForm?.time_active
                                ? dataForm.time_profile_id.value
                                : null
                        },
                        productivity: {
                            active: dataForm?.productivity_active,
                            profile_id: dataForm?.productivity_active
                                ? dataForm?.productivity_profile_id?.value
                                : null
                        },
                        has_payroll: dataForm?.has_payroll,
                        has_project: dataForm?.has_projects
                    }

                    if (data.is_contract_process) {
                        updateUserDirection({
                            data: {
                                ...commonData,
                                teams_id: dataForm.team.map(team => team.value)
                            },
                            id
                        })
                    } else {
                        updateUserDirection({
                            data: {
                                ...commonData,
                                contractor_profile: {
                                    status: dataForm.status,
                                    salary: Number(dataForm.salary),
                                    start_date: dataForm.contractStartDate,
                                    end_date: dataForm.contractEndDate,
                                    position: dataForm.position,
                                    holidays: dataForm.holidays.value,
                                    teams_id: dataForm.team.map(
                                        team => team.value
                                    ),
                                    contract_type_id:
                                        dataForm.contractType.value
                                }
                            },
                            id
                        })
                    }
                } else {
                    setIsDisabled(true)
                    reset()
                    setValue('region', {
                        value: data?.address?.region,
                        label: listOfCountries[
                            data?.address?.country
                        ]?.regions?.find(
                            region => region.iso === data?.address?.region
                        )?.name
                    })
                    onClose()
                }
            },
            { showCancelButton: true, cancelButtonText: t('CANCEL') }
        )
    }

    const setDate = (id, date) => {
        setValue(id, date.format('YYYY-MM-DD'))
        if (id === 'contractStartDate') {
            setStartDate(date.format('YYYY-MM-DD'))
            setValue('contractEndDate', date.format('YYYY-MM-DD'))
        }
    }

    const dataAG = [
        {
            text: data?.modality
                ? t(`views.newContract.steps.employer.${data?.modality}.title`)
                : t(`views.newContract.steps.employer.NoRecruitmentFlow`),
            icon: data?.modality ? (
                CONTRACT_MODALITY_VARIABLES[data?.modality]?.icon
            ) : (
                <FontAwesomeIcon icon="fa-solid fa-image-portrait" />
            ),
            color: data?.modality
                ? CONTRACT_MODALITY_VARIABLES[data?.modality]?.color
                : 'info'
        },
        {
            text: t(
                `views.settings.templates.types.${data?.contract_type_name}`
            ),
            icon: contracts[data?.contract_type_name]?.icon,
            color: contracts[data?.contract_type_name]?.color
        }
    ]

    const [timeHours, setTimes] = useState(watch('time_profile_id')?.time)
    useEffect(() => {
        setTimes(watch('time_profile_id')?.time)
    }, [watch('time_profile_id')])

    const BodyModalContent = () => {
        return modalInfo?.map(i => {
            if (i.order) {
                return (
                    <>
                        <JpLabel
                            className=""
                            labelOptions={{ for: `${i.order}` }}
                        >{`${i.title}:`}</JpLabel>
                        <div className="d-flex align-items-center mb-1 text-break">
                            <JpAvatar
                                className="rounded"
                                icon={
                                    <i class="fa-solid fa-clipboard-list"></i>
                                }
                                color={'light-primary'}
                            />
                            <JpText
                                id={`${i.order}`}
                                className="p-75"
                                type="p-text"
                                children={i.description}
                            />
                        </div>
                    </>
                )
            } else {
                return (
                    <div className="d-flex align-items-center text-break">
                        <JpText className="p-75" type="p-text" children={i} />
                    </div>
                )
            }
        })
    }

    return (
        <Row className="flex-column flex-lg-row">
            <Col tag={Form} onSubmit={handleSubmit(onSubmit)}>
                <Card className="mb-1">
                    <CardHeader className="border-bottom p-1">
                        <CardTitle tag="h4">
                            <JpText
                                className="p-75"
                                type="card-title"
                                children={t('Profile Details')}
                            />
                        </CardTitle>
                        <div className="d-flex justify-content-end align-items-center">
                            {hasWritePermission && (
                                <JpButton
                                    color={!isDisabled ? 'secondary' : 'info'}
                                    text={t('EDIT')}
                                    type="edit"
                                    options={{
                                        iconPosition: 'left',
                                        onClick: () => setIsDisabled(false)
                                    }}
                                />
                            )}
                        </div>
                    </CardHeader>
                    <CardBody className="pb-0">
                        <Row className="pt-1 pb-0">
                            <Col sm="6" lg="3" md="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'first_name' }}
                                >{`${t('FIRST_NAME')}`}</JpLabel>
                                {data.user.first_name ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={data.user.first_name}
                                    />
                                ) : (
                                    <p className="p-75">-</p>
                                )}
                            </Col>

                            <Col sm="6" lg="3" md="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'last_name' }}
                                >{`${t('LAST_NAME')}`}</JpLabel>
                                {data.user.last_name ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={data.user.last_name}
                                    />
                                ) : (
                                    <p className="p-75">-</p>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'birthday' }}
                                >{`${t('BIRTHDAY')}`}</JpLabel>
                                <JpText
                                    className="p-75"
                                    type="p-text"
                                    children={
                                        data?.user_metadata?.birthday
                                            ? dateLocalizer.getIntlLongDate(
                                                  data?.user_metadata?.birthday
                                              )
                                            : '-'
                                    }
                                />
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'email' }}
                                >{`${t('EMAIL')}`}</JpLabel>
                                <JpText
                                    className="p-75"
                                    type="p-text"
                                    children={data.email}
                                />
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'phone' }}
                                >{`${t('PHONE_NUMBER')}`}</JpLabel>
                                {data?.phone ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            data?.phone
                                                ? `+ ${getPhoneCode(
                                                      countryCode
                                                  )} ${
                                                      parsePhoneNumberFromString(
                                                          data.phone
                                                      ).nationalNumber
                                                  }`
                                                : ''
                                        }
                                    />
                                ) : (
                                    <p className="p-75">-</p>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'address' }}
                                >{`${t('ADDRESS_LINE_1')}`}</JpLabel>
                                {isDisabled ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            data?.address?.first_line || '-'
                                        }
                                    />
                                ) : (
                                    <>
                                        <Controller
                                            name="first_line"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    id="first_line"
                                                    name="first_line"
                                                    placeholder="12, Business Park"
                                                    invalid={
                                                        errors.first_line &&
                                                        true
                                                    }
                                                    {...field}
                                                    disabled={isDisabled}
                                                    className={
                                                        isDisabled
                                                            ? 'disabled-joobpay ps-0'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        />
                                        {errors.first_line && (
                                            <FormFeedback>
                                                {t(
                                                    `form.clients.${errors.first_line.message}`,
                                                    {
                                                        ns: 'errors'
                                                    }
                                                )}
                                            </FormFeedback>
                                        )}
                                    </>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'address2' }}
                                >{`${t('ADDRESS_LINE_2')}`}</JpLabel>
                                {isDisabled ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            data?.address?.second_line || '-'
                                        }
                                    />
                                ) : (
                                    <>
                                        <Controller
                                            name="second_line"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    id="second_line"
                                                    name="second_line"
                                                    invalid={
                                                        errors.second_line &&
                                                        true
                                                    }
                                                    {...field}
                                                    disabled={isDisabled}
                                                    className={
                                                        isDisabled
                                                            ? 'disabled-joobpay ps-0'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        />
                                        {errors.second_line && (
                                            <FormFeedback>
                                                {t(
                                                    `form.clients.${errors.second_line.message}`,
                                                    {
                                                        ns: 'errors'
                                                    }
                                                )}
                                            </FormFeedback>
                                        )}
                                    </>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'zipCode' }}
                                >{`${t('ZIP_CODE')}`}</JpLabel>
                                {isDisabled ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            data?.address?.zip_code || '-'
                                        }
                                    />
                                ) : (
                                    <>
                                        <Controller
                                            name="zip_code"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    name="zip_code"
                                                    type="number"
                                                    id="zip_code"
                                                    placeholder="123456"
                                                    invalid={
                                                        errors.zip_code && true
                                                    }
                                                    {...field}
                                                    disabled={isDisabled}
                                                    className={
                                                        isDisabled
                                                            ? 'disabled-joobpay ps-0'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        />
                                        {errors.zip_code && (
                                            <FormFeedback>
                                                {t(
                                                    `form.clients.${errors.zip_code.message}`,
                                                    {
                                                        ns: 'errors'
                                                    }
                                                )}
                                            </FormFeedback>
                                        )}
                                    </>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'country' }}
                                >{`${t('COUNTRY')}`}</JpLabel>
                                <br />
                                {isDisabled ? (
                                    data?.address?.country ? (
                                        <div className="d-flex align-items-center gap-1 p-75">
                                            <JpCountryFlag
                                                countryCode={
                                                    listOfCountries[
                                                        data?.address?.country
                                                    ]?.iso
                                                }
                                                id={data?.user?.id}
                                            />
                                            <JpText
                                                type="p-text"
                                                children={
                                                    listOfCountries[
                                                        data?.address?.country
                                                    ].name || '-'
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <p className="p-75">-</p>
                                    )
                                ) : (
                                    <Controller
                                        name="country"
                                        control={control}
                                        render={({ field }) => (
                                            <JpCountryInput field={field} />
                                        )}
                                    />
                                )}
                                {errors.country && (
                                    <FormFeedback>
                                        {t(`${errors.country.message}`)}
                                    </FormFeedback>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'region' }}
                                >{`${t('STATE_REGION')}`}</JpLabel>
                                {isDisabled ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            listOfCountries[
                                                data?.address?.country
                                            ]?.regions?.find(
                                                ele =>
                                                    ele.iso ===
                                                    data?.address?.region
                                            )?.name ||
                                            data?.address?.region ||
                                            '-'
                                        }
                                    />
                                ) : (
                                    <Controller
                                        name="region"
                                        control={control}
                                        render={({ field }) => (
                                            <JpStateInput
                                                country={watch('country').value}
                                                watch={watch}
                                                disabled={isDisabled}
                                                setValue={setValue}
                                                field={field}
                                            />
                                        )}
                                    />
                                )}
                                {errors.region && (
                                    <FormFeedback>
                                        {t('errors.InputRequired')}
                                    </FormFeedback>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'city' }}
                                >{`${t('CITY')}`}</JpLabel>
                                {isDisabled ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={data?.address?.city || '-'}
                                    />
                                ) : (
                                    <>
                                        <Controller
                                            name="city"
                                            control={control}
                                            render={({ field }) => (
                                                <Input
                                                    id="city"
                                                    name="city"
                                                    placeholder="Los Angeles"
                                                    invalid={
                                                        errors.city && true
                                                    }
                                                    {...field}
                                                    disabled={isDisabled}
                                                    className={
                                                        isDisabled
                                                            ? 'disabled disabled-joobpay ps-0'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        />
                                        {errors.city && (
                                            <FormFeedback>
                                                {t(
                                                    `form.clients.${errors.city.message}`,
                                                    {
                                                        ns: 'errors'
                                                    }
                                                )}
                                            </FormFeedback>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card className="mb-1">
                    <CardHeader className="p-1">
                        <CardTitle tag="h4">
                            <JpText
                                className="p-75"
                                type="card-title"
                                children={t('CONTRACT_INFO')}
                            />
                        </CardTitle>
                        <div className="d-flex justify-content-end align-items-center gap-1">
                            <JpButton
                                id="history"
                                color={'info'}
                                type="edit"
                                tooltip
                                tooltipTextCustom={t('contractHistory')}
                                options={{
                                    iconPosition: 'left',
                                    textLess: true,
                                    customIcon: (
                                        <i class="fa-solid fa-file-contract mx-25"></i>
                                    ),
                                    onClick: () =>
                                        navigate(
                                            `/admin/contractors/contractHistory/${id}`
                                        )
                                }}
                            />

                            {hasWritePermission && (
                                <>
                                    {data?.contractor_status !== 'PENDING' && (
                                        <JpButton
                                            id="changeStatus"
                                            color={'info'}
                                            type="edit"
                                            tooltip
                                            tooltipTextCustom={
                                                data?.contractor_status ===
                                                'ACTIVED'
                                                    ? t(
                                                          'views.activeContractors.icons.disable'
                                                      )
                                                    : t(
                                                          'views.inactiveContractors.icons.reactivate'
                                                      )
                                            }
                                            options={{
                                                iconPosition: 'left',
                                                textLess: true,
                                                customIcon: (
                                                    <i
                                                        class={`fa-solid fa-user${
                                                            data?.contractor_status ===
                                                            'ACTIVED'
                                                                ? '-slash'
                                                                : ''
                                                        }`}
                                                    ></i>
                                                ),
                                                onClick: () => handleStatus(id)
                                            }}
                                        />
                                    )}

                                    {data.contract_type_name === 'CLASSIC' &&
                                        data?.contractor_status ===
                                            'DISABLED' && (
                                            <JpButton
                                                id="renewContract"
                                                color={'info'}
                                                type="edit"
                                                tooltip
                                                tooltipTextCustom={t(
                                                    'views.inactiveContractors.icons.renew'
                                                )}
                                                options={{
                                                    iconPosition: 'left',
                                                    textLess: true,
                                                    customIcon: (
                                                        <i class="fa-kit fa-solid-file-rotate-left"></i>
                                                    ),
                                                    onClick: () =>
                                                        renewContract(id)
                                                }}
                                            />
                                        )}
                                    <JpButton
                                        id="newContract"
                                        color={'info'}
                                        type="edit"
                                        tooltip
                                        tooltipTextCustom={
                                            data?.is_contract_process
                                                ? t(
                                                      'views.inactiveContractors.icons.new'
                                                  )
                                                : t(
                                                      'views.inactiveContractors.icons.newContractJoobPay'
                                                  )
                                        }
                                        options={{
                                            iconPosition: 'left',
                                            textLess: true,
                                            customIcon: (
                                                <i class="fa-solid fa-file-circle-plus"></i>
                                            ),
                                            onClick: () => {
                                                if (
                                                    data.contractor_status ===
                                                    'ACTIVED'
                                                ) {
                                                    SweetModal(
                                                        'warning',
                                                        t('newContractTitle'),
                                                        t('newContractMsg'),
                                                        t('Ok'),
                                                        result => {
                                                            if (
                                                                result.isConfirmed
                                                            ) {
                                                                generateNewContract(
                                                                    id
                                                                )
                                                            }
                                                        },
                                                        {
                                                            showCancelButton: true,
                                                            cancelButtonText:
                                                                t('CANCEL')
                                                        }
                                                    )
                                                } else {
                                                    generateNewContract(id)
                                                }
                                            }
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </CardHeader>
                    <CardBody className="border-top pt-1 pb-0">
                        <Row>
                            <Col sm="6" lg="3" md="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{
                                        for: 'contractType'
                                    }}
                                >{`${t('CONTRACT_TYPE')}`}</JpLabel>

                                {isDisabled ? (
                                    <>
                                        {data?.contract_type_name ? (
                                            <JpAvatarGroup
                                                className="p-50"
                                                data={dataAG?.map(
                                                    (contract, index) => {
                                                        return {
                                                            title: contract.text,
                                                            icon: contract.icon,
                                                            color: contract.color,
                                                            id: `contract-${index}`,
                                                            imgClassName:
                                                                'shadow-none'
                                                        }
                                                    }
                                                )}
                                            />
                                        ) : (
                                            <p className="p-75">-</p>
                                        )}
                                    </>
                                ) : !data.is_contract_process ? (
                                    <Controller
                                        name="contractType"
                                        control={control}
                                        render={({ field }) => (
                                            <AsyncSelect
                                                hideSelectedOptions
                                                cacheOptions
                                                defaultOptions
                                                invalid={
                                                    errors.teams_id && true
                                                }
                                                loadOptions={async value => {
                                                    const res =
                                                        await API.getContractTypes(
                                                            value
                                                        ).then(res => {
                                                            return res.data.map(
                                                                item => ({
                                                                    value: item.id,
                                                                    name: item.name,
                                                                    label: t(
                                                                        `views.settings.templates.types.${item.name}`
                                                                    )
                                                                })
                                                            )
                                                        })
                                                    return res
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                ) : (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            data?.contract_type_name
                                                ? t(
                                                      `views.settings.templates.types.${data?.contract_type_name}`
                                                  )
                                                : '-'
                                        }
                                    />
                                )}
                                {errors.contractType && (
                                    <FormFeedback>
                                        {t('Please enter a valid contractType')}
                                    </FormFeedback>
                                )}
                            </Col>

                            <Col sm="6" lg="3" md="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'salary' }}
                                >{`${t('SALARY')}`}</JpLabel>

                                {isDisabled ? (
                                    data?.contractor_metadata?.SALARY ? (
                                        <div className="p-75">
                                            <JpCurrencyNumber
                                                value={
                                                    data?.contractor_metadata
                                                        ?.SALARY
                                                }
                                            />
                                            <span className="text-success">
                                                {data?.contract_type_name ===
                                                    'TIME' && ' /hrs'}
                                            </span>
                                        </div>
                                    ) : (
                                        <p className="p-75">-</p>
                                    )
                                ) : !data.is_contract_process ? (
                                    <JpCurrencyInputController
                                        name="salary"
                                        control={control}
                                        errors={errors}
                                    />
                                ) : data?.contractor_metadata?.SALARY ? (
                                    <div className="p-75">
                                        <JpCurrencyNumber
                                            value={
                                                data?.contractor_metadata
                                                    ?.SALARY || '-'
                                            }
                                        />
                                        <span className="text-success">
                                            {data?.contract_type_name ===
                                                'TIME' && ' /hrs'}
                                        </span>
                                    </div>
                                ) : (
                                    <p className="p-75">-</p>
                                )}
                                {errors.salary && (
                                    <FormFeedback>
                                        {t(
                                            `form.contractors.${errors?.salary?.message}`,
                                            {
                                                ns: 'errors'
                                            }
                                        )}
                                    </FormFeedback>
                                )}
                            </Col>

                            <Col sm="6" lg="3" md="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'position' }}
                                >{`${t('POSITION')}`}</JpLabel>

                                {isDisabled ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            data.contractor_metadata.POSITION ||
                                            '-'
                                        }
                                    />
                                ) : !data.is_contract_process ? (
                                    <Controller
                                        name="position"
                                        control={control}
                                        render={({ field }) => (
                                            <Input
                                                id="position"
                                                placeholder="Dev"
                                                name="position"
                                                maxLength="30"
                                                invalid={
                                                    errors.position && true
                                                }
                                                {...field}
                                                disabled={isDisabled}
                                                className={
                                                    isDisabled
                                                        ? 'disabled-joobpay ps-0'
                                                        : ''
                                                }
                                            />
                                        )}
                                    />
                                ) : (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            data?.contractor_metadata
                                                ?.POSITION || '-'
                                        }
                                    />
                                )}
                                {errors.position && (
                                    <FormFeedback>
                                        {t(
                                            `form.contractors.${errors?.position?.message}`,
                                            {
                                                ns: 'errors'
                                            }
                                        )}
                                    </FormFeedback>
                                )}
                            </Col>

                            <Col sm="6" lg="3" md="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{
                                        for: 'stateContract'
                                    }}
                                >{`${t('STATE_CONTRACT')}`}</JpLabel>

                                <JpText
                                    type="p-text"
                                    className="p-75"
                                    children={t(
                                        `${
                                            data?.contractor_status
                                                ? data?.contractor_status
                                                : '-'
                                        }`
                                    )}
                                />
                            </Col>

                            <Col sm="6" lg="3" md="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'team' }}
                                >{`${t('TEAM')}`}</JpLabel>

                                {isDisabled ? (
                                    data?.teams.length > 0 ? (
                                        <JpAvatarGroup
                                            className="p-50"
                                            data={data?.teams
                                                ?.slice(0, 4)
                                                .map((team, index) => {
                                                    const colors = [
                                                        'light-warning',
                                                        'light-success',
                                                        'light-info',
                                                        'light-primary'
                                                    ]

                                                    if (index === 3) {
                                                        return {
                                                            content: `+${
                                                                data.teams
                                                                    .length - 3
                                                            }`,
                                                            id: `team-${self.crypto.randomUUID()}`,
                                                            color: colors[
                                                                index
                                                            ],
                                                            imgClassName:
                                                                'shadow-none'
                                                        }
                                                    }

                                                    return {
                                                        title: team.name,
                                                        content: team.name,
                                                        initials: true,
                                                        color: colors[index],
                                                        id: `team-${self.crypto.randomUUID()}`,
                                                        imgClassName:
                                                            'shadow-none'
                                                    }
                                                })}
                                        />
                                    ) : (
                                        <p className="p-75">-</p>
                                    )
                                ) : (
                                    <Controller
                                        name="team"
                                        control={control}
                                        render={({ field }) => (
                                            <AsyncSelect
                                                isMulti
                                                hideSelectedOptions
                                                cacheOptions
                                                defaultOptions
                                                isClearable
                                                placeholder={t(
                                                    'JpAsyncSelectController.placeHolder',
                                                    {
                                                        ns: 'component'
                                                    }
                                                )}
                                                noOptionsMessage={() =>
                                                    t(
                                                        'JpAsyncSelectController.noOptionsMessage',
                                                        {
                                                            ns: 'component'
                                                        }
                                                    )
                                                }
                                                loadingMessage={() =>
                                                    t(
                                                        'JpAsyncSelectController.loadingMessage',
                                                        {
                                                            ns: 'component'
                                                        }
                                                    )
                                                }
                                                invalid={
                                                    errors.teams_id && true
                                                }
                                                loadOptions={async value => {
                                                    const res =
                                                        await API.getAvailableTeams(
                                                            value
                                                        ).then(res => {
                                                            return res.data.map(
                                                                item => ({
                                                                    value: item.id,
                                                                    label: item.name
                                                                })
                                                            )
                                                        })
                                                    return res
                                                }}
                                                {...field}
                                            />
                                        )}
                                    />
                                )}
                                {errors.team && (
                                    <FormFeedback>
                                        {t('Please enter a valid Team')}
                                    </FormFeedback>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{
                                        for: 'contractStartDate'
                                    }}
                                >{`${t('CONTRACT_START_DATE')}`}</JpLabel>

                                {isDisabled ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            dateLocalizer.getIntlLongDate(
                                                data.contractor_metadata
                                                    .START_DATE
                                            ) || '-'
                                        }
                                    />
                                ) : !data.is_contract_process ? (
                                    <Controller
                                        control={control}
                                        name="contractStartDate"
                                        render={({ field }) => (
                                            <JpDatePicker
                                                id="contractStartDate"
                                                minDate={
                                                    data?.contractor_metadata
                                                        ?.START_DATE
                                                }
                                                setPicker={date =>
                                                    setDate(
                                                        'contractStartDate',
                                                        date
                                                    )
                                                }
                                                disabled={isDisabled}
                                                className={`${
                                                    isDisabled
                                                        ? 'disabled-joobpay'
                                                        : 'enabled-joobpay'
                                                } ps-0 ${
                                                    errors.contractStartDate
                                                        ? 'is-invalid'
                                                        : 'mb-1'
                                                }`}
                                                {...field}
                                            />
                                        )}
                                    />
                                ) : (
                                    <JpText
                                        type="p-text"
                                        className="p-75"
                                        children={
                                            data?.contractor_metadata
                                                ?.START_DATE
                                                ? dateLocalizer.getIntlLongDate(
                                                      data?.contractor_metadata
                                                          ?.START_DATE
                                                  )
                                                : '-'
                                        }
                                    />
                                )}
                                {errors.contractStartDate && (
                                    <FormFeedback>
                                        {t(
                                            'Please enter a valid Contract Start Date'
                                        )}
                                    </FormFeedback>
                                )}
                            </Col>

                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{
                                        for: 'contractEndDate'
                                    }}
                                >{`${t('CONTRACT_END_DATE')}`}</JpLabel>

                                {isDisabled ? (
                                    <JpText
                                        className="p-75"
                                        type="p-text"
                                        children={
                                            data.contractor_metadata.END_DATE
                                                ? dateLocalizer.getIntlLongDate(
                                                      data.contractor_metadata
                                                          .END_DATE
                                                  )
                                                : '-'
                                        }
                                    />
                                ) : !data.is_contract_process ? (
                                    <Controller
                                        control={control}
                                        name="contractEndDate"
                                        render={({ field }) => (
                                            <JpDatePicker
                                                id="contractEndDate"
                                                disabledDates={[
                                                    {
                                                        to: watch(
                                                            'contractStartDate'
                                                        ),
                                                        from: moment().format(
                                                            'YYYY-MM-DD'
                                                        )
                                                    }
                                                ]}
                                                minDate={start_date}
                                                setPicker={date =>
                                                    setDate(
                                                        'contractEndDate',
                                                        date
                                                    )
                                                }
                                                disabled={isDisabled}
                                                className={`${
                                                    isDisabled
                                                        ? 'disabled-joobpay'
                                                        : 'enabled-joobpay'
                                                } ps-0 ${
                                                    errors.start_date
                                                        ? 'is-invalid'
                                                        : 'mb-1'
                                                }`}
                                                {...field}
                                            />
                                        )}
                                    />
                                ) : (
                                    <JpText
                                        type="p-text"
                                        className="p-75"
                                        children={
                                            data?.contractor_metadata?.END_DATE
                                                ? dateLocalizer.getIntlLongDate(
                                                      data?.contractor_metadata
                                                          ?.END_DATE
                                                  )
                                                : '-'
                                        }
                                    />
                                )}
                                {errors.contractEndDate && (
                                    <FormFeedback>
                                        {t(
                                            'Please enter a valid Contract End Date'
                                        )}
                                    </FormFeedback>
                                )}
                            </Col>
                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{
                                        for: 'payment_rule'
                                    }}
                                >{`${t('paymentRule')}`}</JpLabel>
                                <JpText
                                    type="p-text"
                                    className="p-75"
                                    id="payment_rule"
                                    children={
                                        data?.payment_rule?.name
                                            ? t(
                                                  `views.payPeriods.${data?.payment_rule?.name}`
                                              )
                                            : '-'
                                    }
                                />
                            </Col>
                            <Col sm="6" lg="3" className="mb-1">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{ for: 'holidays' }}
                                >{`${t(
                                    'views.settings.calendar.holidays'
                                )}`}</JpLabel>
                                <br />
                                {isDisabled ? (
                                    data?.contractor_metadata?.HOLIDAYS ? (
                                        data?.contractor_metadata?.HOLIDAYS !==
                                        'NOT_APPLY' ? (
                                            <div className="d-flex align-items-center gap-1 p-75">
                                                <JpCountryFlag
                                                    countryCode={
                                                        listOfCountries[
                                                            data
                                                                .contractor_metadata
                                                                .HOLIDAYS
                                                        ]?.iso
                                                    }
                                                    id={data?.user?.id}
                                                />
                                                <JpText
                                                    type="p-text"
                                                    children={
                                                        listOfCountries[
                                                            data
                                                                .contractor_metadata
                                                                .HOLIDAYS
                                                        ].name || '-'
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <p className="p-75">
                                                {t('notApplicable')}
                                            </p>
                                        )
                                    ) : (
                                        <p className="p-75">-</p>
                                    )
                                ) : !data.is_contract_process ? (
                                    <Controller
                                        name="holidays"
                                        control={control}
                                        invalid={errors.holidays && true}
                                        render={({ field }) => (
                                            <JpCountryInput
                                                /* disabled={isTasksContract} */
                                                field={field}
                                                enableCountrys={getHolidays?.result.map(
                                                    item => item.value
                                                )}
                                                customOptions={[
                                                    {
                                                        value: getHolidays?.result.find(
                                                            item =>
                                                                item.value ===
                                                                'NOT_APPLY'
                                                        ).value,
                                                        label: t(
                                                            'notApplicable'
                                                        )
                                                    }
                                                ]}
                                            />
                                        )}
                                    />
                                ) : data?.contractor_metadata?.HOLIDAYS !==
                                  'NOT_APPLY' ? (
                                    <div className="d-flex align-items-center gap-1 p-75">
                                        {data.contractor_metadata.HOLIDAYS && (
                                            <JpCountryFlag
                                                countryCode={
                                                    listOfCountries[
                                                        data.contractor_metadata
                                                            .HOLIDAYS
                                                    ]?.iso
                                                }
                                                id={data?.user?.id}
                                            />
                                        )}
                                        <JpText
                                            type="p-text"
                                            children={
                                                listOfCountries[
                                                    data?.contractor_metadata
                                                        .HOLIDAYS
                                                ]?.name || '-'
                                            }
                                        />
                                    </div>
                                ) : (
                                    <p className="p-75">{t('notApplicable')}</p>
                                )}
                                {errors.holidays && (
                                    <small className="text-danger">
                                        {t(`${errors.holidays.message}`)}
                                    </small>
                                )}
                            </Col>
                            <Col
                                sm="6"
                                lg="3"
                                className="mb-1 d-flex flex-column align-items-start"
                            >
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{
                                        for: 'clauses'
                                    }}
                                >{`${t(
                                    'views.newContract.steps.contract.clauses'
                                )}`}</JpLabel>
                                {data?.clauses?.length > 0 ? (
                                    <Button
                                        className={`table-action p-75`}
                                        onClick={() => {
                                            setIsOpen(true),
                                                setModalTitle(
                                                    t(
                                                        'views.newContract.steps.contract.clauses'
                                                    )
                                                )
                                            setModalInfo(data?.clauses)
                                        }}
                                        color="link"
                                    >
                                        <FontAwesomeIcon
                                            icon={faList}
                                            id={'clausesIcon'}
                                            size="lg"
                                        />
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={'clausesIcon'}
                                        >
                                            {t('see')}
                                        </UncontrolledTooltip>
                                    </Button>
                                ) : (
                                    <p className="p-75">-</p>
                                )}
                            </Col>
                            <Col
                                sm="6"
                                lg="3"
                                className="mb-1 d-flex flex-column align-items-start"
                            >
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{
                                        for: 'responsabilities'
                                    }}
                                >{`${t(
                                    'views.classicContract.labels.responsabilities'
                                )}`}</JpLabel>
                                {data?.contractor_metadata?.RESPONSIBILITIES ? (
                                    <Button
                                        className={`table-action p-75`}
                                        onClick={() => {
                                            setIsOpen(true)
                                            setModalTitle(
                                                t(
                                                    'views.classicContract.labels.responsabilities'
                                                )
                                            )
                                            setModalInfo([
                                                data?.contractor_metadata
                                                    ?.RESPONSIBILITIES
                                            ])
                                        }}
                                        color="link"
                                    >
                                        <FontAwesomeIcon
                                            icon={faList}
                                            id={'responsabilitiesIcon'}
                                            size="lg"
                                        />
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={'responsabilitiesIcon'}
                                        >
                                            {t('see')}
                                        </UncontrolledTooltip>
                                    </Button>
                                ) : (
                                    <p className="p-75">-</p>
                                )}
                            </Col>
                            <Col sm="6" lg="3" className="mb-1 mb-md-0">
                                <JpLabel
                                    className="ps-75"
                                    labelOptions={{
                                        for: 'has_payroll'
                                    }}
                                >{`${t(
                                    'views.classicContract.labels.payByJoobpay'
                                )}`}</JpLabel>
                                <JpSwitchController
                                    name="has_payroll"
                                    control={control}
                                    id="switch-info"
                                    className="form-switch form-check-info mt-50 ms-75"
                                    optionsLabel={{
                                        className: 'form-check-label text-break'
                                    }}
                                    optionsInput={{
                                        checked: hasPayroll,
                                        onClick: () => {
                                            setHasPayroll(!hasPayroll)
                                        },
                                        disabled: isDisabled
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card style={{ minHeight: 160 }}>
                    <CardHeader className="p-1">
                        <CardTitle tag="h4">
                            <JpText
                                className="p-75"
                                type="card-title"
                                children={t('accessProfile')}
                            />
                        </CardTitle>
                    </CardHeader>
                    <CardBody className="d-flex flex-column py-2">
                        <Row>
                            <Col md="4" className="mb-1 mb-md-0">
                                <JpSwitchController
                                    name="time_active"
                                    control={control}
                                    id="switch-info"
                                    className="form-switch form-check-info"
                                    label={t(
                                        'views.profile.payments.measuringTime'
                                    )}
                                    optionsLabel={{
                                        className: 'form-check-label text-break'
                                    }}
                                    optionsInput={{
                                        checked: timeTracking,
                                        onClick: () => {
                                            setTimeTracking(!timeTracking)
                                        },
                                        disabled: isDisabled || isTimeContract
                                    }}
                                />
                            </Col>

                            {timeTracking && (
                                <>
                                    <Col xs="12" md="4">
                                        <JpSwitchController
                                            name="productivity_active"
                                            control={control}
                                            id="switch-info"
                                            className="form-switch form-check-info"
                                            label={t(
                                                'views.profile.payments.productivity'
                                            )}
                                            optionsLabel={{
                                                className:
                                                    'form-check-label text-break'
                                            }}
                                            optionsInput={{
                                                checked: productivity,
                                                onClick: () =>
                                                    setProductivity(
                                                        !productivity
                                                    ),
                                                disabled: isDisabled
                                            }}
                                        />
                                    </Col>
                                </>
                            )}

                            <Col md="4" className="mb-1 mb-md-0">
                                <JpSwitchController
                                    name="has_projects"
                                    control={control}
                                    id="switch-info"
                                    className="form-switch form-check-info"
                                    label={`${t('views.projects.project')}s`}
                                    optionsLabel={{
                                        className: 'form-check-label text-break'
                                    }}
                                    optionsInput={{
                                        checked: hasProjects,
                                        onClick: () => {
                                            setHasProjects(!hasProjects)
                                        },
                                        disabled: isDisabled || isTasksContract
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-1">
                            {timeTracking && (
                                <Col
                                    className=" mt-1 mt-md-0 d-flex align-items-center"
                                    md="4"
                                >
                                    <JpAsyncSelectController
                                        name="time_profile_id"
                                        control={control}
                                        errors={errors}
                                        optionsAsyncSelect={{
                                            isDisabled: isDisabled,
                                            defaultOptions:
                                                getTimeProfile?.result?.map(
                                                    item => ({
                                                        value: item.id,
                                                        label: `${item.name} ${item.hours}/hrs`,
                                                        name: item.name,
                                                        time: item.hours
                                                    })
                                                ),
                                            styles: {
                                                control: (
                                                    baseStyles,
                                                    state
                                                ) => ({
                                                    ...baseStyles,
                                                    width: 250
                                                })
                                            },
                                            menuPlacement: 'top'
                                        }}
                                        loadOptions={getTimeProfileSelect}
                                    />
                                </Col>
                            )}
                            {productivity && (
                                <Col className=" mt-1 mt-md-0" md="4">
                                    <JpAsyncSelectController
                                        name="productivity_profile_id"
                                        control={control}
                                        errors={errors}
                                        placeHolder={t(
                                            'views.profile.payments.productivityProfile'
                                        )}
                                        optionsAsyncSelect={{
                                            isDisabled: isDisabled,
                                            defaultOptions:
                                                getProductivityProfile?.data?.map(
                                                    item => ({
                                                        value: item.id,
                                                        label: item.name,
                                                        name: item.name
                                                    })
                                                ),
                                            styles: {
                                                control: (
                                                    baseStyles,
                                                    state
                                                ) => ({
                                                    ...baseStyles,
                                                    width: 250
                                                })
                                            },
                                            menuPlacement: 'top'
                                        }}
                                        loadOptions={productivityProfile}
                                    />
                                </Col>
                            )}
                        </Row>
                    </CardBody>
                </Card>

                {!isDisabled && (
                    <Col className="d-flex mb-2 justify-content-end" sm="12">
                        <div className="me-75">
                            <JpButton
                                text={t('modals.cancel')}
                                type="cancel"
                                options={{
                                    onClick: () => {
                                        onClose()
                                    }
                                }}
                            />
                        </div>

                        <JpButton type="save" buttonType="submit" />
                    </Col>
                )}
            </Col>

            <JpModal
                isOpen={isOpen}
                toggle={() => {
                    setIsOpen(!isOpen)
                }}
                headerContent={<>{modalTitle}</>}
                bodyContent={<BodyModalContent />}
            />
        </Row>
    )
}
export default AccountTabs
