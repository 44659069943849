// ** React Imports
import { Fragment, useEffect, useState } from 'react'
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap'
import { useQuery } from '@tanstack/react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import '@styles/react/pages/page-account-settings.scss'
import { useDispatch } from 'react-redux'
import { addBreadcrumbs } from '@src/redux/breadcrumbs'
import { parseName } from '@src/utility/Utils'
import {
    SpinnerLoader,
    ProfileHeader,
    JpBombLight,
    JpButtonGroup,
    JpAvatar,
    HasPermissionModules
} from '@Intelli/utilities'
import AdminAPI from '@src/services/API'
import AccountTabs from './AccountTab'
import DocumentTab from './DocumentsTab'
import PayrollTab from './PayrollTab'
import useSocket from '@src/hooks/useSocket'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import ProductivityDetailsProfile from '@src/views/ProductivityDetailsProfile'
import TimesDetailsContractor from '@src/views/TimesDetailsContractor'
import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'
import usePermissionsProductivity from '@src/hooks/usePermissionsProductivity'
import searchFirstModuleActiveFunction from '@src/utility/searchFirstModuleActiveFunction'

const ContractorProfile = () => {
    const API = new AdminAPI()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const permissionsProductivity = usePermissionsProductivity()
    const { id } = useParams()
    const [params, setSearchParams] = useSearchParams()

    const { modules } = useApplicationModules()

    // ** States
    const [currentPage, setCurrentPage] = useState(1)
    const [showTimeTabs, setShowTimeTabs] = useState(
        searchFirstModuleActiveFunction('TIME')
    )
    const [showProductivityTabs, setShowProductivityTabs] = useState(
        permissionsProductivity.searchFirstModuleActive
    )
    const [shouldRefetch, setShouldRefetch] = useState(false)

    const hasWritePermission = HasPermissionModules.WriteUpdate(modules, [
        'CONTRACTS',
        'CONTRACTS-CONTRACTORS'
    ])

    const {
        data: dataProfile,
        status,
        refetch
    } = useQuery(['profile', id], () => API.getContractor(id), {
        onSuccess: data => {
            dispatch(
                addBreadcrumbs({
                    id,
                    value: parseName(
                        data.user.first_name || data.email,
                        data.user.last_name
                    )
                })
            )
        }
    })

    useSocket({
        [`/admin/contractors/${id}`]: {
            fn: value => {
                refetch()
            }
        }
    })

    useEffect(() => {
        if (params.get('page')) {
            setCurrentPage(parseInt(params.get('page')))
        }
    }, [params])

    const onTabChange = index => {
        setSearchParams({
            page: index
        })
    }

    useEffect(() => {
        if (shouldRefetch !== null) {
            refetch()
        }
    }, [shouldRefetch])

    if (status === 'loading') {
        return <SpinnerLoader />
    }
    const profileAvatar = () => {
        return dataProfile?.photo?.photo ? (
            <div className="d-flex align-items-end">
                <JpAvatar
                    img={dataProfile.photo.photo}
                    imgHeight={100}
                    imgWidth={100}
                    imgClassName="cover cursor-default"
                    className="cursor-default"
                    contentStyles={{
                        cursor: 'default'
                    }}
                />
                {dataProfile?.realtime ? (
                    <JpBombLight
                        size={'lg'}
                        state={dataProfile?.realtime?.state}
                    />
                ) : (
                    <JpBombLight size={'lg'} state={'DISCONNECTED'} />
                )}
            </div>
        ) : (
            <div className="d-flex align-items-end">
                <JpAvatar
                    content={parseName(
                        dataProfile.user.first_name || dataProfile.email,
                        dataProfile.user.last_name
                    )}
                    initials
                    color="primary"
                    contentStyles={{
                        height: 100,
                        width: 100,
                        fontWeight: 'bold',
                        fontSize: 30,
                        cursor: 'default'
                    }}
                    imgClassName="rounded cursor-default"
                />
                {dataProfile?.realtime && (
                    <JpBombLight
                        size={'lg'}
                        state={dataProfile?.realtime?.state}
                    />
                )}
            </div>
        )
    }

    const PageBody = () => {
        const pages = {
            1: (
                <AccountTabs
                    hasWritePermission={hasWritePermission}
                    data={dataProfile}
                    type={'SETTINGS_PROFILE_DETAILS'}
                    setShouldRefetch={setShouldRefetch}
                    shouldRefetch={shouldRefetch}
                />
            ),
            2: <TimesDetailsContractor />,
            3: <ProductivityDetailsProfile />,
            4: (
                <DocumentTab
                    hasWritePermission={hasWritePermission}
                    userId={dataProfile.contractor_id}
                />
            ),
            5: <PayrollTab />,
        }

        return pages[currentPage]
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <ProfileHeader
                        Avatar={profileAvatar()}
                        Name={parseName(
                            dataProfile.user.first_name || dataProfile.email,
                            dataProfile.user.last_name
                        )}
                        Details={dataProfile?.contractor_metadata?.POSITION}
                    />
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                    <JpButtonGroup className="mb-1">
                        <Button
                            className="d-flex align-items-center"
                            color="info"
                            onClick={() => onTabChange(1)}
                            active={currentPage === 1}
                            outline={currentPage !== 1}
                            id="account"
                        >
                            <FontAwesomeIcon
                                className="m-0 me-xl-50"
                                icon="fa-solid fa-user"
                            />
                            <span className="d-none d-xl-block">
                                {t('profile')}
                            </span>
                        </Button>
                        <UncontrolledTooltip
                            target={'account'}
                            className="d-block d-xl-none"
                        >
                            {t('Account')}
                        </UncontrolledTooltip>

                        <Button
                            className="d-flex align-items-center"
                            color="info"
                            onClick={() => onTabChange(4)}
                            active={currentPage === 4}
                            outline={currentPage !== 4}
                            id="documents"
                        >
                            <FontAwesomeIcon
                                className="m-0 me-xl-50"
                                icon="fa-solid fa-file"
                            />
                            <span className="d-none d-xl-block">
                                {t('Documents')}
                            </span>
                        </Button>

                        <UncontrolledTooltip
                            target={'documents'}
                            className="d-block d-xl-none"
                        >
                            {t('Documents')}
                        </UncontrolledTooltip>
                        <Button
                            className="d-flex align-items-center"
                            color="info"
                            onClick={() => onTabChange(5)}
                            active={currentPage === 5}
                            outline={currentPage !== 5}
                            id="payments"
                        >
                            <FontAwesomeIcon
                                className="m-0 me-xl-50"
                                icon="fa-solid fa-file-invoice-dollar"
                            />
                            <span className="d-none d-xl-block">
                                {t('views.profile.payments.paymentsInfo')}
                            </span>
                        </Button>

                        <UncontrolledTooltip
                            target={'payments'}
                            className="d-block d-xl-none"
                        >
                            {t('views.profile.payments.paymentsInfo')}
                        </UncontrolledTooltip>
                        {dataProfile?.time?.active && showTimeTabs && (
                            <>
                                <Button
                                    className="d-flex align-items-center"
                                    color="info"
                                    onClick={() => onTabChange(2)}
                                    active={currentPage === 2}
                                    outline={currentPage !== 2}
                                    id="times"
                                >
                                    <FontAwesomeIcon
                                        className="m-0 me-xl-50"
                                        icon="fa-solid fa-alarm-clock"
                                    />
                                    <span className="d-none d-xl-block">
                                        {t('Times')}
                                    </span>
                                </Button>

                                <UncontrolledTooltip
                                    target={'times'}
                                    className="d-block d-xl-none"
                                >
                                    {t('Times')}
                                </UncontrolledTooltip>
                            </>
                        )}
                        {dataProfile?.productivity?.active &&
                            showProductivityTabs && (
                                <>
                                    <Button
                                        className="d-flex align-items-center"
                                        id="toggler"
                                        color="info"
                                        onClick={() => {
                                            if (currentPage !== 3) {
                                                onTabChange(3)
                                            }
                                        }}
                                        active={currentPage === 3}
                                        outline={currentPage !== 3}
                                    >
                                        <FontAwesomeIcon
                                            className="m-0 me-xl-50"
                                            icon="fa-solid fa-arrow-trend-up"
                                        />

                                        <span className="d-none d-xl-block">
                                            {t('Productivity')}
                                        </span>
                                    </Button>
                                    <UncontrolledTooltip
                                        target={'toggler'}
                                        className="d-block d-xl-none"
                                    >
                                        {t('Productivity')}
                                    </UncontrolledTooltip>
                                </>
                            )}

                        
                    </JpButtonGroup>
                </Col>
            </Row>
            <PageBody />
        </Fragment>
    )
}

export default ContractorProfile
