import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import { SpinnerLoader, JpAvatar, user$, parseName } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    Col,
    Row,
    UncontrolledTooltip
} from 'reactstrap'
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons'

const BORDER_CLASSES = {
    YOU_HIRE: 'type__employer',
    WE_HIRE_FOR_YOU: 'type__contractor',
    WE_EMPLOY_FOR_YOU: 'type__contract'
}

const getMethodAvatar = method => {
    if (method === 'YOU_HIRE') {
        return (
            <JpAvatar
                className="contract-method-icon border border-5"
                size="xl"
                color={!!!user$.value?.company?.logo ? 'info' : 'white'}
                img={user$.value?.company?.logo}
                initials={!!!user$.value?.company?.logo}
                content={
                    !!!user$.value?.company?.logo ? parseName(
                        user$.value?.company?.name
                    ) : ''
                }
                imgClassName="dashboard_img"
            />
        )
    } else {
        return (
            <JpAvatar
                className="contract-method-icon border border-5 dashboard_img"
                size="xl"
                color={method === 'WE_EMPLOY_FOR_YOU' ? 'green' : 'primary'}
                icon={<FontAwesomeIcon icon="fak fa-joobpay" />}
            />
        )
    }
}

const Methods = ({ method, setMethod }) => {
    const API = new AdminAPI()
    const { t } = useTranslation()

    const { data: modalitiesData, isSuccess: modalitiesIsSuccess } = useQuery(
        ['modalities'],
        () => API.getModalities(),
        { keepPreviousData: true }
    )

    if (!modalitiesIsSuccess) return <SpinnerLoader />

    //Parse data to build the cards
    const modalities = modalitiesData.data
        .map(modality => ({
            id: modality.modality_id,
            ...modality.client,
            name: modality.name,
            order: modality.order,
            title: t(`views.newContract.steps.employer.${modality.name}.title`),
            subtitle: t(
                `views.newContract.steps.employer.${modality.name}.subtitle`
            ),
            tooltip: t(
                `views.newContract.steps.employer.${modality.name}.tooltip`
            ),
            icon: getMethodAvatar(modality.name),
            borderClass: BORDER_CLASSES[modality.name]
        }))
        .sort((a, b) => a.order - b.order)

    const colsProps = {
        lg: Math.floor(12 / modalities.length) - 1,
        xs: 12
    }

    return (
        <>
            <Row>
                <Col>
                    <h1 className="text-center mt-2">
                        {t('views.newContract.steps.employer.cardTitle')}
                    </h1>
                </Col>
            </Row>
            <Row className={`pricing-card p-0 my-5`}>
                <Col>
                    <Row className="justify-content-evenly">
                        {modalities.map((item, index) => {
                            return (
                                <Col
                                    key={index}
                                    {...colsProps}
                                    tag={Card}
                                    className={`text-center ${
                                        !item?.status && 'opacity-25'
                                    } border shadow-none ${
                                        item.borderClass
                                            ? `${item.borderClass}${
                                                  !item?.status
                                                      ? '__disabled'
                                                      : ''
                                              }`
                                            : ''
                                    } ${
                                        method === item.name && item?.status
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        if (!item?.status) return
                                        setMethod(item.name)
                                    }}
                                >
                                    {item.tooltip && (
                                        <CardHeader className="justify-content-end align-items-center p-0 me-1 mt-1">
                                            <UncontrolledTooltip
                                                placement="top"
                                                target={`help-${item.name}`}
                                            >
                                                {item.tooltip}
                                            </UncontrolledTooltip>
                                            <FontAwesomeIcon
                                                id={`help-${item.name}`}
                                                icon={faCircleQuestion}
                                                size="lg"
                                            />
                                        </CardHeader>
                                    )}
                                    <CardBody>
                                        {item.icon}
                                        <h3
                                            className={`${
                                                !item.tooltip ? 'mt-2' : 'mt-1'
                                            }`}
                                        >
                                            {item.title}
                                        </h3>
                                        <CardText
                                            className={`${
                                                !item.content ? 'pb-2' : ''
                                            }`}
                                        >
                                            {item.subtitle}
                                        </CardText>
                                        {item.content}
                                    </CardBody>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Methods
