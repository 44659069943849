import {
    JpTableV1,
    JpText,
    dateLocalizer,
    JpDatePicker,
    parseMinutesToHours,
    percentage,
    formatDatesUtc,
    JpTableTitleName,
    JpAvatar
} from '@Intelli/utilities'
import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import ProductivityDetailGraphs from './ProductivityDetailsGraphs'
import useSocket from '@src/hooks/useSocket'

const TabActions = ({
    start_date,
    setStartDate,
    end_date,
    setEndDate,
    setCurrentPage
}) => {
    return (
        <Row className="justify-content-between align-items-center">
            <Col
                sm="4"
                xl="3"
                className="d-flex justify-content-start table-filters mt-1 mt-md-0"
            >
                <JpDatePicker
                    id="start_date"
                    value={start_date}
                    setPicker={date => {
                        setCurrentPage(0)
                        setStartDate(date.format('YYYY-MM-DD'))
                    }}
                    className={'enabled-joobpay'}
                    maxDate={moment(end_date).format('YYYY-MM-DD')}
                />
                <JpDatePicker
                    id="end_date"
                    value={end_date}
                    maxDate={moment().format('YYYY-MM-DD')}
                    setPicker={date => {
                        setCurrentPage(0)
                        setEndDate(date.format('YYYY-MM-DD'))
                    }}
                    className={'enabled-joobpay ms-1'}
                    minDate={start_date}
                />
            </Col>
        </Row>
    )
}

const ProductivityDetailsMain = ({ contractorId }) => {
    const { t } = useTranslation()
    const API = new AdminAPI()

    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState('')
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('date__desc')
    const [start_date, setStartDate] = useState(
        moment().subtract(15, 'days').format('YYYY-MM-DD')
    )
    const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))

    const {
        data: productivityDashboardData,
        isLoading: productivityDashboardIsLoading,
        refetch: productivityDashboardRefetch
    } = useQuery(
        [
            'productivityDetailsDashboard',
            currentPage,
            limit,
            queries,
            sortBy,
            contractorId,
            start_date,
            end_date
        ],
        () =>
            API.getProductivityDetailsDashboard({
                contractor_id: contractorId,
                offset: currentPage,
                limit,
                order: sortBy,
                query: queries,
                date__between: `${formatDatesUtc.getDateStringtoUTC(
                    start_date
                )},${formatDatesUtc.getDateStringtoUTC(
                    moment(end_date).add(1, 'days').format('YYYY-MM-DD')
                )}`
            })
    )

    useSocket(
        {
            [`/productivity/contractors/${contractorId}/dashboard`]: {
                fn: value => {
                    if (end_date === moment().format('YYYY-MM-DD')) {
                        productivityDashboardRefetch()
                    }
                }
            },
            [`/productivity/contractors/${contractorId}/dashboard/stats`]: {
                fn: value => {
                    if (end_date === moment().format('YYYY-MM-DD')) {
                        productivityDashboardRefetch()
                    }
                }
            }
        },
        [end_date]
    )

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    //Tab Cols
    const tabCols = [
        {
            name: `${t('views.productivity.date')}`,
            sortable: 'date',
            /* width: '130px', */
            cell: row => {
                return (
                    <span id={`Last-Access-${row.access_id}`}>
                        {row?.date
                            ? dateLocalizer.getIntlLongDate(row?.date)
                            : '-'}
                    </span>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.appMostUsed')}
                    tooltipText={t('views.productivity.appMostUsedTooltip')}
                />
            ),
            sortable: false,
            width: '210px',
            cell: row => {
                return (
                    <div className="d-flex align-items-center text-truncate">
                        {row?.app_name && (
                            <div className="d-flex justify-content-center align-items-center">
                                {row?.app_media?.location ? (
                                    <JpAvatar
                                        color={`light-info`}
                                        img={row?.app_media?.location}
                                        imgClassName="avatar-cover"
                                    />
                                ) : (
                                    <JpAvatar
                                        color={`light-info`}
                                        content={
                                            row.app_name && `${row.app_name}`
                                        }
                                        initials
                                    />
                                )}
                            </div>
                        )}

                        <JpText
                            type="span-table-text"
                            className="ms-50 "
                            children={`${row?.app_name ? row?.app_name : '-'}`}
                        />
                    </div>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.active')}
                    tooltipText={t('views.productivity.activeTime')}
                />
            ),
            sortable: 'active_time',
            cell: row => {
                return (
                    <JpText
                        type="span-table-text"
                        children={`${
                            row?.active_time
                                ? parseMinutesToHours(row?.active_time)
                                : '-'
                        }`}
                    />
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.idle')}
                    tooltipText={t('views.productivity.idleTime')}
                />
            ),
            sortable: 'afk_time',
            cell: row => {
                return (
                    <JpText
                        type="span-table-text"
                        children={`${
                            row?.afk_time
                                ? parseMinutesToHours(row?.afk_time)
                                : '-'
                        }`}
                    />
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.productive')}
                    tooltipText={t('views.productivity.productiveTime')}
                />
            ),
            sortable: 'productive_time',
            cell: row => {
                return (
                    <>
                        <JpText
                            id={`productive-${row?.contractor_day_id}`}
                            type="small-text"
                            className="text-green"
                            children={`${percentage(
                                row?.work_time,
                                row?.productive_time
                            )}%`}
                        />
                        <UncontrolledTooltip
                            target={`productive-${row?.contractor_day_id}`}
                        >
                            {row?.productive_time
                                ? parseMinutesToHours(row?.productive_time)
                                : '-'}
                        </UncontrolledTooltip>
                    </>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.unproductive')}
                    tooltipText={t('views.productivity.unproductiveTime')}
                />
            ),
            sortable: 'unproductive_time',
            cell: row => {
                return (
                    <>
                        <JpText
                            id={`unproductive-${row?.contractor_day_id}`}
                            type="small-text"
                            className="text-danger"
                            children={`${percentage(
                                row?.work_time,
                                row?.unproductive_time
                            )}%`}
                        />
                        <UncontrolledTooltip
                            target={`unproductive-${row?.contractor_day_id}`}
                        >
                            {row?.unproductive_time
                                ? parseMinutesToHours(row?.unproductive_time)
                                : '-'}
                        </UncontrolledTooltip>
                    </>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.neutral')}
                    tooltipText={t('views.productivity.neutralTime')}
                />
            ),
            sortable: 'neutral_time',
            cell: row => {
                return (
                    <>
                        <JpText
                            id={`neutral-${row?.contractor_day_id}`}
                            type="small-text"
                            className="text-info"
                            children={`${percentage(
                                row?.work_time,
                                row?.neutral_time
                            )}%`}
                        />
                        <UncontrolledTooltip
                            target={`neutral-${row?.contractor_day_id}`}
                        >
                            {row?.neutral_time
                                ? parseMinutesToHours(row?.neutral_time)
                                : '-'}
                        </UncontrolledTooltip>
                    </>
                )
            }
        },
        {
            name: `${t('views.productivity.states.ON_CALL')}`,
            sortable: 'on_call_time',
            cell: row => {
                return (
                    <>
                        <JpText
                            id={`oncall-${row?.contractor_day_id}`}
                            type="span-table-text"
                            className="text-yellow"
                            children={`${percentage(
                                row?.work_time,
                                row?.on_call_time
                            )}%`}
                        />
                        <UncontrolledTooltip
                            target={`oncall-${row?.contractor_day_id}`}
                        >
                            {row?.neutral_time
                                ? parseMinutesToHours(row?.on_call_time)
                                : '-'}
                        </UncontrolledTooltip>
                    </>
                )
            }
        }
    ]

    //Table actions

    return (
        <>
            <ProductivityDetailGraphs
                start_date={start_date}
                end_date={end_date}
                contractor_id={contractorId}
            />
            <JpTableV1
                offset={currentPage}
                cols={tabCols}
                fetcher={handlePageChange}
                data={productivityDashboardData?.data}
                total={productivityDashboardData?.count}
                loading={productivityDashboardIsLoading}
                actions={
                    <TabActions
                        start_date={start_date}
                        setStartDate={setStartDate}
                        end_date={end_date}
                        setEndDate={setEndDate}
                        setCurrentPage={setCurrentPage}
                    />
                }
            />
        </>
    )
}

export default ProductivityDetailsMain
