import {
    JpModal,
    parseMinutesToHours,
    NoData,
    JpAvatar,
    JpText
} from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { productivityStatesIcons } from '@src/constants/icons/productivityStatesIcons'
import { parseName } from '@src/utility/Utils'
import { Col, Row } from 'reactstrap'

const AppsModal = ({ isOpen, setIsOpen, setQuerySession, data }) => {
    const { t } = useTranslation()

    const onClose = () => {
        setIsOpen(false)
        setQuerySession(null)
    }

    const LiApps = () => {
        const Tag = 'ul'

        return (
            <Tag className="timeline">
                {data?.data?.length > 0 ? (
                    data?.data?.map((item, i) => {
                        const ItemTag = item.tag ? item.tag : 'li'

                        return (
                            <ItemTag key={i} className={'timeline-item pb-1'}>
                                <span
                                    className={`timeline-point timeline-point-indicator bg-${
                                        productivityStatesIcons[item.state]
                                            ?.color
                                    }`}
                                >
                                    {/* {item.icon ? item.icon : null} */}
                                </span>
                                <div className="timeline-event">
                                    <div
                                        className={
                                            'd-flex flex-column mt-75 '
                                        }
                                    >
                                        <Row className="mt-n75">
                                            <Col md="2">
                                                {item.state === 'AFK' ? (
                                                    <JpAvatar
                                                        icon={
                                                            <i
                                                                class={`fa fa-laptop-slash`}
                                                            ></i>
                                                        }
                                                        color={
                                                            productivityStatesIcons[
                                                                item.state
                                                            ]?.color
                                                        }
                                                        className={`cursor-default`}
                                                    />
                                                ) : item?.app?.location ? (
                                                    <JpAvatar
                                                        color={`light-info`}
                                                        img={
                                                            item?.app?.location
                                                        }
                                                        imgClassName="avatar-cover cursor-default"
                                                    />
                                                ) : (
                                                    <JpAvatar
                                                        color={`light-info`}
                                                        content={
                                                            item.app.name &&
                                                            parseName(
                                                                item.app.name
                                                            )
                                                        }
                                                        initials
                                                    />
                                                )}
                                            </Col>
                                            <Col
                                                className="text-break"
                                                md="8"
                                            >
                                                {item.state === 'AFK' ? (
                                                    <JpText
                                                        type="span-table-text"
                                                        children={`${t(
                                                            'INACTIVE'
                                                        )}`}
                                                    />
                                                ) : (
                                                    <JpText
                                                        type="p-text"
                                                        children={`${item.app.name}`}
                                                    />
                                                )}
                                                <JpText
                                                    type="span-table-text"
                                                    children={`${parseMinutesToHours(
                                                        item.time
                                                    )}`}
                                                />
                                            </Col>

                                            <Col md="2">
                                                <span>
                                                    {moment(
                                                        item.start_datetime
                                                    ).format('HH:mm:ss')}
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </ItemTag>
                        )
                    })
                ) : (
                    <NoData color="light" size="5x" />
                )}
            </Tag>
        )
    }

    return (
        <JpModal
            isOpen={isOpen}
            toggle={onClose}
            headerContent={<>{t('views.productivity.timeline')}</>}
            bodyContent={<LiApps />}
            options={{
                modalOptions: {
                    scrollable: true,
                    centered: true
                }
            }}
        />
    )
}

export default AppsModal
