import {
    faAlarmClock,
    faChartSimple,
    faListCheck
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import { SpinnerLoader, user$, JpAvatar, parseName } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardText, Col, Row } from 'reactstrap'

const contractTypesIcons = {
    CLASSIC: <FontAwesomeIcon icon={faChartSimple} size="3x" />,
    TASKS: <FontAwesomeIcon icon={faListCheck} size="3x" />,
    TIME: <FontAwesomeIcon icon={faAlarmClock} size="3x" />
}

const TypesTitle = ({ method }) => {
    const { t } = useTranslation()
    return (
        <Row>
            <Col md="4" className="d-flex align-items-center ps-1 ms-1">
                {method === 'YOU_HIRE' ? (
                    <>
                        {/* <JpAvatar
                        imgClassName="dashboard_img cursor-default"
                        className="contract-type-avatar border border-5 me-75"
                        color="white"
                        size="xl"
                        img={user$.value?.company?.logo}
                        initials={!!!user$.value?.company?.logo}
                        content={
                            !!!user$.value?.company?.logo
                            ? parseName(user$.value?.company?.name)
                            : ''
                        }
                    /> */}
                        <JpAvatar
                            className="contract-type-avatar border border-5 me-75"
                            size="xl"
                            color={
                                !!!user$.value?.company?.logo ? 'info' : 'white'
                            }
                            img={user$.value?.company?.logo}
                            initials={!!!user$.value?.company?.logo}
                            content={
                                !!!user$.value?.company?.logo
                                    ? parseName(user$.value?.company?.name)
                                    : ''
                            }
                            imgClassName="dashboard_img"
                        />
                    </>
                ) : (
                    <JpAvatar
                        imgClassName="dashboard_img cursor-default"
                        className="contract-type-avatar border border-5 me-75"
                        color={
                            method === 'WE_EMPLOY_FOR_YOU' ? 'green' : 'primary'
                        }
                        size="xl"
                        icon={<FontAwesomeIcon icon="fak fa-joobpay" />}
                    />
                )}
                <h3 className="text-primary m-0">
                    {t(`views.newContract.steps.employer.${method}.title`)}
                </h3>
            </Col>
            <Col className="d-flex align-items-center">
                <h1 className="m-0">
                    {t('views.newContract.steps.type.cardTitle')}
                </h1>
            </Col>
        </Row>
    )
}

const Types = ({ type, setType, setTypes, method }) => {
    const API = new AdminAPI()
    const { t } = useTranslation()

    const { data: contractTypesData, isSuccess: contractTypesDataIsSuccess } =
        useQuery(['contractTypes', method], () => API.getContractTypes(), {
            onSuccess: data => {
                setTypes(data.data)
            }
        })

    if (!contractTypesDataIsSuccess) return <SpinnerLoader />

    const types = contractTypesData?.data
        ?.sort((a, b) => a.order - b.order)
        .map(item => ({
            title: t(`views.settings.templates.types.${item.name}`),
            id: item.id,
            borderClass: 'method',
            name: item.name,
            subtitle: t(
                `views.newContract.steps.type.types.${item.name}.subtitle`
            ),
            icon: contractTypesIcons[item.name]
        }))

    const colsProps = {
        lg: Math.floor(12 / types.length) - 1,
        xs: 12
    }

    return (
        <>
            <TypesTitle method={method} />
            <Row className={`pricing-card p-0 mt-5`}>
                <Col>
                    <Row className="justify-content-evenly">
                        {types.map((item, index) => {
                            return (
                                <Col
                                    key={index}
                                    {...colsProps}
                                    tag={Card}
                                    className={`text-center border shadow-none ${
                                        item.borderClass
                                            ? `${item.borderClass}`
                                            : ''
                                    } ${
                                        type.name === item.name ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        setType({
                                            name: item.name,
                                            id: item.id
                                        })
                                    }}
                                >
                                    <CardBody className="d-flex flex-column justify-content-center">
                                        {item.icon}
                                        <h3 className={'mt-2'}>{item.title}</h3>
                                        <CardText>{item.subtitle}</CardText>
                                        {item.content}
                                    </CardBody>
                                </Col>
                            )
                        })}
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Types
