import { useEffect, useState } from 'react'
import { HasPermissionModules } from '@Intelli/utilities'
import { useApplicationModules } from './ApplicationModules.hook'

const usePermissionsProjects = () => {
    const { modules } = useApplicationModules()

    const [hasWriteProjects, setHasWriteProjects] = useState(
        HasPermissionModules.WriteUpdate(modules, [
            'PROJECTS',
            'PROJECTS-PROJECTS'
        ])
    )
    const [hasReadProjects, setHasReadProjects] = useState(
        HasPermissionModules.Read(modules, ['PROJECTS', 'PROJECTS-PROJECTS'])
    )
    const [hasReadProjectsContractors, setHasReadProjectsContractors] =
        useState(
            HasPermissionModules.Read(modules, [
                'PROJECTS',
                'PROJECTS-CONTRACTORS'
            ])
        )

    return {
        hasWriteProjects,
        hasReadProjects,
        hasReadProjectsContractors
    }
}

export default usePermissionsProjects
