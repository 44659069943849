import React, { Suspense } from 'react'

// ** Router Import
import Router from './router/Router'

// ** React Imports
import { BrowserRouter } from 'react-router-dom'

// ** Redux Imports
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react'
import { store, persistor } from './redux/store'
import { Provider } from 'react-redux'

// ** ThemeColors Context

import { ThemeContext } from './utility/context/ThemeColors'

// ** ThemeConfig
import themeConfig from './configs/themeConfig'

// ** Toast
import { Toaster } from 'react-hot-toast'

// ** i18n
import './configs/i18n'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Hot Toast Styles
import '@styles/react/libs/react-hot-toasts/react-hot-toasts.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { language$ } from '@Intelli/utilities'
import i18next from 'i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import ScrollToTop from './components/general/ScrollToTop'

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false
            }
        }
    })

    language$.subscribe(lang => {
        i18next.changeLanguage(lang)
        moment.locale(lang)
    })

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <ScrollToTop />
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        {/* <Suspense fallback={<Spinner />}> */}
                        <Suspense
                            fallback={
                                <FontAwesomeIcon
                                    icon="fak fa-joobpay"
                                    size="10x"
                                    beatFade
                                />
                            }
                        >
                            <ThemeContext>
                                <Suspense
                                    fallback={
                                        <FontAwesomeIcon
                                            icon="fak fa-joobpay"
                                            size="10x"
                                            beatFade
                                        />
                                    }
                                >
                                    <Router />
                                </Suspense>
                                <Toaster
                                    position={themeConfig.layout.toastPosition}
                                    toastOptions={{
                                        className: 'react-hot-toast'
                                    }}
                                />
                            </ThemeContext>
                        </Suspense>
                    </PersistGate>
                </Provider>
            </BrowserRouter>
        </QueryClientProvider>
    )
}

export default App
