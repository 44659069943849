import React, { useEffect, useState } from 'react'
import MaskedInput from 'react-text-mask'

function formatHourMask(timeStr) {
    // Separamos las horas y los minutos usando el delimitador ':'
    const [hours, minutes] = timeStr.split(':').map(Number)

    // Creamos un array para almacenar las partes formateadas
    const formattedParts = []

    // Si las horas son mayores que 0, añadimos las horas formateadas
    if (hours > 0) {
        formattedParts.push(`${hours}h`)
    }

    // Si los minutos son mayores que 0, añadimos los minutos formateados
    if (minutes > 0) {
        formattedParts.push(`${minutes}m`)
    } else {
        formattedParts.push(`00m`)
    }

    // Si las horas y los minutos son 0, devolvemos '0m' (opcional)
    if (formattedParts.length === 0) {
        formattedParts.push('0m')
    }

    return formattedParts.join(' ')
}

function HourInput({ newHour, setNewHour, id }) {
    const [hour, setHour] = useState(newHour)

    const handleHourChange = e => {
        const inputValue = e.target.value
        setHour(inputValue)
        setNewHour(inputValue)
    }

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    useEffect(() => {
        document.getElementById(id).focus()
        setNewHour(formatHourMask(newHour))
    }, [id])

    const mask = function (rawValue) {
        const inputValueTransform = rawValue
            .replace(/[^\d]/g, ' ')
            .trim()
            .replace(/\s+/g, '').length

        switch (inputValueTransform) {
            case 1:
                return [/\d/, 'm']
            case 2:
                return [/\d/, /\d/, 'm']
            case 3:
                return [/\d/, 'h', ' ', /\d/, /\d/, 'm']
            case 4:
                return [/\d/, /\d/, 'h', ' ', /\d/, /\d/, 'm']
            default:
                return [/\d/, /\d/, 'h', ' ', /\d/, /\d/, 'm']
        }
    }

    return (
        <>
            <MaskedInput
                mask={value => mask(value)}
                placeholder="00h 00m"
                value={hour}
                id={id}
                onKeyDown={handleKeyDown}
                onChange={handleHourChange}
                className="form-control"
            />
        </>
    )
}

export default HourInput
