// ** React Imports
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// ** Reactstrap Imports
import { Button, Row } from 'reactstrap'

// ** Styles
import '@styles/base/pages/page-misc.scss'

import source from '@src/assets/images/pages/error.svg'
import logo from '../assets/images/logo/logo_blue_horizontal.svg'
const Error = () => {
    const { t } = useTranslation()

    return (
        // <div className='misc-wrapper'>
        <div>
            <Row className="justify-content-center">
                <img
                    src={logo}
                    alt="logo"
                    className="logo text-center mt-2"
                    style={{ maxWidth: '250px' }}
                />
            </Row>
            <Row className="misc-inner p-1 p-sm-3">
                <div className="w-100 text-center">
                    <h2 className="mb-1">{t('404.Title-404')}</h2>
                    <p className="mb-2">
                        {t('404.Text-Content')}
                        <Link to="/" color="link" className="btn-sm-block">
                            {t('404.Btn-back')}
                        </Link>
                    </p>

                    {/* <img style={{ maxHeight: '400px' }} className='img-fluid' src={source} alt='Not authorized page'/> */}
                </div>
                <img
                    style={{ maxHeight: '400px' }}
                    className="img-fluid"
                    src={source}
                    alt="Not authorized page"
                />
            </Row>
        </div>
    )
}
export default Error
