// ** Imports
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import SendInvite from './SendInvite'
import AdminAPI from '@src/services/API'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { parseName } from '@src/utility/Utils'
import {
    JpTableV1,
    JpTableAction,
    SweetModal,
    dateLocalizer,
    JpAvatarGroup,
    JpAvatar,
    JpCountryFlag,
    JpCurrencyNumber,
    JpButton,
    JpModal,
    SpinnerLoader,
    JpText,
    JpTableTitleName,
    ContractorNameInfo,
    CONTRACT_MODALITY_VARIABLES
} from '@Intelli/utilities'
import {
    faChildReaching,
    faCircleExclamation,
    faFileArrowDown,
    faUserSlash
} from '@fortawesome/pro-solid-svg-icons'
import { downloadFile } from '@src/utility/Files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useSocket from '@src/hooks/useSocket'
import { useDispatch, useSelector } from 'react-redux'
import { setSignContractModal } from '@src/redux/signContractModal'
import ContractorAvatar from '@src/components/contractors/ContractorAvatar'
import { Col, Row } from 'reactstrap'

export const services = {
    YOU_HIRE: {
        icon: <FontAwesomeIcon icon={faChildReaching} />,
        color: 'purple'
    },
    WE_HIRE_FOR_YOU: {
        icon: <FontAwesomeIcon icon={'fak fa-joobpay'} />,
        color: 'primary'
    },
    WE_EMPLOY_FOR_YOU: {
        icon: <FontAwesomeIcon icon={'fak fa-joobpay'} />,
        color: 'success'
    },
    MANAGE: {
        icon: <FontAwesomeIcon icon="fa-solid fa-building" />,
        color: 'info'
    }
}

export const contracts = {
    CLASSIC: {
        icon: <FontAwesomeIcon icon="fa-solid fa-chart-simple" />,
        color: 'primary'
    },
    TIME: {
        icon: <FontAwesomeIcon icon="fa-solid fa-alarm-clock" />,
        color: 'success'
    },
    TASKS: {
        icon: <FontAwesomeIcon icon="fa-solid fa-list-check" />,
        color: 'warning'
    }
}

const contractor_status = 'ACTIVED'
const ContractorsActive = ({
    hasLegalRepresentative = false,
    hasWritePermission,
    /* validateHavePaymentMethod */
}) => {
    const { t, i18n } = useTranslation()
    const API = new AdminAPI()
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [queries, setQueries] = useState('')
    const [sortBy, setSortBy] = useState('created_at__desc')
    const signContractModal = useSelector(state => state.signContractModal)
    const [modalSigned, setModalSigned] = useState(
        signContractModal ? true : false
    )
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { mutate: updateAccess } = useMutation(
        data => API.changeContractorStatus(data),
        {
            onSuccess: () => {
                //showModal(t('modals.success'), t('modals.userDisabled'), 'success')
                SweetModal(
                    'success',
                    t('Success'),
                    t('modals.userDisabled'),
                    t('Ok')
                )
                setShouldUpdate(!shouldUpdate)
            }
        }
    )

    const TabActions = () => {
        return (
            <Row>
                <Col className="d-flex justify-content-end mt-1 mt-md-0 me-1">
                    <JpButton
                        id="add"
                        type="add"
                        tooltip
                        tooltipTextCustom={t(
                            'views.activeContractors.table.invite'
                        )}
                        options={{
                            onClick: () => {
                                /* if (validateHavePaymentMethod()) {
                                    navigate('/admin/contractors/invitation')
                                    } */
                               navigate('/admin/contractors/invitation')
                            },
                            textLess: true,
                            iconPosition: 'center'
                        }}
                    />
                </Col>
            </Row>
        )
    }

    const handleStatus = id => {
        SweetModal(
            'warning',
            t('views.activeContractors.table.modal.inactiveTitle'),
            t('views.activeContractors.table.modal.inactiveText'),
            t('Ok'),
            ({ isConfirmed }) => {
                if (isConfirmed) {
                    updateAccess({ id, data: { status: 'DISABLED' } })
                }
            },
            { showCancelButton: true, cancelButtonText: t('CANCEL') }
        )
    }

    const tabCols = [
        {
            sortable: false,
            width: '20px',
            cell: row => (
                <ContractorAvatar
                    className={
                        'd-flex justify-content-center align-items-center'
                    }
                    key={row.contractor_id}
                    content={{
                        photo: row?.user_photo?.photo,
                        name: row?.first_name,
                        bombLight: row?.realtime?.state,
                        contractor_id: row?.contractor_id
                    }}
                />
            )
        },
        {
            name: t('views.activeContractors.table.fields.name'),
            minWidth: '220px',
            sortable: 'first_name',
            cell: row => {
                const Tag = row?.has_been_actived ? Link : 'div'

                return (
                    <ContractorNameInfo
                        Tag={Tag}
                        classNameContainer={'d-flex flex-column'}
                        first_name={row?.first_name}
                        last_name={row?.last_name}
                        contractor_id={row.contractor_id}
                        smallContent={row?.position}
                    />
                )
            }
        },
        {
            name: t('views.activeContractors.table.fields.team'),
            sortable: false,
            grow: 1,
            hide: 'md',
            cell: (row, i) =>
                row?.teams ? (
                    <JpAvatarGroup
                        data={row.teams.slice(0, 3).map((team, index) => {
                            const colors = [
                                'light-warning',
                                'light-success',
                                'light-info',
                                'light-primary'
                            ]

                            if (index === 2 && row.teams.length > 3) {
                                return {
                                    content: `+${row.teams.length - 2}`,
                                    id: `team${i}-${index}`,
                                    color: colors[index],
                                    imgClassName: 'shadow-none'
                                }
                            }

                            return {
                                title: team.name,
                                content: team.name,
                                initials: true,
                                color: colors[index],
                                id: `team-${i}-${index}`,
                                imgClassName: 'shadow-none'
                            }
                        })}
                    />
                ) : (
                    <></>
                )
        },
        {
            name: t('views.activeContractors.table.fields.country'),
            sortable: 'country',
            hide: 'md',
            cell: row => (
                <>
                    {row.country ? (
                        <div className={i18n.language === 'en' ? 'ms-75' : ''}>
                            <JpCountryFlag
                                countryCode={row?.country}
                                id={row?.contractor_id}
                            />
                        </div>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: t('views.activeContractors.table.fields.salary'),
            sortable: 'contractor_salary',
            // width: '120px',
            cell: row => (
                <>
                    <JpCurrencyNumber
                        value={row.salary}
                        time={row?.contract_type_name === 'TIME'}
                    />
                </>
            )
        },
        {
            name: t('views.inactiveContractors.table.contractType'),
            sortable: 'contract_type_name',
            compact: true,
            cell: (row, i) => {
                const dataAG = [
                    {
                        text: row?.modality
                            ? t(
                                  `views.newContract.steps.employer.${row?.modality}.title`
                              )
                            : t(
                                  `views.newContract.steps.employer.NoRecruitmentFlow`
                              ),
                        icon: row?.modality ? (
                            CONTRACT_MODALITY_VARIABLES[row?.modality]?.icon
                        ) : (
                            <FontAwesomeIcon icon="fa-solid fa-image-portrait" />
                        ),
                        color: row?.modality
                            ? CONTRACT_MODALITY_VARIABLES[row?.modality]?.color
                            : 'info'
                    },
                    {
                        text: t(
                            `views.settings.templates.types.${row?.contract_type_name}`
                        ),
                        icon: contracts[row?.contract_type_name]?.icon,
                        color: contracts[row?.contract_type_name]?.color
                    }
                ]

                return (
                    <JpAvatarGroup
                        data={dataAG.map((contract, index) => {
                            return {
                                title: contract.text,
                                icon: contract.icon,
                                color: contract.color,
                                id: `contract-${i}-${index}`,
                                imgClassName: 'shadow-none'
                            }
                        })}
                    />
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.activeContractors.table.fields.date')}
                    tooltipText={t('views.classicContract.labels.startDate')}
                />
            ),
            sortable: 'start_date',
            hide: 'lg',
            cell: row =>
                `${
                    row.start_date
                        ? dateLocalizer.getIntlLongDate(row.start_date)
                        : ''
                } `
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            right: true,
            cell: row => (
                <div className="d-flex">
                    {row.is_contract_process &&
                        row?.contract_signed_media?.location && (
                            <JpTableAction
                                icon={faFileArrowDown}
                                id={`download-${row.contractor_id}`}
                                tooltipText={t(
                                    'views.activeContractors.icons.download'
                                )}
                                onClick={() =>
                                    downloadFile(
                                        row?.contract_signed_media?.location,
                                        row?.contract_signed_media?.name
                                    )
                                }
                            />
                        )}

                    {hasWritePermission && (
                        <JpTableAction
                            icon={faUserSlash}
                            id={`disable-${row.contractor_id}`}
                            tooltipText={t(
                                'views.activeContractors.icons.disable'
                            )}
                            onClick={() => handleStatus(row.contractor_id)}
                        />
                    )}

                    {!hasLegalRepresentative && (
                        <JpTableAction
                            icon={faCircleExclamation}
                            id={`warning-${row.contractor_id}`}
                            tooltipText={t(
                                'module.noLegalRepresentative.title',
                                {
                                    ns: 'errors'
                                }
                            )}
                            className=" text-warning"
                        />
                    )}
                </div>
            )
        }
    ]

    const {
        data: activeContractors,
        isLoading: activeContractorsIsLoading,
        refetch: activeContractorsRefetch
    } = useQuery(
        [
            'activeContractors',
            currentPage,
            queries,
            limit,
            shouldUpdate,
            sortBy
        ],
        () =>
            API.getActiveContractors({
                query: queries,
                offset: currentPage,
                limit,
                order: sortBy,
                contractor_status
            }),
        { keepPreviousData: true }
    )

    const LoaderWithMsg = () => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center my-2">
                <div>
                    <h4 className="mb-5">{t('modals.contractChangeState')}</h4>
                </div>

                <div>
                    <SpinnerLoader />
                    <JpText
                        type="p-text"
                        className="mt-5 text-center"
                        children={`${t('modals.plsWait')}`}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (modalSigned) {
            const timeout = setTimeout(() => {
                setModalSigned(false)
                dispatch(setSignContractModal(false))
            }, 20000)
            return () => {
                clearInterval(timeout)
            }
        }
    }, [modalSigned])

    useSocket({
        '/admin/contractors': {
            fn: value => {
                activeContractorsRefetch()
                dispatch(setSignContractModal(false))
            }
        }
    })

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    return (
        <>
            <JpModal
                isOpen={modalSigned}
                toggle={() => {}}
                headerContent={false}
                bodyContent={
                    <>
                        <LoaderWithMsg />
                    </>
                }
            />
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={activeContractors?.data}
                total={activeContractors?.count}
                loading={activeContractorsIsLoading}
                actions={hasWritePermission && <TabActions />}
            />
        </>
    )
}

export default ContractorsActive
