// ** Router imports
import { createBrowserRouter, useRoutes } from 'react-router-dom'

// ** GetRoutes
import { getRoutes } from './routes'

// ** Hooks Imports
import { useLayout } from '@hooks/useLayout'
import { useQueries, useQuery } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setBackofficeUser } from '@src/redux/user'
import { addRoutes } from '@src/redux/routes'
import { setHasRL } from '@src/redux/hasRL'

const Router = () => {
    // ** Hooks
    const { layout } = useLayout()
    const API = new AdminAPI()

    const allRoutes = getRoutes(layout)
    const dispatch = useDispatch()

    const routes = useRoutes([...allRoutes])
    const broutes = createBrowserRouter(allRoutes)

    /*     console.log('routes', routes)
    console.log('broutes', broutes) */

    const availablePaths = []

    for (const route of allRoutes) {
        if (route.children) {
            for (const child of route.children) {
                availablePaths.push(child.path)
            }
        } else {
            availablePaths.push(route.path)
        }
    }

    dispatch(addRoutes(availablePaths))

    const [userAuth, userAdmin, legalRepresentativesReduce] = useQueries({
        queries: [
            { queryKey: ['userAuth'], queryFn: () => API.getUser() },
            { queryKey: ['userAdmin'], queryFn: () => API.getAdminUserInfo() },
            {
                queryKey: ['legalRepresentativesReduce'],
                queryFn: () => API.getLegalRepresentativeStatusCount()
            }
        ]
    })

    useEffect(() => {
        if (legalRepresentativesReduce.isSuccess) {
            dispatch(setHasRL(legalRepresentativesReduce?.data))
        }
    }, [legalRepresentativesReduce.isSuccess])

    useEffect(() => {
        if (userAuth.isSuccess && userAdmin.isSuccess) {
            dispatch(
                setBackofficeUser({
                    userAuth: userAuth.data,
                    userAdmin: userAdmin.data
                })
            )
        }

    }, [userAuth.isSuccess, userAdmin.isSuccess])

    return routes
}

export default Router
