import { Controller } from 'react-hook-form'
import { Input, Label } from 'reactstrap'

const JpSwitchController = ({
    name,
    id,
    label,
    className,
    control,
    optionsController = {},
    optionsInput = {},
    optionsLabel = {}
}) => {
    return (
        <div className={className}>
            <Controller
                name={name}
                control={control}
                {...optionsController}
                render={({ field }) => (
                    <Input
                        defaultChecked
                        id={name}
                        type="switch"
                        className="me-1 cursor-pointer"
                        {...optionsInput}
                        {...field}
                    />
                )}
            />
            <Label
                for={name}
                className="form-check-label"
                {...optionsLabel}
            >
                {label}
            </Label>
        </div>
    )
}

export default JpSwitchController
