import {
    JpStatsIconCard,
    JpBarsCardV1,
    JpStackedBarsCard,
    parseMinutesToHours,
    JpPercentageText,
    JpAvatar,
    JpCurrencyNumber
} from '@Intelli/utilities'
import { faClockThree, faDollarSign } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { productivityStatesIcons } from '@src/constants/icons/productivityStatesIcons'
import useSocket from '@src/hooks/useSocket'
import AdminAPI from '@src/services/API'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'
import updateChartSocket from '@src/utility/updateChartSocket'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Card, CardTitle, Col, Row, UncontrolledTooltip } from 'reactstrap'

const ProductivityGraphs = ({ start_date, end_date }) => {
    const { t } = useTranslation()
    const API = new AdminAPI()
    const { id } = useParams()
    const queryClient = useQueryClient()

    const refetchQuerysInterval = dateToday => {
        if (dateToday === moment().format('YYYY-MM-DD')) {
            return 60000 // 1 minute
        } else {
            return false
        }
    }

    const dateQuery = {
        [end_date ? 'date__between' : 'date']: `${start_date}${
            end_date ? `,${end_date}` : ''
        }`
    }

    const {
        data: weeklyWorkedData,
        isLoading: weeklyWorkedLoading,
        refetch: weeklyDataRefetch
    } = useQuery(
        ['weekly-worked', id],
        () =>
            id
                ? API.getContractorWeeklyWorkedHours(id, dateQuery)
                : API.getWeeklyWorkedHours({ date: start_date }),
        {
            refetchInterval: () => {
                const isToday = id ? end_date : start_date
                return refetchQuerysInterval(isToday)
            }
        }
    )

    const {
        data: getContractorProductivityChartsData,
        isLoading: getContractorProductivityChartsIsLoading,
        refetch: hoursGraphsRefetch
    } = useQuery(
        ['getContractorProductivityCharts', id],
        () =>
            id
                ? API.getContractorProductivityCharts(id, dateQuery)
                : API.getProductivityCharts({ date: start_date }),
        {
            refetchInterval: () => {
                const isToday = id ? end_date : start_date
                return refetchQuerysInterval(isToday)
            }
        }
    )

    useEffect(() => {
        hoursGraphsRefetch()
        weeklyDataRefetch()
    }, [start_date, end_date])

    useSocket(
        id && disabledRefetchSocket(end_date)
            ? {
                  [`/time/contractors/${id}/charts/contractor_times`]: {
                      fn: value => {
                          hoursGraphsRefetch()
                      }
                  },
                  [`/time/contractors/${id}/charts/week`]: {
                      fn: value => {
                          weeklyDataRefetch()
                      }
                  }
              }
            : disabledRefetchSocket(start_date)
            ? {
                  '/time/charts/contractor_times': {
                      fn: value => {
                          updateChartSocket(
                              queryClient,
                              ['getContractorProductivityCharts'],
                              value
                          )
                      }
                  },
                  '/time/charts/week': {
                      fn: value => {
                          updateChartSocket(
                              queryClient,
                              ['weekly-worked'],
                              value
                          )
                      }
                  }
              }
            : {},
        [start_date, end_date, queryClient, id]
    )

    const getTotalWeeklyTime = () => {
        return (
            weeklyWorkedData?.week
                .map(item => parseFloat(item.productive_time.percent))
                .reduce((acc, item) => acc + item, 0) === 0
        )
    }

    return (
        <>
            <Row className="mb-2 gap-1 gap-lg-0">
                <Col md="4" lg="2" className="text-start">
                    <JpStatsIconCard
                        title={t('views.productivity.effective')}
                        stat={
                            <>
                                <div id="effective">
                                    {`${parseMinutesToHours(
                                        getContractorProductivityChartsData
                                            ?.effective?.time
                                    )} (${
                                        getContractorProductivityChartsData
                                            ?.effective?.percent_work_time
                                    }%)`}
                                </div>
                                <UncontrolledTooltip target={'effective'}>
                                    {
                                        <JpCurrencyNumber
                                            value={
                                                getContractorProductivityChartsData
                                                    ?.fee?.amount
                                            }
                                        />
                                    }
                                </UncontrolledTooltip>
                            </>
                        }
                        statSize="xs"
                        icon={<FontAwesomeIcon icon={faClockThree} />}
                        iconBgColor="cyan"
                        iconSize="lg"
                        iconDirection="top"
                        subtitle={
                            id
                                ? t('views.time.graphs.lastPeriod')
                                : t('views.time.graphs.lastDay')
                        }
                        subtitlePercentage={
                            getContractorProductivityChartsData?.effective
                                ?.percent_last
                        }
                        bodyClassName={'justify-content-md-start gap-2 py-0'}
                        loading={getContractorProductivityChartsIsLoading}
                    />
                </Col>
                <Col md="4" lg="2" className="text-start">
                    <JpStatsIconCard
                        title={t('views.productivity.productive')}
                        stat={`${parseMinutesToHours(
                            getContractorProductivityChartsData?.productive
                                ?.time
                        )} (${
                            getContractorProductivityChartsData?.productive
                                ?.percent_work_time
                        }%)`}
                        statSize="xs"
                        icon={
                            <JpAvatar
                                icon={
                                    <i
                                        class={
                                            productivityStatesIcons.PRODUCTIVE
                                                .icon
                                        }
                                    ></i>
                                }
                                color={productivityStatesIcons.PRODUCTIVE.color}
                            />
                        }
                        iconBgColor={productivityStatesIcons.PRODUCTIVE.color}
                        iconSize="lg"
                        iconDirection="top"
                        subtitle={
                            id
                                ? t('views.time.graphs.lastPeriod')
                                : t('views.time.graphs.lastDay')
                        }
                        subtitlePercentage={
                            getContractorProductivityChartsData?.productive
                                ?.percent_last
                        }
                        bodyClassName={'justify-content-md-start gap-2 py-0'}
                        loading={getContractorProductivityChartsIsLoading}
                    />
                </Col>
                <Col md="4" lg="2" className="text-start">
                    <JpStatsIconCard
                        swapBgColor={true}
                        title={t('views.productivity.unproductive')}
                        stat={`${parseMinutesToHours(
                            getContractorProductivityChartsData?.unproductive
                                ?.time
                        )} (${
                            getContractorProductivityChartsData?.unproductive
                                ?.percent_work_time
                        }%)`}
                        statSize="xs"
                        icon={
                            <JpAvatar
                                icon={
                                    <i
                                        class={
                                            productivityStatesIcons.UNPRODUCTIVE
                                                .icon
                                        }
                                    ></i>
                                }
                                color={
                                    productivityStatesIcons.UNPRODUCTIVE.color
                                }
                            />
                        }
                        iconBgColor={productivityStatesIcons.UNPRODUCTIVE.color}
                        iconSize="lg"
                        iconDirection="top"
                        subtitle={
                            id
                                ? t('views.time.graphs.lastPeriod')
                                : t('views.time.graphs.lastDay')
                        }
                        subtitlePercentage={
                            getContractorProductivityChartsData?.unproductive
                                ?.percent_last
                        }
                        bodyClassName={'justify-content-md-start gap-2 py-0'}
                        loading={getContractorProductivityChartsIsLoading}
                    />
                </Col>
                <Col md="4" lg="2" className="text-start">
                    <JpStatsIconCard
                        title={'Neutral'}
                        stat={`${parseMinutesToHours(
                            getContractorProductivityChartsData?.neutral?.time
                        )} (${
                            getContractorProductivityChartsData?.neutral
                                ?.percent_work_time
                        }%)`}
                        statSize="xs"
                        icon={
                            <JpAvatar
                                icon={
                                    <i
                                        class={
                                            productivityStatesIcons.NEUTRAL.icon
                                        }
                                    ></i>
                                }
                                color={productivityStatesIcons.NEUTRAL.color}
                            />
                        }
                        iconBgColor={productivityStatesIcons.NEUTRAL.color}
                        iconSize="lg"
                        iconDirection="top"
                        subtitle={
                            id
                                ? t('views.time.graphs.lastPeriod')
                                : t('views.time.graphs.lastDay')
                        }
                        subtitlePercentage={
                            getContractorProductivityChartsData?.neutral
                                ?.percent_last
                        }
                        bodyClassName={'justify-content-md-start gap-2 py-0'}
                        loading={getContractorProductivityChartsIsLoading}
                    />
                </Col>

                <Col lg="4" sm="12" xs="12" className="mt-1 mt-lg-0">
                    {/* {getTotalWeeklyTime() ? (
                        <JpStackedBarsCard
                            data={[Array.from({ length: 7 }, (_, i) => 1)]}
                            labels={Array.from({ length: 7 }, (_, i) =>
                                moment().subtract(i, 'days').format('ddd')
                            ).reverse()}
                            seriesNames={[t('Productivity')]}
                            seriesColors={['light']}
                            tooltipFormatters={[(value, index) => 0]}
                            height={125}
                            classNameCard="mb-0 h-100"
                            cardBodyHeight={{ height: 125 }}
                            footerClassName="py-50"
                            cols={true}
                            bodyClassName="justify-content-between pt-75 pb-1"
                            additionalInfo={
                                <div className="d-flex flex-column align-items-between justify-content-between h-100 ">
                                    <CardTitle id="timevsprod" className="hhhh">
                                        {t(
                                            'views.time.graphs.productivityVsTime'
                                        )}
                                    </CardTitle>
                                    <UncontrolledTooltip target={'timevsprod'}>
                                        {t(
                                            'views.time.graphs.productivityVsTimeTooltip'
                                        )}
                                    </UncontrolledTooltip>
                                    <span className="mt-1">
                                        <h5
                                            id="worked-time"
                                            className="mb-0 fw-normal mb-25"
                                        >
                                            {t('views.time.graphs.workedTime')}
                                        </h5>
                                        <h6 className="m-0 fw-bolder">
                                            {parseMinutesToHours(
                                                weeklyWorkedData?.hours?.current
                                            )}
                                        </h6>
                                    </span>
                                    <small className="d-flex">
                                        <JpPercentageText
                                            percentage={parseFloat(
                                                weeklyWorkedData?.hours?.percent
                                            )}
                                            periodText={`${t(
                                                'views.time.graphs.lastPeriod'
                                            )}`}
                                        />
                                    </small>
                                </div>
                            }
                            loading={weeklyWorkedLoading}
                        />
                    ) : (
                        <JpStackedBarsCard
                            data={[
                                weeklyWorkedData?.week
                                    ?.map(
                                        item =>
                                            parseFloat(
                                                item.productive_time.percent
                                            ) / 100
                                    )
                                    ?.reverse(),
                                weeklyWorkedData?.week
                                    ?.map(
                                        item =>
                                            parseFloat(
                                                item.productive_time.percent
                                            ) / 100
                                    )
                                    .map(item => {
                                        return item === 0 ? 0 : 1 - item
                                    })
                                    ?.reverse()
                            ]}
                            labels={Array.from({ length: 7 }, (_, i) =>
                                moment(id ? end_date : start_date)
                                    .subtract(i, 'days')
                                    .format('dd')
                            ).reverse()}
                            seriesNames={[
                                t('Productivity'),
                                t('views.time.graphs.unproductivity')
                            ]}
                            seriesColors={['green', 'danger']}
                            cols={true}
                            tooltipFormatters={[
                                (value, index) => {
                                    const totalWeek =
                                        weeklyWorkedData?.week?.length - 1
                                    return `${(value * 100).toFixed(2)}% ${t(
                                        'Time'
                                    )}: ${parseMinutesToHours(
                                        weeklyWorkedData?.week[
                                            totalWeek - index
                                        ]?.work_time?.value
                                    )} `
                                },
                                (value, index) => {
                                    const totalWeek =
                                        weeklyWorkedData?.week?.length - 1 //Esta constante es para obtener los dias de la semana
                                    return `${
                                        weeklyWorkedData?.week[
                                            totalWeek - index
                                        ]?.unproductive_time?.percent
                                    }% ${t('Time')}: ${parseMinutesToHours(
                                        weeklyWorkedData?.week[
                                            totalWeek - index
                                        ]?.work_time?.value
                                    )}`
                                }
                            ]}
                            height={125}
                            classNameCard="mb-0 h-100"
                            footerClassName="py-50"
                            bodyClassName="justify-content-between pt-75 pb-1"
                            additionalInfo={
                                <div className="d-flex flex-column align-items-between justify-content-between h-100">
                                    <CardTitle id="timevsprod" className="">
                                        {t(
                                            'views.time.graphs.productivityVsTime'
                                        )}
                                    </CardTitle>
                                    <UncontrolledTooltip target={'timevsprod'}>
                                        {t(
                                            'views.time.graphs.productivityVsTimeTooltip'
                                        )}
                                    </UncontrolledTooltip>
                                    <span>
                                        <h5
                                            id="worked-time"
                                            className="mb-0 fw-normal mb-25"
                                        >
                                            {t('views.time.graphs.workedTime')}
                                        </h5>

                                        <h4 className="m-0 fw-bolder">
                                            {parseMinutesToHours(
                                                weeklyWorkedData?.hours?.current
                                            )}
                                        </h4>
                                    </span>
                                    <small className="d-flex">
                                        <JpPercentageText
                                            percentage={parseFloat(
                                                weeklyWorkedData?.hours?.percent
                                            )}
                                            periodText={`${t(
                                                'views.time.graphs.lastPeriod'
                                            )}`}
                                        />
                                    </small>
                                </div>
                            }
                            loading={weeklyWorkedLoading}
                        />
                    )} */}
                    <JpBarsCardV1
                        title={`${t(
                            'views.time.graphs.productivityVsTime'
                        )} ${t('views.time.graphs.workedTime')}`}
                        cardClassName={'mb-0'}
                        percentage={weeklyWorkedData?.hours?.percent}
                        stat={` ${parseMinutesToHours(
                            weeklyWorkedData?.hours?.current
                        )}`}
                        statClassName={'mb-0 ms-75'}
                        statPosition="right"
                        lastPeriodText={t('views.time.graphs.lastPeriod')}
                        barsData={[
                            {
                                data: weeklyWorkedData?.week
                                    ?.map(item => {
                                        return parseFloat(
                                            item.productive_time.percent
                                        )
                                    })
                                    ?.reverse()
                            },
                            {
                                data: weeklyWorkedData?.week
                                    ?.map(item => {
                                        return parseFloat(
                                            item.unproductive_time.percent
                                        )
                                    })
                                    ?.reverse()
                            }
                        ]}
                        colors={['#28c76f', '#EA5455']}
                        /* yTooltipFormatter={val => `${t('Productivity')} ${val}`} */
                        yTooltipFormatter={(
                            val,
                            {
                                seriesIndex,
                                w: {
                                    globals: { categoryLabels }
                                }
                            }
                        ) => {
                            const series = t(
                                `views.permissions.status.${
                                    ['PRODUCTIVE', 'UNPRODUCTIVE'][seriesIndex]
                                }`
                            )
                            return `${series}: ${val}%`
                        }}
                        seriesLabels={Array.from({ length: 7 }, (_, i) =>
                            moment(id ? end_date : start_date)
                                .subtract(i, 'days')
                                .format('dd')
                        ).reverse()}
                        loading={weeklyWorkedLoading}
                        stacked
                        chartHeight={150}
                        showXToolTip={false}
                        cardBodyClassName="pb-0"
                    />
                </Col>
            </Row>
        </>
    )
}

export default ProductivityGraphs
