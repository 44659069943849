import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminAPI from '@src/services/API'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Alert, Collapse } from 'reactstrap'



const ValidatedAccAlert = () => {
    const [alertContent, setAlertContent] = useState()
    const { userAdmin, userAuth } = useSelector(state => state.backofficeUser)
    const userCount = useSelector(state => state.hasRL)
    const { t } = useTranslation()
    const [isOpen, setIsOpen] = useState(false)

    const LR_ALERTS = {
        PENDING: {
            // message: 'Company has LR veriffied but u havent completed your process',
            message: 'views.Home.legal_representative.PENDING_VERIFICATION',
            color: 'warning',
            icon: 'seal-exclamation',
            link: '/admin/account-settings?page=3'
        },
        NOT_FINISHED: {
            message: 'views.Home.legal_representative.NOT_FINISHED',
            color: 'warning',
            icon: 'seal-exclamation',
            link: '/admin/account-settings?page=3'
        },
        REJECTED: {
            // message: 'Your LR process was rejected, upload a new document',
            message: 'views.Home.legal_representative.REJECTED',
            color: 'danger',
            icon: 'seal-exclamation',
            link: '/admin/account-settings?page=3'
        },
        VERIFFIED: {
            // message: 'LR veriffied',
            message: 'views.Home.legal_representative.VERIFFIED',
            color: 'success',
            icon: 'badge-check'
        },
        MISSING: {
            // message: 'Company does not have any LR veriffied',
            message: 'views.Home.legal_representative.MISSING',
            color: 'secondary',
            icon: 'seal-exclamation',
            link: '/admin/account-settings?page=3'
        },
        PENDING_VERIFF: {
            // message: 'Company does not have any LR veriffied',
            message: 'views.Home.pending_veriff',
            color: 'warning',
            icon: 'seal-exclamation',
            link: `/auth/legal-representative/${userAuth?.user?.id}` 
        },
    }

    const getAlertContent = (userCount, statusLR) => {
        const pendingStates = ['PENDING', 'VERIFICATION']

        if(userAuth?.veriff?.status !== 'APPROVED'){
            return LR_ALERTS.PENDING_VERIFF
        }

        if (userCount?.VERIFIED === 0 || !userCount?.VERIFIED) {
            if (
                (userCount?.PENDING === 0 && userCount?.VERIFICATION === 0) ||
                (!userCount?.PENDING &&
                    !userCount?.VERIFICATION &&
                    !userCount?.REJECTED)
            ) {
                return LR_ALERTS.MISSING
            } else if (pendingStates.includes(statusLR)) {
                return LR_ALERTS.NOT_FINISHED
            } else if (statusLR === 'REJECTED') {
                return LR_ALERTS.REJECTED
            }
        } else if (pendingStates.includes(statusLR)) {
            return LR_ALERTS.PENDING
        } else {
            return LR_ALERTS.VERIFFIED
        }
    }

    useEffect(() => {
        setAlertContent(
            getAlertContent(
                userCount,
                userAdmin?.legal_representative?.verified
            )
        )
    }, [userAdmin, userAuth, userCount])

    if (!userCount || !userAdmin || !userAuth) {
        return null
    }

    return alertContent ? (
        <Alert
            className="m-0"
            color={alertContent?.color}
            id="veriffied_rl"
            onMouseOver={() => setIsOpen(true)}
            onMouseOut={() => setIsOpen(false)}
            tag={alertContent?.link ? Link : 'div'}
            to={`${alertContent?.link}`}
        >
            <div className="alert-body d-flex align-items-center justify-content-between">
                <Collapse isOpen={isOpen} className="me-75" horizontal>
                    <small className="text-nowrap">
                        {t(alertContent?.message)}
                    </small>
                </Collapse>
                <FontAwesomeIcon icon={alertContent?.icon} size="lg" />
            </div>
        </Alert>
    ) : (
        <></>
    )
}

export default ValidatedAccAlert
