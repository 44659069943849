import {
    JpTableActionsCol,
    JpTableV1,
    JpCurrencyNumber,
    JpAvatar,
    JpTableAction,
    dateLocalizer,
    JpText,
    JpDatePicker,
    JpTableTitleName,
    parseMinutesToHours,
    ContractorNameInfo,
    PROJECTS_STATE_COLORS,
    parseCurrency,
    iconStarFn
} from '@Intelli/utilities'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Badge, Col, Row, UncontrolledTooltip } from 'reactstrap'
import AdminAPI from '@src/services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { detailsIcon } from '@src/constants/icons/detailsIcon'
import PersonsProjectsGraphs from './PersonProjectsGraphs'
import RatingModalInfo from '@src/components/projects/RatingModalInfo'
import { formatNumberIntFloat, parseName } from '@src/utility/Utils'

const PersonProjectsTable = () => {
    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState('')
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('start_date__desc')
    const [start_date, setStartDate] = useState('')
    const [end_date, setEndDate] = useState('')
    const [modalRateInfo, setModalRateInfo] = useState(false)
    const [ratingData, setRatingData] = useState(null)

    const { t } = useTranslation()
    const API = new AdminAPI()
    const { contractor_id, id } = useParams()
    const navigate = useNavigate()

    const {
        mutateAsync: getPersonsAllRatingInProject,
        isLoading: getPersonsAllRatingInProjectLoading
    } = useMutation(data =>
        API.getPersonsAllRatingInProject(data.project_id, data.contractor_id)
    )

    const { data: getPersonProjects, isLoading: getPersonProjectsLoading } =
        useQuery(
            [
                'getPersonProjects',
                currentPage,
                limit,
                queries,
                sortBy,
                start_date,
                end_date
            ],
            () =>
                API.getPersonProjects(contractor_id || id, {
                    offset: currentPage,
                    limit,
                    query: queries,
                    order: sortBy,
                    start_date__gte: `${
                        start_date
                            ? moment(start_date)
                                  .format('YYYY-MM-DD')
                            : ''
                    }`,
                    end_date__lte: `${
                        end_date ? moment(end_date).format('YYYY-MM-DD') : ''
                    }`
                })
        )

    const tabCols = [
        {
            name: '',
            sortable: row => row.logo,
            width: '20px',
            cell: row => (
                <div className="d-flex align-items-center">
                    <JpAvatar
                        color={`light-${PROJECTS_STATE_COLORS[row?.status]}`}
                        content={row?.name}
                        initials
                    />
                </div>
            )
        },
        {
            name: t('views.projects.project'),
            sortable: 'name',
            minWidth: '250px',
            compact: true,
            wrap: true,
            cell: row => (
                <>
                    <ContractorNameInfo
                        classNameContainer="d-flex flex-column user-info text-primary"
                        Tag={Link}
                        customNavigate={`/admin/projects/details/${row?.project_id}`}
                        largeName={row?.name}
                        contractor_id={row.project_id}
                    />
                </>
            )
        },
        {
            name: `${t('views.permissions.start')}`,
            sortable: 'start_date',
            cell: row => (
                <JpText type="span-table-text">
                    {row.start_date
                        ? dateLocalizer.getIntlLongDate(row.start_date)
                        : '-'}
                </JpText>
            )
        },
        {
            name: `${t('views.permissions.end')}`,
            sortable: 'end_date',
            cell: row => (
                <JpText type="span-table-text">
                    {row.end_date
                        ? dateLocalizer.getIntlLongDate(row.end_date)
                        : '-'}
                </JpText>
            )
        },
        {
            name: t('views.settings.templates.types.TASKS'),
            cell: row => (
                <>
                    <JpText
                        type="span-table-text"
                        className={`${row?.late_tasks ? 'text-danger' : ''}`}
                        children={`${row?.finished_tasks}/${row?.tasks}`}
                        id={`tasks-${row.project_id}`}
                    />
                    {row?.late_tasks ? (
                        <UncontrolledTooltip target={`tasks-${row.project_id}`}>
                            {`${t('views.projects.overdueTasks')} ${
                                row?.late_tasks
                            }`}
                        </UncontrolledTooltip>
                    ) : (
                        <UncontrolledTooltip target={`tasks-${row.project_id}`}>
                            <div className="d-flex flex-column gap-50">
                                <span>{`${t('views.projects.tasksExecuted')} ${
                                    row?.finished_tasks
                                }`}</span>
                                <span>{`${t('views.projects.tasksAssigned')} ${
                                    row?.tasks
                                }`}</span>
                            </div>
                        </UncontrolledTooltip>
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={`${t('views.projects.planned')}`}
                    tooltipText={`${t('views.projects.plannedHours')}`}
                />
            ),
            sortable: 'estimated_hours',
            cell: row => (
                <>
                    {row?.estimated_hours ? (
                        <>
                            <div id={`hoursExecuted-${row.project_id}`}>
                                {parseMinutesToHours(row?.estimated_hours)}
                            </div>
                            {row?.hours_executed ? (
                                <UncontrolledTooltip
                                    target={`hoursExecuted-${row.project_id}`}
                                >
                                    {`${t(
                                        'views.projects.executedes'
                                    )} ${parseMinutesToHours(
                                        row?.hours_executed
                                    )}`}
                                </UncontrolledTooltip>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: `${t('views.projects.budget')}`,
            sortable: 'budget',
            cell: row => (
                <>
                    {row?.budget ? (
                        <>
                            <div id={`budget_executed-${row.project_id}`}>
                                <JpCurrencyNumber value={row?.budget} />
                            </div>
                            {row?.budget_executed ? (
                                <UncontrolledTooltip
                                    target={`budget_executed-${row.project_id}`}
                                >
                                    {`${t(
                                        'views.projects.planned'
                                    )} ${parseCurrency(row?.budget_executed)}`}
                                </UncontrolledTooltip>
                            ) : (
                                ''
                            )}
                        </>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: t('views.projects.rating'),
            sortable: 'rating',
            cell: row => {
                return (
                    <div key={`id-${row.rating}`}>
                        {row?.rating ? (
                            <>
                                <div
                                    className="d-flex align-items-center cursor-pointer"
                                    id={`seerating-${row.project_id}`}
                                    onClick={async () => {
                                        const { qualifications } =
                                            await getPersonsAllRatingInProject({
                                                project_id: row?.project_id,
                                                contractor_id:
                                                    contractor_id || id
                                            })

                                        const allRatings = qualifications?.map(
                                            rating => ({
                                                comment: rating?.comment,
                                                rating: rating?.rating,
                                                budget: rating?.budget_excuted,
                                                user_photo: rating?.user_photo,
                                                first_name: rating?.first_name,
                                                last_name: rating?.last_name,
                                                position: rating?.position,
                                                rating_date: rating.created_at,
                                                name_project:
                                                    rating.name_project,
                                                name_task: rating.name_task,
                                                url_link: rating?.contractor_id
                                                    ? `/admin/contractors/profile/${rating.contractor_id}`
                                                    : `/admin/settings/users/profile/${rating?.access_id}`
                                            })
                                        )

                                        setRatingData({
                                            allRatings,
                                            rating_of: parseName(
                                                getPersonProjects?.contractor
                                                    ?.first_name,
                                                getPersonProjects?.contractor
                                                    ?.last_name
                                            )
                                        })

                                        setModalRateInfo(true)
                                    }}
                                >
                                    {iconStarFn(row.rating)}
                                    <JpText
                                        type="span-table-text"
                                        className={'ms-1 mt-25 text-warning'}
                                    >{`${formatNumberIntFloat(row.rating)}`}</JpText>
                                </div>
                                <UncontrolledTooltip
                                    target={`seerating-${row.project_id}`}
                                >
                                    {t('views.projects.seeRatings')}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            '-'
                        )}
                    </div>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('Status')}
                    tooltipText={t('views.projects.projectStatus')}
                />
            ),
            sortable: 'status',
            cell: row => (
                <>
                    {row?.status ? (
                        <Badge color={PROJECTS_STATE_COLORS[row?.status]}>
                            {t(`views.projects.projectStates.${row?.status}`)}
                        </Badge>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            width: '120px',
            allowOverflow: true,
            right: true,
            cell: row => {
                return (
                    <JpTableActionsCol>
                        <JpTableAction
                            icon={detailsIcon}
                            id={`details-${row.project_id}`}
                            tooltipText={t('views.historicalPayments.details')}
                            onClick={() => {
                                navigate(
                                    `/admin/projects/contractors/${
                                        contractor_id ?? id
                                    }/details/${row.project_id}`
                                )
                            }}
                            className="ms-75"
                        />
                    </JpTableActionsCol>
                )
            }
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    const TabActions = () => {
        return (
            <Row className="align-items-center justify-content-start">
                <Col
                    sm="4"
                    xl="4"
                    className="d-flex justify-content-start table-filters mt-1 mt-md-0"
                >
                    <JpDatePicker
                        clearable
                        id="contractStartDate"
                        tooltipText={t('startDate')}
                        value={start_date}
                        setPicker={date => {
                            setCurrentPage(0)
                            setStartDate(moment(date).format('YYYY-MM-DD'))
                        }}
                        customOnClear={() => {
                            setEndDate('')
                            setStartDate('')
                        }}
                        maxDate={end_date}
                        className={'enabled-joobpay'}
                    />

                    <JpDatePicker
                        clearable
                        disabled={!!!start_date}
                        id="contractEndDate"
                        tooltipText={t('endDate')}
                        value={end_date}
                        minDate={moment(start_date).format('YYYY-MM-DD')}
                        setPicker={date => {
                            setCurrentPage(0)
                            setEndDate(moment(date).format('YYYY-MM-DD'))
                        }}
                        customOnClear={() => {
                            setEndDate('')
                        }}
                        className={` ms-1 ${
                            !!!start_date ? 'disabled-bg' : ''
                        }`}
                    />
                </Col>
            </Row>
        )
    }

    const onCloseModalRateInfo = () => {
        setModalRateInfo(false)
        setRatingData(null)
    }

    return (
        <>
            <PersonsProjectsGraphs startDate={start_date} endDate={end_date} />
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={getPersonProjects?.projects}
                total={getPersonProjects?.count}
                loading={getPersonProjectsLoading}
                actions={<TabActions />}
            />
            <RatingModalInfo
                isOpen={modalRateInfo}
                onClose={onCloseModalRateInfo}
                ratingData={ratingData}
            />
        </>
    )
}

export default PersonProjectsTable
