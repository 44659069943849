import { useEffect, useState } from 'react'

import {
    Row,
    Col,
    Input,
    InputGroup,
    InputGroupText,
    Card,
    CardBody
} from 'reactstrap'

import ReactPaginate from 'react-paginate'

import {
    JpButton,
    JpCardSkeleton,
    JpDatePicker,
    formatDatesUtc,
    JpFilterButtons,
    SweetModal
} from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import moment from 'moment'
import ScreenshotCards from '@src/components/productivity/ScreenshotCards'
import ScreenshotModal from '@src/components/productivity/ScreenshotModal'
import { useParams } from 'react-router-dom'
import DetailsChartsCards from './DetailsChartsCards'
import useSocket from '@src/hooks/useSocket'
import { productivityStatesIcons } from '@src/constants/icons/productivityStatesIcons'
import { productivityStates } from '@src/constants/states/productivity/productivityStates'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'

const SCREENSHOTS_PER_ROW = 4

const ScreenshotsDetail = ({ permissionWriteScreenshots }) => {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState('')
    const [query, setQuery] = useState('')
    const [start_date, setStartDate] = useState(moment().format('YYYY-MM-DD'))
    const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [modal, setModal] = useState(false)
    const [selectedScreenshot, setSelectedScreenshot] = useState(null)
    const [sortBy, setSortBy] = useState('timestamp__desc')
    const [screenshotWaiting, setScreenshotWaiting] = useState(false)
    const [type, setType] = useState('')
    const API = new AdminAPI()
    const { id } = useParams()

    const perPage = SCREENSHOTS_PER_ROW * 2

    const filterForScreenshotModal = {
        query,
        timestamp__between: `${formatDatesUtc.getDateStringtoUTC(
            start_date
        )},${formatDatesUtc.getDateStringtoUTC(
            moment(end_date).add(1, 'days').format('YYYY-MM-DD')
        )}`,
        order: sortBy,
        app_type: type,
        contractor_id: id
    }

    const {
        data: screenshotsData,
        isLoading: screenshotsIsLoading,
        isError: screenshotsIsError,
        refetch: screenshotsDataRefetch
    } = useQuery(
        [
            'contractor-screenshots',
            currentPage,
            query,
            start_date,
            end_date,
            sortBy,
            type
        ],
        () =>
            API.getContractorScreenshots(id, {
                limit: perPage,
                offset: currentPage * perPage,
                query,
                order: sortBy,
                app_type: type,
                timestamp__between: `${formatDatesUtc.getDateStringtoUTC(
                    start_date
                )},${formatDatesUtc.getDateStringtoUTC(
                    moment(end_date).add(1, 'days').format('YYYY-MM-DD')
                )}`
            }),
        {
            keepPreviousData: true
        }
    )

    const { mutateAsync: takeScreenshot } = useMutation(
        () => API.takeScreenshot(id),
        {
            onSuccess: () => {
                setScreenshotWaiting(true)
            }
        }
    )

    const {
        data: takeScreenshotBtnActive,
        isLoading: takeScreenshotBtnActiveIsLoading,
        isError: takeScreenshotBtnActiveIsError,
        refetch: btnActiveRefetch
    } = useQuery(['takeScreenshotBtnActive'], () =>
        API.takeScreenshotBtnActive(id)
    )

    useEffect(() => {
        if (!screenshotWaiting) return
        const timeout = setTimeout(() => {
            setScreenshotWaiting(false)
            SweetModal(
                'warning',
                t('attention'),
                t('modals.screenshotsError'),
                `${t('Ok')}`
            )
        }, 15000)
        return () => {
            clearInterval(timeout)
        }
    }, [screenshotWaiting])

    useSocket(
        screenshotWaiting || disabledRefetchSocket(end_date)
            ? {
                  [`/productivity/contractors/${id}/screenshots`]: {
                      fn: value => {
                          if (value.payload.requested && screenshotWaiting) {
                              setSelectedScreenshot(value.payload.screenshot_id)
                              setModal(true)

                              setScreenshotWaiting(false)
                          }

                          if (disabledRefetchSocket(end_date)) {
                              screenshotsDataRefetch()
                          }
                      }
                  }
              }
            : {},
        [end_date, screenshotWaiting]
    )

    useSocket({
        [`/admin/contractors/${id}`]: {
            fn: value => {
                btnActiveRefetch()
            }
        }
    })

    const handlePagination = ({ selected }) => {
        setCurrentPage(selected)
    }

    const _handleSearch = ({ target: { value } }) => {
        setCurrentPage(0)
        setQueries(value)
    }

    const filters = [
        {
            icon: productivityStatesIcons.PRODUCTIVE.icon,
            color: `light-${productivityStatesIcons.PRODUCTIVE.color}`,
            state: productivityStates.PRODUCTIVE,
            text: t('views.settings.productivity.productive')
        },
        {
            icon: productivityStatesIcons.NEUTRAL.icon,
            color: `light-${productivityStatesIcons.NEUTRAL.color}`,
            state: productivityStates.NEUTRAL,
            text: t('views.settings.productivity.neutral')
        },
        {
            icon: productivityStatesIcons.UNPRODUCTIVE.icon,
            color: `light-${productivityStatesIcons.UNPRODUCTIVE.color}`,
            state: productivityStates.UNPRODUCTIVE,
            text: t('views.settings.productivity.unproductive')
        }
    ]

    useEffect(() => {
        const timeout = setTimeout(() => {
            setQuery(queries)
        }, 300)
        return () => {
            clearInterval(timeout)
        }
    }, [queries, currentPage])

    const dataScreenshot = screenshotsData?.data?.find(
        item => item.screenshot_id === selectedScreenshot
    )

    // if (screenshotsIsLoading || screenshotsIsError) return <SpinnerLoader />

    return (
        <>
            <DetailsChartsCards start_date={start_date} end_date={end_date} />
            <Card className="mb-1">
                <CardBody>
                    <Row className="align-items-end">
                        <Col
                            className="d-flex align-items-center justify-content-end"
                            md="3"
                            sm="12"
                        >
                            <InputGroup
                                className="input-group-merge w-100"
                                md="4"
                            >
                                <InputGroupText>
                                    <FontAwesomeIcon icon="magnifying-glass" />
                                </InputGroupText>
                                <Input
                                    type="text"
                                    id="search-input"
                                    placeholder={t('search')}
                                    onChange={_handleSearch}
                                />
                            </InputGroup>
                        </Col>
                        <Col
                            sm="5"
                            md="4"
                            lg="3"
                            className="d-flex justify-content-start pe-1 table-filters"
                        >
                            <JpDatePicker
                                id="start_date"
                                value={start_date}
                                setPicker={date => {
                                    setCurrentPage(0)
                                    setStartDate(date.format('YYYY-MM-DD'))
                                }}
                                className={'enabled-joobpay'}
                                maxDate={end_date}
                            />
                            <JpDatePicker
                                id="end_date"
                                value={end_date}
                                setPicker={date => {
                                    setCurrentPage(0)
                                    setEndDate(date.format('YYYY-MM-DD'))
                                }}
                                className={'enabled-joobpay ms-1'}
                                maxDate={moment().format('YYYY-MM-DD')}
                                minDate={start_date}
                            />
                        </Col>

                        <Col className="d-flex align-items-center justify-content-end">
                            <JpFilterButtons
                                filters={filters}
                                state={type}
                                setStatusType={setType}
                                setCurrentPage={setCurrentPage}
                            />

                            {permissionWriteScreenshots && (
                                <JpButton
                                    id="screenshot"
                                    tooltip
                                    tooltipTextCustom={t(
                                        'views.productivity.takeScreenshot'
                                    )}
                                    type="action"
                                    color="green"
                                    loading={screenshotWaiting}
                                    disabled={
                                        !takeScreenshotBtnActive?.active ||
                                        screenshotWaiting
                                    }
                                    options={{
                                        customIcon: (
                                            <FontAwesomeIcon icon="camera" />
                                        ),
                                        onClick: () => takeScreenshot(),
                                        textLess: true,
                                        iconPosition: 'center'
                                    }}
                                />
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Row>
                <ScreenshotModal
                    filterForScreenshotModal={filterForScreenshotModal}
                    onClose={() => {
                        setModal(false)
                    }}
                    isOpen={modal}
                    dataScreenshot={dataScreenshot}
                    refetch={screenshotsDataRefetch}
                />
                {!screenshotsIsLoading && (
                    <ScreenshotCards
                        currentData={screenshotsData?.data}
                        detailed
                        setSelectedScreenshot={id => {
                            setSelectedScreenshot(id)
                            setModal(true)
                        }}
                    />
                )}
                {(screenshotsData?.data?.length === 0 ||
                    screenshotsIsLoading ||
                    screenshotsIsError) &&
                    Array.from(
                        { length: screenshotsIsLoading ? perPage : 4 },
                        (_, index) => ({
                            id: index,
                            number: index + 1
                        })
                    ).map((_, index) => (
                        <Col md="3" key={index}>
                            <JpCardSkeleton
                                noData={
                                    screenshotsIsError ||
                                    screenshotsData?.data?.length === 0
                                }
                                loading={screenshotsIsLoading}
                            />
                        </Col>
                    ))}
            </Row>
            <ReactPaginate
                nextLabel=""
                breakLabel="..."
                previousLabel=""
                pageRangeDisplayed={2}
                forcePage={currentPage}
                marginPagesDisplayed={2}
                activeClassName="active"
                pageClassName="page-item"
                breakClassName="page-item"
                nextLinkClassName="page-link"
                pageLinkClassName="page-link"
                breakLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextClassName="page-item next-item"
                previousClassName="page-item prev-item"
                pageCount={Math.ceil(screenshotsData?.count / perPage) || 1}
                onPageChange={page => handlePagination(page)}
                containerClassName="pagination react-paginate pagination-sm separated-pagination justify-content-end pe-1 mt-1"
            />
        </>
    )
}

export default ScreenshotsDetail
