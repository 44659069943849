/**
 * @param {string} moduleToSearch - modulo a buscar ('PRODUCTIVITY)
 * @returns el primer modulo que tiene activo
 * @example searchFirstModuleActiveFunction(modules, 'PRODUCTIVITY')
 */

import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'

const searchFirstModuleActiveFunction = (moduleToSearch) => {
    const { modules } = useApplicationModules()
    const moduleSearched = modules.find(item => item.name === moduleToSearch)

    if (!moduleSearched) return false

    return (
        [...moduleSearched.children].sort((a, b) => a.order - b.order)[0]
            .order + 1
    )
}

export default searchFirstModuleActiveFunction
