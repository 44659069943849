import { Row, Col } from 'reactstrap'
import {
    SpinnerLoader,
    JpStatsIconCard,
    JpPieGraph,
    JpGraphCard,
    parseMinutesToHours,
    JpAvatar,
    formatDatesUtc,
    JpAdvanceCard
} from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import { useEffect } from 'react'
import useSocket from '@src/hooks/useSocket'
import { productivityStatesIcons } from '@src/constants/icons/productivityStatesIcons'
import moment from 'moment'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'

const ProductivityDetailGraphs = ({ contractor_id, start_date, end_date }) => {
    const API = new AdminAPI()
    const { t } = useTranslation()

    const {
        data: getContractorProductivityChartsData,
        isLoading: getContractorProductivityChartsIsLoading,
        refetch: contractorProductivityRefecth
    } = useQuery(['getContractorProductivityCharts', contractor_id], () =>
        API.getContractorProductivityCharts(contractor_id, {
            date__between: `${formatDatesUtc.getDateStringtoUTC(
                start_date
            )},${formatDatesUtc.getDateStringtoUTC(
                moment(end_date).add(1, 'days').format('YYYY-MM-DD')
            )}`
        })
    )
    

    const {
        data: getAppsChartData,
        isLoading: getAppsChartIsLoading,
        refetch: mostAppsUsedRefetch
    } = useQuery(['getAppsChart'], () =>
        API.getAppsChart({
            contractor_id: contractor_id,

            date__between: `${start_date},${moment(end_date)
                .add(1, 'days')
                .format('YYYY-MM-DD')}`
        })
    )

    useEffect(() => {
        contractorProductivityRefecth()
        mostAppsUsedRefetch()
    }, [start_date, end_date])

    useSocket(
        {
            [`/productivity/contractors/${contractor_id}/charts/contractor_times`]:
                {
                    fn: value => {
                        disabledRefetchSocket(end_date) &&
                            contractorProductivityRefecth()
                    }
                },
            [`/productivity/contractors/${contractor_id}/charts/most_used_apps`]:
                {
                    fn: value => {
                        disabledRefetchSocket(end_date) && mostAppsUsedRefetch()
                    }
                }
        },
        [contractor_id, end_date]
    )

    const byProductiveColor = {
        afk: 'yellow',
        neutral: 'info',
        productive: 'success',
        unproductive: 'danger'
    }

    function filterObject(obj, keysToKeep) {
        return Object.keys(obj).reduce((acc, key) => {
            if (keysToKeep.includes(key)) {
                acc[key] = obj[key]
            }
            return acc
        }, {})
    }

    return (
        <Row className="mb-2 justify-content-center">
            <Col lg="3">
                <JpGraphCard
                    title={`${t('views.productivity.%appsUsage')}`}
                    className="h-100"
                    cardBodyClass="d-flex align-items-center justify-content-center"
                    loading={getContractorProductivityChartsIsLoading}
                    graph={
                        !getContractorProductivityChartsIsLoading ? (
                            <JpPieGraph
                                type="donut"
                                fourMaxShoun={true}
                                graphData={Object.values(
                                    filterObject(
                                        getContractorProductivityChartsData,
                                        [
                                            'neutral',
                                            'productive',
                                            'unproductive'
                                        ]
                                    )
                                ).map(item => Number(item.percent_work_time))}
                                graphLabels={Object.keys(
                                    filterObject(
                                        getContractorProductivityChartsData,
                                        [
                                            'neutral',
                                            'productive',
                                            'unproductive'
                                        ]
                                    )
                                )?.map(label =>
                                    t(
                                        `views.productivity.states.label${label.toUpperCase()}`
                                    )
                                )}
                                tooltipFormatter={value => {
                                    return `% ${value}`
                                }}
                                legendPosition="bottom"
                                graphColors={Object.keys(
                                    filterObject(
                                        getContractorProductivityChartsData,
                                        [
                                            'neutral',
                                            'productive',
                                            'unproductive'
                                        ]
                                    )
                                ).map(type => byProductiveColor[type])}
                            />
                        ) : (
                            <SpinnerLoader />
                        )
                    }
                />
            </Col>

            <Col lg="6" className="d-flex flex-column justify-content-between">
                <Row>
                    <Col md="6" className="mb-lg-1 mt-1 mt-lg-0">
                        <JpStatsIconCard
                            loading={getContractorProductivityChartsIsLoading}
                            title={t('views.productivity.productive')}
                            stat={`${parseMinutesToHours(
                                getContractorProductivityChartsData?.productive
                                    ?.time
                            )} (${Math.round(
                                getContractorProductivityChartsData?.productive
                                    ?.percent_work_time
                            )}%)`}
                            statSize="sm"
                            icon={
                                <JpAvatar
                                    icon={
                                        <i
                                            class={
                                                productivityStatesIcons
                                                    .PRODUCTIVE.icon
                                            }
                                        ></i>
                                    }
                                    color={
                                        productivityStatesIcons.PRODUCTIVE.color
                                    }
                                />
                            }
                            iconBgColor={
                                productivityStatesIcons.PRODUCTIVE.color
                            }
                            iconSize="xl"
                            iconDirection="left"
                            subtitle={t('views.time.graphs.lastPeriod')}
                            subtitlePercentage={
                                getContractorProductivityChartsData?.productive
                                    ?.percent_last
                            }
                        />
                    </Col>
                    <Col md="6" className="mb-lg-1 mt-1 mt-lg-0">
                        <JpStatsIconCard
                            swapBgColor={true}
                            title={t('views.productivity.unproductive')}
                            stat={`${parseMinutesToHours(
                                getContractorProductivityChartsData
                                    ?.unproductive?.time
                            )} (${Math.round(
                                getContractorProductivityChartsData
                                    ?.unproductive?.percent_work_time
                            )}%)`}
                            statSize="sm"
                            loading={getContractorProductivityChartsIsLoading}
                            icon={
                                <JpAvatar
                                    icon={
                                        <i
                                            class={
                                                productivityStatesIcons
                                                    .UNPRODUCTIVE.icon
                                            }
                                        ></i>
                                    }
                                    color={
                                        productivityStatesIcons.UNPRODUCTIVE
                                            .color
                                    }
                                />
                            }
                            iconBgColor={
                                productivityStatesIcons.UNPRODUCTIVE.color
                            }
                            iconSize="xl"
                            iconDirection="left"
                            subtitle={t('views.time.graphs.lastPeriod')}
                            subtitlePercentage={
                                getContractorProductivityChartsData
                                    ?.unproductive?.percent_last
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md="6" className="mt-1 mt-lg-0">
                        <JpStatsIconCard
                            loading={getContractorProductivityChartsIsLoading}
                            title={t('views.productivity.neutral')}
                            stat={`${parseMinutesToHours(
                                getContractorProductivityChartsData?.neutral
                                    ?.time
                            )} (${Math.round(
                                getContractorProductivityChartsData?.neutral
                                    ?.percent_work_time
                            )}%)`}
                            statSize="sm"
                            icon={
                                <JpAvatar
                                    icon={
                                        <i
                                            class={
                                                productivityStatesIcons.NEUTRAL
                                                    .icon
                                            }
                                        ></i>
                                    }
                                    color={
                                        productivityStatesIcons.NEUTRAL.color
                                    }
                                />
                            }
                            iconBgColor="light-info"
                            iconSize="xl"
                            iconDirection="left"
                            subtitle={t('views.time.graphs.lastPeriod')}
                            subtitlePercentage={
                                getContractorProductivityChartsData?.neutral
                                    ?.percent_last
                            }
                        />
                    </Col>
                    <Col md="6" className="mt-1 mt-lg-0">
                        <JpStatsIconCard
                            swapBgColor={true}
                            loading={getContractorProductivityChartsIsLoading}
                            title={t('views.productivity.inactivity')}
                            stat={`${parseMinutesToHours(
                                getContractorProductivityChartsData?.afk?.time
                            )}`}
                            statSize="sm"
                            icon={
                                <JpAvatar
                                    icon={
                                        <i class="fa-solid fa-alarm-snooze"></i>
                                    }
                                    color={'yellow'}
                                />
                            }
                            iconBgColor="light-info"
                            iconSize="xl"
                            iconDirection="left"
                            subtitle={t('views.time.graphs.lastPeriod')}
                            subtitlePercentage={
                                getContractorProductivityChartsData?.afk
                                    ?.percent_last
                            }
                        />
                    </Col>
                </Row>
            </Col>

            <Col lg="3" className="mt-1 mt-lg-0">
                {/* <JpCard
                    title={`${t('views.productivity.screens')}`}
                    img={screenshot?.photo}
                    alt={screenshot?.alt}
                    cardFooter={
                        <div className="d-flex justify-content-between my-1 align-items-center">
                            <JpText
                                className="me-75"
                                type="span-table-text"
                                children={screenshot?.alt || 'App'}
                            />
                            <JpButton
                                type="send"
                                text={t('views.productivity.seeAll')}
                                options={{
                                    onClick: () => {
                                        navigate(
                                            `/admin/productivity/details/${contractor_id}?page=4`
                                        )
                                    },
                                    iconless: true
                                }}
                            />
                        </div>
                    }
                /> */}
                <JpAdvanceCard
                    title={t('views.productivity.mostUsedApps')}
                    data={getAppsChartData?.data}
                    loading={getAppsChartIsLoading}
                />
            </Col>
        </Row>
    )
}

export default ProductivityDetailGraphs
