import {
    JpTableActionsCol,
    JpTableV1,
    JpCurrencyNumber,
    JpAvatar,
    JpTableAction,
    JpText,
    JpTableTitleName,
    parseMinutesToHours,
    ContractorNameInfo,
    percentage,
    PROJECTS_STATE_COLORS
} from '@Intelli/utilities'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Badge } from 'reactstrap'
import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import { detailsIcon } from '@src/constants/icons/detailsIcon'
import PersonsProjectsTimeGraphs from './PersonProjectsTimeGraphs'

const PersonProjectsProductivity = () => {
    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState('')
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('start_date__desc')

    const { t } = useTranslation()
    const API = new AdminAPI()
    const navigate = useNavigate()
    const { contractor_id, id } = useParams()

    const {
        data: getPersonProjectsProductivity,
        isLoading: getPersonProjectsProductivityLoading
    } = useQuery(
        ['getPersonProjectsProductivity', currentPage, limit, queries, sortBy],
        () =>
            API.getPersonProjectsProductivity(contractor_id || id, {
                offset: currentPage,
                limit,
                query: queries,
                order: sortBy
            })
    )

    const tabCols = [
        {
            name: '',
            width: '20px',
            cell: row => (
                <div className="d-flex align-items-center">
                    <JpAvatar
                        color={`light-${
                            PROJECTS_STATE_COLORS[row?.contractor_status]
                        }`}
                        content={row?.name}
                        initials
                    />
                </div>
            )
        },
        {
            name: t('views.projects.project'),
            sortable: 'name',
            compact: true,
            minWidth: '250px',
            wrap: true,
            cell: row => (
                <>
                    <ContractorNameInfo
                        classNameContainer="d-flex flex-column user-info text-primary"
                        Tag={Link}
                        customNavigate={`/admin/projects/details/${row?.project_id}`}
                        largeName={row?.name}
                        contractor_id={row.project_id}
                    />
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.time')}
                    tooltipText={t('views.productivity.workedTime')}
                />
            ),
            sortable: 'work_time',
            cell: row => {
                return (
                    <JpText
                        type="small-text"
                        children={`${
                            row?.work_time
                                ? parseMinutesToHours(row?.work_time)
                                : '-'
                        }`}
                    />
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.productive')}
                    tooltipText={t('views.productivity.productiveTime')}
                />
            ),
            sortable: 'productive_time',
            cell: row => {
                return (
                    <>
                        <JpText
                            id={`productive-${row?.contractor_id}`}
                            type="small-text"
                            className="text-green"
                            children={`${percentage(
                                row?.work_time,
                                row?.productive_time
                            )}%`}
                        />
                    </>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.unproductive')}
                    tooltipText={t('views.productivity.unproductiveTime')}
                />
            ),
            sortable: 'unproductive_time',
            cell: row => {
                return (
                    <>
                        <JpText
                            id={`unproductive-${row?.contractor_id}`}
                            type="small-text"
                            className="text-danger"
                            children={`${percentage(
                                row?.work_time,
                                row?.unproductive_time
                            )}%`}
                        />
                    </>
                )
            }
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.neutral')}
                    tooltipText={t('views.productivity.neutralTime')}
                />
            ),
            sortable: 'neutral_time',
            cell: row => {
                return (
                    <>
                        <JpText
                            id={`neutral-${row?.contractor_id}`}
                            type="small-text"
                            className="text-info"
                            children={`${percentage(
                                row?.work_time,
                                row?.neutral_time
                            )}%`}
                        />
                    </>
                )
            }
        },
        {
            name: `${t('views.projects.value_executed')}`,
            sortable: 'budget_executed',
            cell: row => (
                <>
                    {row?.budget_executed ? (
                        <JpCurrencyNumber value={row?.budget_executed} />
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('Status')}
                    tooltipText={t('views.projects.personStatus')}
                />
            ),
            sortable: 'contractor_status',
            cell: row => (
                <>
                    {row?.contractor_status ? (
                        <Badge color={PROJECTS_STATE_COLORS[row?.contractor_status]}>
                            {t(
                                `views.projects.projectStates.${row?.contractor_status}`
                            )}
                        </Badge>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            allowOverflow: true,
            right: true,
            width: '120px',
            cell: row => {
                return (
                    <JpTableActionsCol>
                        <JpTableAction
                            icon={'fa-solid fa-image'}
                            id={`screenshots-${row.project_id}`}
                            tooltipText={t('views.productivity.screenshots')}
                            onClick={() => {}}
                        />
                        <JpTableAction
                            icon={detailsIcon}
                            id={`details-${row.project_id}`}
                            tooltipText={t('views.historicalPayments.details')}
                            onClick={() => {
                                navigate(
                                    `/admin/projects/contractors/${contractor_id}/details/${row.project_id}?page=3`
                                )
                            }}
                        />
                    </JpTableActionsCol>
                )
            }
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    return (
        <>
            <PersonsProjectsTimeGraphs />
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={getPersonProjectsProductivity?.contractors}
                total={getPersonProjectsProductivity?.count}
                loading={getPersonProjectsProductivityLoading}
            />
        </>
    )
}

export default PersonProjectsProductivity
