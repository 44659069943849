const updateChartSocket = (queryClient,queryKey, value) => {
    if (!value.payload.data) {
        return
    }

    queryClient.setQueryData(queryKey, olData => ({
        ...olData,
        ...value.payload.data
    }))
}

export default updateChartSocket