// ** React Imports
import { Fragment, lazy } from 'react'
import { Navigate } from 'react-router-dom'
// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'
// import PrivateRoute from "@src/@core/components/routes/PrivateRoute"
// ** Route Components
import PrivateRoute from '@components/routes/PrivateRoute'

// ** Utils
import { isObjEmpty } from '@utils'
import ContractorProfile from '@src/views/contractors/contractor-detailed/ContractorProfile'
import RenewContract from '@src/views/contractors/contract/RenewContract'
import NewContractV2 from '@src/views/contractors/contract/NewContractV2'

import Error from '@src/views/Error'

//import PayPeriods from '@src/views/settings/pay-periods/PayPeriods'

const getLayout = {
    blank: <BlankLayout />,
    vertical: <VerticalLayout />,
    horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = '%s - Vuexy React Admin Template'

// ** Default Route
const DefaultRoute = '/admin/home'

// Home views
const Home = lazy(() => import('../../views/Home'))
// Recruitment views
const Recruitment = lazy(() => import('../../views/Recruitment'))
const NewRequest = lazy(() => import('../../views/recruitment/NewRequest'))
const RequestSummary = lazy(() =>
    import('../../views/recruitment/RequestSummary')
)
// Contractor views
const Contractors = lazy(() => import('../../views/Contractors'))
const NewContract = lazy(() =>
    import('../../views/contractors/contract/NewContract')
)
const SignContract = lazy(() =>
    import('../../views/contractors/contract/SignContract')
)
// Productivity views
const Productivity = lazy(() => import('../../views/Productivity'))
const DetailedProductivity = lazy(() =>
    import('../../views/productivity/DetailedProductivity')
)
// Time views
const Time = lazy(() => import('../../views/Time'))
const HoursDays = lazy(() => import('../../views/time/HoursDays'))
const HoursDaysDetails = lazy(() => import('../../views/time/HoursDayDetails'))
const DetailedTime = lazy(() => import('../../views/time/DetailedTime'))
const DetailedTimeProductivity = lazy(() =>
    import('../../views/time/DetailedTimeProductivity')
)
const ProductivityVsTimeDays = lazy(() =>
    import('../../views/time/ProductivityVsTimeDays')
)
const PermissionsDetails = lazy(() =>
    import('@src/views/time/PermissionsDetails')
)

// Projects views
const ProjectDetailsTabs = lazy(() =>
    import('@src/views/projects/ProjectDetails/ProjectDetailsTabs')
)
const ProjectsTabs = lazy(() => import('@src/views/ProjectsTabs'))
const TasksDetailsTabs = lazy(() =>
    import('@src/views/projects/TaskDetails/TasksDetailsTabs')
)
const PersonProjectsTabs = lazy(() => import('@src/views/projects/Persons/PersonProjectsTabs'))
const PersonProjectsDetailTabs = lazy(() => import('@src/views/projects/Persons/ProjectDetails/PersonProjectsDetailTabs'))
const PersonProjectTaskTab = lazy(() => import('@src/views/projects/Persons/ProjectTaskDetails/PersonProjectTaskTab'))
const PersonDetailDaysTabs = lazy(() => import('@src/views/projects/Persons/ProjectTaskDetails/TaskDetailDays/PersonTaskDetailDaysTabs'))

// Invoices views
const Invoices = lazy(() => import('../../views/Invoices/Invoices'))
const InvoicesForPay = lazy(() => import('../../views/Invoices/InvoicesForPay'))
// Reports views
const Reports = lazy(() => import('../../views/Reports'))
// Settings views
const Settings = lazy(() => import('../../views/Settings'))
const Teams = lazy(() => import('../../views/settings/teams/Teams'))
const CreateTeam = lazy(() => import('../../views/settings/teams/CreateTeam'))
const EditTeam = lazy(() => import('../../views/settings/teams/EditTeam'))
const EditPermissions = lazy(() =>
    import('../../views/settings/EditPermissions')
)
const UserTabs = lazy(() => import('@src/views/settings/users/UserTabs'))

const SendInvitation = lazy(() => import('../../views/settings/SendInvitation'))
const AdminProfile = lazy(() =>
    import('../../views/settings/admin-detailed/AdminProfile')
)
const Contracts = lazy(() => import('../../views/settings/contracts/Contracts'))
const ContractHistory = lazy(() =>
    import('@src/views/contractors/contractor-detailed/ContractHistory')
)
const CreateTemplate = lazy(() =>
    import('../../views/settings/contracts/CreateTemplate')
)
const EditTemplate = lazy(() =>
    import('../../views/settings/contracts/EditTemplate')
)
const ProductivitySettings = lazy(() =>
    import('../../views/settings/productivity/ProductivitySettings')
)
const CreateProductivityProfile = lazy(() =>
    import('../../views/settings/productivity/CreateProductivityProfile')
)
//Profile views
const UserProfile = lazy(() => import('../../views/UserProfile'))
// Template default views
const SecondPage = lazy(() => import('../../views/SecondPage'))
// Contractor Profile
const PreviewDocument = lazy(() =>
    import('@src/views/contractors/contractor-detailed/PreviewDocument')
)
const PreviewContract = lazy(() =>
    import('@src/views/contractors/contractor-detailed/PreviewContract')
)
const Calendar = lazy(() => import('@src/views/settings/Calendar'))

//Payment view
const Payments = lazy(() => import('../../views/Payrolls'))
/* const PayDetail = lazy(() => import('../../views/payrollDetail/DetailLogic')) */
import HistoricalDetails from '../../views/payrolls/HistoricalDetails'
import InvoicesDetail from '@src/views/Invoices/InvoicesDetail'
import ProductivityDetails from '@src/views/ProductivityDetails'
import TimesDetailsContractor from '@src/views/TimesDetailsContractor'


const EditCompanyData = lazy(() =>
    import('@src/views/settings/company/EditCompanyLogic')
)
const PreviewContractPdf = lazy(() =>
    import('@src/views/contractors/contractor-detailed/PreviewContractPdf')
)

const Invitation = lazy(() =>
    import('@src/views/contractors/invitation/Invitation')
)

const CompleteInvitation = lazy(() =>
    import('@src/views/contractors/invitation/CompleteInvitation')
)

const HistoricalTransactions = lazy(() =>
    import('../../views/payrolls/HistoricalTransactions')
)
const PayrollDetailsContractor = lazy(() =>
    import('@src/views/payrolls/PayrollDetailsContractor')
)

const PayPeriods = lazy(() =>
    import('@src/views/settings/pay-periods/PayPeriods')
)
const ContractTypesLogic = lazy(() =>
    import('../../views/settings/type-contract/ContractTypesLogic')
)
const Historical = lazy(() =>
    import('../../views/settings/type-contract/ContractTypesLogic')
)

const PayDetailList = lazy(() =>
    import('../../views/payrollDetailList/DetailLogic')
)
const PaymentsApproved = lazy(() =>
    import('../../views/payrolls/PaymentsApproved')
)
const StripeView = lazy(() => import('../../views/payrolls/StripeView'))
const PaymentSuccessed = lazy(() =>
    import('../../views/payrolls/payment/PaymentSuccessed')
)
// PrevDocuments
const PrevDoc = lazy(() => import('../../components/PrevDoc'))

// ** Merge Routes
const Routes = [
    {
        path: '*',
        element: <Error />,
        meta: {
            layout: 'blank'
        }
    },
    {
        path: '/admin/payrolls/historics/details/:id/transaction/:transaction_payroll_id',
        element: <PayrollDetailsContractor />
    },
    {
        path: '/admin/payrolls/historics/details/:id/logs',
        element: <HistoricalTransactions />
    },
    {
        path: '/admin/payrolls/historics/details/:id',
        element: <HistoricalDetails />
    },
    {
        path: '/admin',
        index: true,
        element: <Navigate replace to={DefaultRoute} />
    },
    {
        path: '/admin/home',
        element: <Home />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/recruitment',
        element: <Recruitment />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/recruitment/new_request',
        element: <NewRequest />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/recruitment/request_summary/:request_id',
        element: <RequestSummary />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/contractors',
        element: <Contractors />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/pay-periods',
        element: <PayPeriods />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/type-contract',
        element: <ContractTypesLogic />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/historical-payments',
        element: <Historical />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/contractors/profile/:id',
        element: <ContractorProfile />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/contractors/profile/:id/document-preview',
        element: <PreviewDocument />
    },
    {
        path: '/admin/contractors/profile/contract-preview/:id',
        element: <PreviewContract />
    },
    {
        path: '/admin/contractors/profile/contract-preview/pdf/:id',
        element: <PreviewContractPdf />
    },
    {
        path: '/admin/contractors/sign/:id',
        element: <SignContract />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/contractors/sign/email',
        element: <SignContract />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/contractors/invitation',
        element: <Invitation />
    },
    {
        path: '/admin/contractors/invitation/complete/:id',
        element: <CompleteInvitation />
    },
    {
        path: '/admin/contractors/new-contract/:id',
        element: <NewContractV2 />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/contractors/renew-contract/:id',
        element: <RenewContract />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/productivity',
        element: <Productivity />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/productivity/:productivity_detailed_id',
        element: <DetailedProductivity />
    },
    {
        path: '/admin/time',
        element: <Time />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/time/time_detailed/:time_detailed_id',
        element: <DetailedTime />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/projects',
        element: <ProjectsTabs />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/projects/contractors/:contractor_id',
        element: < PersonProjectsTabs/>
    },
    {
        path: '/admin/projects/contractors/:contractor_id/details/:id',
        element: < PersonProjectsDetailTabs/>
    },
    {
        path: '/admin/projects/contractors/:contractor_id/details/:id/tasks/:task_id',
        element: < PersonProjectTaskTab/>
    },
    {
        path: '/admin/projects/contractors/:contractor_id/details/:id/tasks/:task_id/days',
        element: < PersonDetailDaysTabs/>
    },
    {
        path: '/admin/projects/details/:id',
        element: <ProjectDetailsTabs />
    },
    {
        path: '/admin/projects/details/:id/tasks/:task_id',
        element: <TasksDetailsTabs />
    },
    {
        path: '/admin/reports',
        element: <Reports />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings',
        element: <Settings />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/teams',
        element: <Teams />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/teams/create',
        element: <CreateTeam />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/teams/edit/:id',
        element: <EditTeam />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/users',
        /* <AssignedRoles /> */
        element: <UserTabs />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/users/profile/:id',
        element: <AdminProfile />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/send-invite',
        element: <SendInvitation />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/users/permissions/:id',
        element: <EditPermissions />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/contracts',
        element: <Contracts />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/contracts/create',
        element: <CreateTemplate />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/settings/contracts/edit/:id',
        element: <EditTemplate />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/second-page',
        element: <SecondPage />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/account-settings',
        element: <UserProfile />
    },
    {
        path: '/admin/account-settings/prevdoc',
        element: <PrevDoc />
    },
    {
        path: '/admin/payrolls',
        element: <Payments />
    },
    {
        path: '/admin/payrolls/payment_detail/:id',
        element: <PayDetailList />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/payrolls/approved_payrolls',
        element: <PaymentsApproved />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/payrolls/stripe_view/:id',
        element: <StripeView />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/invoices/pay',
        element: <StripeView />
    },
    {
        path: '/admin/payrolls/pay',
        element: <StripeView />
    },
    {
        path: '/admin/payrolls/pay/success',
        element: <PaymentSuccessed />
    },
    {
        path: '/admin/settings/company',
        element: <EditCompanyData />
    },
    {
        path: '/admin/invoices',
        element: <Invoices />,
        meta: {
            restricted: true
        }
    },
    {
        path: '/admin/invoices/invoices-detail/:id',
        element: <InvoicesDetail />
    },
    {
        path: '/admin/invoices/invoices-detail/:id/payable',
        element: <InvoicesForPay />
    },
    {
        path: '/admin/settings/productivity',
        element: <ProductivitySettings />
    },
    {
        path: '/admin/settings/productivity/profile/create/:id',
        element: <CreateProductivityProfile />
    },
    {
        path: '/admin/settings/productivity/edit/:id',
        element: <CreateProductivityProfile />
    },
    {
        path: '/admin/productivity/details/:id',
        element: <ProductivityDetails />
    },
    {
        path: '/admin/time/hours/:id/days',
        element: <TimesDetailsContractor />
    },
    {
        path: '/admin/time/hours/:id/days/details',
        element: <HoursDaysDetails />
    },
    {
        path: '/admin/time/productivity/:id/days/details',
        element: <DetailedTimeProductivity />
    },
    {
        path: '/admin/time/productivity/:id/days',
        element: <TimesDetailsContractor />
    },
    {
        path: '/admin/settings/times',
        element: <Calendar />
    },
    {
        path: '/admin/time/permissions/details/:id',
        element: <TimesDetailsContractor />
    },
    {
        path: '/admin/contractors/contractHistory/:id',
        element: <ContractHistory />
    }
]

const getRouteMeta = route => {
    if (isObjEmpty(route.element.props)) {
        if (route.meta) {
            return { routeMeta: route.meta }
        } else {
            return {}
        }
    }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
    const LayoutRoutes = []

    if (Routes) {
        Routes.filter(route => {
            let isBlank = false
            // ** Checks if Route layout or Default layout matches current layout
            if (
                (route.meta &&
                    route.meta.layout &&
                    route.meta.layout === layout) ||
                ((route.meta === undefined ||
                    route.meta.layout === undefined) &&
                    defaultLayout === layout)
            ) {
                const RouteTag = PrivateRoute

                // ** Check for public or private route
                if (route.meta) {
                    route.meta.layout === 'blank'
                        ? (isBlank = true)
                        : (isBlank = false)
                }
                if (route.element) {
                    const Wrapper =
                        // eslint-disable-next-line multiline-ternary
                        isObjEmpty(route.element.props) && isBlank === false
                            ? // eslint-disable-next-line multiline-ternary
                              LayoutWrapper
                            : Fragment

                    route.element = (
                        <Wrapper
                            {...(isBlank === false ? getRouteMeta(route) : {})}
                        >
                            <RouteTag route={route}>{route.element}</RouteTag>
                        </Wrapper>
                    )
                }

                // Push route to LayoutRoutes
                LayoutRoutes.push(route)
            }
            return LayoutRoutes
        })
    }
    return LayoutRoutes
}

const getRoutes = layout => {
    const defaultLayout = layout || 'vertical'
    const layouts = ['vertical', 'horizontal', 'blank']

    const AllRoutes = []

    layouts.forEach(layoutItem => {
        const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

        AllRoutes.push({
            path: '/',
            element: getLayout[layoutItem] || getLayout[defaultLayout],
            children: LayoutRoutes
        })
    })
    return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
