import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    JpAvatar,
    dateLocalizer,
    JpCurrencyNumber,
    JpTableAction,
    JpTableV1,
    JpTableTitleName
} from '@Intelli/utilities'
import AdminAPI from '@src/services/API'
import { downloadFile } from '@src/utility/Files'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

const PayrollTab = () => {
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [queries, setQueries] = useState('')
    const [sortBy, setSortBy] = useState('payment_date__desc')
    const { id } = useParams()
    const { t } = useTranslation()
    const API = new AdminAPI()

    const {
        data: dataPayroll,
        isSuccess: dataPayrollIsSuccess,
        isLoading: dataPayrollIsLoading
    } = useQuery(['payroll', id, currentPage, limit, queries, sortBy], () =>
        API.getContractorPayroll(
            id,{
            offset: currentPage,
            limit,
            query: queries,
            order: sortBy
        })
    )

    const tabCols = [
        {
            name: '',
            sortable: row => row.transaction.name,
            width: '20px',
            cell: row => (
                <div className="d-flex align-items-center">
                    <JpAvatar
                        color={`light-info`}
                        icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    />
                </div>
            )
        },
        {
            name: t('views.profile.payments.table.transaction'),
            sortable: 'transaction_name',
            cell: row => `#${row.transaction.name}`
        },
        {
            name: t('views.profile.payments.table.paymentDate'),
            sortable: 'payment_date',
            cell: row =>
                !!row.payment_date &&
                dateLocalizer.getIntlLongDate(row.payment_date)
        },
        {
            name: t('views.profile.payments.table.paymentPeriod'),
            sortable: 'start_date',
            cell: row =>
                !!row.start_date &&
                `${dateLocalizer.getIntlShortDate(
                    row.start_date
                )} - ${dateLocalizer.getIntlShortDate(row.end_date)}`
        },
        {
            name: t('views.profile.payments.table.value'),
            sortable: 'total_balance',
            cell: row => <JpCurrencyNumber value={row.total_balance} />
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            allowOverflow: true,
            right: true,
            grow: 1,
            minWidth: '120px',
            cell: row => (
                <div className="d-flex">
                    {!!row.payment_receipt_media && (
                        <JpTableAction
                            icon={faFileInvoiceDollar}
                            id={`download-${row.transaction.name}`}
                            tooltipText={t('Download')}
                            onClick={() =>
                                downloadFile(
                                    row.payment_receipt_media.location,
                                    row.transaction.name
                                )
                            }
                        />
                    )}
                </div>
            )
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    return (
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={dataPayroll?.data}
                total={dataPayroll?.count}
                loading={dataPayrollIsLoading}
                actions={<></>}
                title={t('views.profile.payments.paymentsInfo')}
            />
    )
}

export default PayrollTab
