import { Badge } from 'reactstrap'
import { faFileArrowDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    JpButton,
    JpText,
    dateLocalizer,
    SweetModal,
    JpTable,
    JpAvatar,
    JpTableAction,
    JpTableTitleName,
    BlueHeaderCard,
    JpCountryFlag,
    JpCurrencyNumber,
    HasPermissionModules,
    JpTableV1,
    ContractorNameInfo
} from '@Intelli/utilities'
import { parseName } from '@src/utility/Utils'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { faFile } from '@fortawesome/pro-regular-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { addParamsValues } from '@src/redux/breadcrumbs'
import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import DetailsPayrolls from '@src/components/payroll/historics/DetailsPayrolls'
import { downloadFile } from '../../utility/Files'
import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'
import { detailsIcon } from '@src/constants/icons/detailsIcon'
import useSocket from '@src/hooks/useSocket'

const HistoricalDetails = () => {
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const [queries, setQueries] = useState([])
    const [limite, setLimit] = useState(10)
    const [hasPermissionContract, setHasPermissionContract] = useState(false)
    const [sortBy, setSortBy] = useState('payment_date__desc')
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const API = new AdminAPI()
    const { id } = useParams()
    const navigate = useNavigate()
    const statePayroll = useSelector(state => state.getStatePayroll)
    const { modules } = useApplicationModules()

    useEffect(() => {
        setHasPermissionContract(
            HasPermissionModules.Read(modules, [
                'CONTRACTS',
                'CONTRACTS-CONTRACTORS'
            ])
        )
        dispatch(
            addParamsValues({
                id: {
                    value: id,
                    label: `#${id.substring(0, 8)}`
                }
            })
        )
    }, [])

    const {
        data: getPayrollsDetails,
        isError: getPayrollsDetailsHasError,
        isLoading: getPayrollsDetailsIsLoading,
        refetch: getPayrollsDetailsRefetch
    } = useQuery(
        [
            'getPayrollsDetails',
            id,
            currentPage,
            limite,
            queries,
            sortBy,
            shouldUpdate
        ],
        () =>
            API.getPayrollsDetails({
                id: id,
                offset: currentPage,
                limit: limite,
                query: queries,
                order: sortBy
            }),
        { keepPreviousData: true }
    )

    useSocket({
        [`/payroll/transactions/${id}/payrolls`]: {
            fn: value => {
                getPayrollsDetailsRefetch()
            }
        }
    })

    const {
        data: getPayrollsTransaction,
        isError: getPayrollsTransactionHasError,
        isLoading: getPayrollsTransactionIsLoading
    } = useQuery(['getPayrollsTransaction', currentPage, limite, sortBy], () =>
        API.getPayrollsTransaction(id, {
            offset: currentPage,
            limit: limite,
            order: sortBy
        })
    )

    const tabCols = [
        {
            id: 'avatar_column',
            name: '',
            sortable: false,
            width: '40px',
            cell: row => (
                <div className="d-flex justify-content-center align-items-center ms-1">
                    {row?.user_photo ? (
                        <JpAvatar
                            color={`light-info`}
                            img={row?.user_photo?.photo}
                            imgClassName="avatar-cover"
                        />
                    ) : (
                        <JpAvatar
                            color={`light-info`}
                            content={
                                parseName(row.first_name, row.last_name)
                                /* row.first_name ? `${row.first_name}` : `${row.last_name}` */
                            }
                            initials
                        />
                    )}
                </div>
            )
        },
        {
            name: t('views.historicalPayments.contractor'),
            minWidth: '150px',
            sortable: 'first_name',
            cell: row => {
                return (
                    <ContractorNameInfo
                        classNameContainer="d-flex flex-column"
                        Tag={hasPermissionContract ? Link : 'div'}
                        first_name={row?.first_name}
                        last_name={row?.last_name}
                        contractor_id={row.contractor_id}
                        smallContent={row?.position}
                    />
                )
            }
        },
        {
            name: t('views.historicalPayments.period'),
            minWidth: '200px',
            sortable: 'payment_date',
            cell: row => (
                <div className="d-flex align-items-center">
                    <div className="text-truncate ">
                        {row?.is_manual ? (
                            <JpText>
                                {dateLocalizer.getIntlLongDate(
                                    row?.payment_date
                                )}
                            </JpText>
                        ) : (
                            <JpText type="span-table-text">
                                {row?.start_date
                                    ? `${dateLocalizer.getIntlLongDate(
                                          row?.start_date
                                      )}-${dateLocalizer.getIntlLongDate(
                                          row?.end_date
                                      )}`
                                    : '-'}
                            </JpText>
                        )}
                    </div>
                </div>
            )
        },
        {
            name: t('views.historicalPayments.mount'),
            minWidth: '100px',
            sortable: 'total_balance',
            cell: row =>
                row.total_balance && (
                    <div className="d-flex align-items-center justify-content-center">
                        <JpCurrencyNumber value={row?.total_balance} />
                    </div>
                )
        },
        {
            name: `${t('COUNTRY')}`,
            sortable: 'country',
            minWidth: '150px',
            cell: row => (
                <>
                    {row.country ? (
                        <div>
                            <JpCountryFlag
                                countryCode={row?.country}
                                id={row?.user_id}
                            />
                        </div>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: `${t('CONTRACT_TYPE')}`,
            minWidth: '200px',
            sortable: 'contract_type',
            cell: row => (
                <>
                    <Badge color={`light-${row?.contract_type_color}`}>
                        {t(`views.contractType.${row?.contract_type}`)}
                    </Badge>
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    customClass="me-1"
                    text={t('views.documents.contractsTable.actions')}
                />
            ),
            grow: '<int de 1-n>',
            minWidth: '120px',
            right: true,
            allowOverflow: true,
            cell: row => {
                return (
                    <>
                        <JpTableAction
                            icon={detailsIcon}
                            id={`details-${row.transaction_payroll_id}`}
                            tooltipText={t('views.historicalPayments.details')}
                            onClick={() => {
                                navigate(
                                    `transaction/${row?.transaction_payroll_id}`
                                )
                            }}
                        />

                        {!!row?.payment_receipt_media?.location && (
                            <JpTableAction
                                icon={faFileArrowDown}
                                id={`download-${row.id}`}
                                tooltipText={t(
                                    'views.documents.documentsTable.downloadButton'
                                )}
                                onClick={() => {
                                    downloadFile(
                                        row?.payment_receipt_media.location,
                                        row?.payment_receipt_media.name
                                    )
                                    SweetModal(
                                        'success',
                                        t('Success'),
                                        '',
                                        t('Ok')
                                    )
                                }}
                            />
                        )}
                    </>
                )
            }
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    const TabAction = () => {
        return (
            <div className="d-flex justify-content-end">
                <JpButton
                    text={`${t('views.historicalPayments.details')}`}
                    type="send"
                    color="info"
                    options={{
                        onClick: () => {
                            navigate('logs')
                        },
                        iconPosition: 'left',
                        customIcon: (
                            <FontAwesomeIcon className="me-1" icon={faFile} />
                        )
                    }}
                />
            </div>
        )
    }

    const downloadShow = ['VOIDED', 'PENDING']

    /* if (getPayrollsTransactionIsLoading)
        return <SpinnerLoader /> */

    return (
        <div>
            <BlueHeaderCard
                headerContent={
                    <h4 className="text-white mb-0">
                        {t('views.historicalPayments.transactionInfo')}
                    </h4>
                }
                bodyContent={<DetailsPayrolls />}
            />

            <div className=" mt-2">
                <JpTableV1
                    cols={tabCols}
                    fetcher={handlePageChange}
                    data={getPayrollsDetails?.data}
                    total={getPayrollsDetails?.count}
                    loading={getPayrollsDetailsIsLoading}
                    actions={
                        downloadShow.includes(statePayroll) ? null : (
                            <TabAction />
                        )
                    }
                />
            </div>
        </div>
    )
}

export default HistoricalDetails
