// ** Custom Components
import CustomNavbarUser from './CustomNavbarUser'

// ** Reactstrap Imports
import { NavItem, NavLink, UncontrolledTooltip } from 'reactstrap'

import { Fragment } from 'react'

// ** Third Party Components
import { Menu } from 'react-feather'
import BreadcrumbsBar from './BreadcrumbsBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom'
import { useApplicationModules } from '@src/utility/hooks/useApplicationModules'
import { HOME_PATH } from '@src/configs/environment'
import { useTranslation } from 'react-i18next'

const CustomNavbar = ({ setMenuVisibility }) => {
    const { path = [] } = useApplicationModules()
    const { pathname: realPath } = useLocation()
    const pathlist = path.map(p => p.navLink)
    const { t } = useTranslation()

    const isBasePath = realPath === pathlist[0] && pathlist.length === 1

    /* Created to navigate to payroll if you are in payroll detail */
    const customNavigation = realPath.includes('payment_detail') ? '/admin/payrolls' : -1

    return (
        <Fragment>
            <div className="bookmark-wrapper d-flex align-items-center">
                <ul className="navbar-nav d-xl-none">
                    <NavItem className="mobile-menu me-auto">
                        <NavLink
                            className="nav-menu-main menu-toggle hidden-xs is-active"
                            onClick={() => setMenuVisibility(true)}
                        >
                            <Menu className="ficon" />
                        </NavLink>
                    </NavItem>
                </ul>
            </div>
            {!isBasePath && realPath !== HOME_PATH && (
                <>
                    <Link to={customNavigation}>
                        <FontAwesomeIcon
                            id="arrow"
                            icon={faAngleLeft}
                            fontSize="35"
                            className="me-1"
                        />
                    </Link>
                    <UncontrolledTooltip
                        placement="bottom"
                        target="arrow"
                        className="z-index-1"
                    >
                        {t('back')}
                    </UncontrolledTooltip>
                </>
            )}
            <BreadcrumbsBar className={isBasePath ? 'ms-75' : ''} />
            <CustomNavbarUser />
        </Fragment>
    )
}

export default CustomNavbar
