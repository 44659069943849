import { useState } from 'react'
import { HasPermissionModules } from '@Intelli/utilities'
import { useApplicationModules } from './ApplicationModules.hook'


const usePermissionTimePermissions = () => {

    const { modules } = useApplicationModules()

    const [hasHours, setHasHours] = useState(
        HasPermissionModules.Read(modules, ['TIME', 'TIME-HOURS'])
    )

    const [hasHoursWrite, setHasHoursWrite] = useState(
        HasPermissionModules.WriteUpdate(modules, ['TIME', 'TIME-HOURS'])
    )

    const [hasTimeProductivity, setHasTimeProductivity] = useState(
        HasPermissionModules.Read(modules, ['TIME', 'TIME-PRODUCTIVITY'])
    )

    const [hasTimePermissions, setHasTimePermissions] = useState(
        HasPermissionModules.Read(modules, ['TIME', 'TIME-PERMISSIONS'])
    )

    const [hasWriteTimePermissions, setHasWriteTimePermissions] = useState(
        HasPermissionModules.WriteUpdate(modules, ['TIME', 'TIME-PERMISSIONS'])
    )
  return {
       hasHours, 
       hasHoursWrite,
       hasTimeProductivity,
       hasTimePermissions,
       hasWriteTimePermissions
    }
  
}

export default usePermissionTimePermissions