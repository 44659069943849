// ** React Imports
import { useEffect, useState } from 'react'
import {
    Outlet,
    Link,
    useLocation,
    useMatch,
    useOutlet
} from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'

import Avatar from '@components/avatar'
import UserDropdown from '../@core/layouts/components/navbar/UserDropdown'
import NotificationDropdown from '../@core/layouts/components/navbar/NotificationDropdown'
import BreadCrumbs from '@components/breadcrumbs'
import IntlDropdown from '../@core/layouts/components/navbar/IntlDropdown'
import CustomUserDropdown from '../components/navbar/CustomUserDropdown'
import { Settings, Menu } from 'react-feather'

// ** Reactstrap Imports
import { NavItem, NavLink, UncontrolledTooltip, Row, Col } from 'reactstrap'

import toast from 'react-hot-toast'

// ** Third Party Components
import { useTranslation } from 'react-i18next'
import CustomNavbar from '../components/navbar/CustomNavbar'
import AdminAPI from '@src/services/API'
import { useQuery } from '@tanstack/react-query'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChartSimple, faHome, fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'

// Hooks
import { useApplicationModules } from '@src/utility/hooks/useApplicationModules'
import { HOME_PATH } from '@src/configs/environment'

library.add(fas, far)

const CustomNavbar2 = props => {
    const { setMenuVisibility } = props
    /* console.log(props) */

    const urlParams = [
        {
            param: ':productivity_detailed_id',
            fetchUrl: 'api.example.com/productivity_detailed/'
        },
        {
            param: ':request_id',
            fetchUrl: 'api.example.com/request/'
        },
        {
            param: ':timeproductivity_detailed_id',
            fetchUrl: 'api.example.com/timeproductivity_detailed/'
        },
        {
            param: ':time_detailed_id',
            fetchUrl: 'api.example.com/time_detailed/'
        }
    ]

    const location = useLocation()
    const match = useMatch(location.pathname)
    const currPath =
        useOutlet().props.children.props.children.props.children.props.route
            .path
    const paths = currPath.split('/')
    const breadcrumbs = []
    let page_title = ''
    //console.log('loc')
    //console.log(location)
    /* console.log('match')
  console.log(match) */
    //console.log(useMatch({path: '/productivity', end: false }, location.pathname))
    //console.log(useOutlet())
    //console.log(useOutlet().props.children.props.children.props.children.props.route.path)
    /* console.log('CP')
  console.log(currPath.split('/')) */

    paths.map((path, index) => {
        /* console.log(path)
    console.log('path found?')
    console.log(urlParams.find(x => x.param === path)) */
        if (path.length && path !== 'home' && path[0] !== ':') {
            /* console.log(path) */

            if (index !== paths.length - 1) {
                breadcrumbs.push({
                    title:
                        path[0].toUpperCase() +
                        path.slice(1).replaceAll('_', ' '),
                    link: `/${path}`
                })
            } else {
                breadcrumbs.push({
                    title:
                        path[0].toUpperCase() +
                        path.slice(1).replaceAll('_', ' ')
                })
                page_title =
                    path[0].toUpperCase() + path.slice(1).replaceAll('_', ' ')
            }
        } else if (
            path.length &&
            path[0] === ':' &&
            urlParams.find(x => x.param === path)
        ) {
            const data = {
                productivity_detailed_id: [
                    {
                        id: '1',
                        name: 'John Doe',
                        email: 'smol@ame.com',
                        post: 'Senior Software Engineer',
                        age: '30'
                    },
                    {
                        id: '2',
                        name: 'Jane Doe',
                        email: 'smol@ame.com',
                        post: 'Senior Software Engineer',
                        age: '30'
                    },
                    {
                        id: '3',
                        name: 'Jim Doe',
                        email: 'smol@ame.com',
                        post: 'Senior Software Engineer',
                        age: '30'
                    },
                    {
                        id: '4',
                        name: 'Jill Doe',
                        email: 'smol@ame.com',
                        post: 'Senior Software Engineer',
                        age: '30'
                    }
                ],
                request_id: [
                    {
                        responsive_id: '',
                        id: '1',
                        name: 'Python Developer',
                        status: 'Completed',
                        status_color: 'success',
                        candidates: [
                            {
                                title: 'Vinnie Mostowy',
                                img: require(`@src/assets/images/portrait/small/avatar-s-1.jpg`)
                                    .default
                            },
                            {
                                title: 'Elicia Rieske',
                                img: require('@src/assets/images/portrait/small/avatar-s-2.jpg')
                                    .default
                            },
                            {
                                title: 'Julee Rossignol',
                                img: require('@src/assets/images/portrait/small/avatar-s-3.jpg')
                                    .default
                            },
                            {
                                title: 'Darcey Nooner',
                                img: require('@src/assets/images/portrait/small/avatar-s-4.jpg')
                                    .default
                            },
                            {
                                title: 'Jenny Looper',
                                img: require('@src/assets/images/portrait/small/avatar-s-5.jpg')
                                    .default
                            }
                        ],
                        date: '10/05/2022'
                    },
                    {
                        responsive_id: '',
                        id: '2',
                        name: 'Oracle DBA',
                        status: 'In Proccess',
                        status_color: 'warning',
                        candidates: [
                            {
                                title: 'Vinnie Mostowy',
                                img: require(`@src/assets/images/portrait/small/avatar-s-1.jpg`)
                                    .default
                            },
                            {
                                title: 'Elicia Rieske',
                                img: require('@src/assets/images/portrait/small/avatar-s-2.jpg')
                                    .default
                            },
                            {
                                title: 'Julee Rossignol',
                                img: require('@src/assets/images/portrait/small/avatar-s-3.jpg')
                                    .default
                            },
                            {
                                title: 'Darcey Nooner',
                                img: require('@src/assets/images/portrait/small/avatar-s-4.jpg')
                                    .default
                            },
                            {
                                title: 'Jenny Looper',
                                img: require('@src/assets/images/portrait/small/avatar-s-5.jpg')
                                    .default
                            }
                        ],
                        date: '16/08/2022'
                    },
                    {
                        responsive_id: '',
                        id: '3',
                        name: 'Senior Frontend Developer',
                        status: 'Inactive',
                        status_color: 'danger',
                        candidates: [
                            {
                                title: 'Zoe Mostowy',
                                img: require(`@src/assets/images/portrait/small/avatar-s-1.jpg`)
                                    .default
                            },
                            {
                                title: 'Elicia Rieske',
                                img: require('@src/assets/images/portrait/small/avatar-s-2.jpg')
                                    .default
                            },
                            {
                                title: 'Julee Rossignol',
                                img: require('@src/assets/images/portrait/small/avatar-s-3.jpg')
                                    .default
                            },
                            {
                                title: 'Darcey Nooner',
                                img: require('@src/assets/images/portrait/small/avatar-s-4.jpg')
                                    .default
                            },
                            {
                                title: 'Jenny Looper',
                                img: require('@src/assets/images/portrait/small/avatar-s-5.jpg')
                                    .default
                            }
                        ],
                        date: '22/07/2022'
                    }
                ],
                timeproductivity_detailed_id: [
                    {
                        responsive_id: '',
                        id: '1',
                        avatar: '10.jpg',
                        name: 'John Doe',
                        role: 'Admin',
                        first_checkin: '10:00',
                        last_checkout: '18:00',
                        worked_hours: '8h00m',
                        amount_to_pay: '$2000 USD'
                    },
                    {
                        responsive_id: '',
                        id: '2',
                        avatar: '11.jpg',
                        name: 'John Doe',
                        role: 'Admin',
                        first_checkin: '10:00',
                        last_checkout: '18:00',
                        worked_hours: '8h00m',
                        amount_to_pay: '$2000 USD'
                    },
                    {
                        responsive_id: '',
                        id: '3',
                        avatar: '12.jpg',
                        name: 'John Doe',
                        role: 'Admin',
                        first_checkin: '10:00',
                        last_checkout: '18:00',
                        worked_hours: '8h00m',
                        amount_to_pay: '$2000 USD'
                    }
                ],
                time_detailed_id: [
                    {
                        responsive_id: '',
                        id: '1',
                        avatar: '12.jpg',
                        name: 'John Doe',
                        role: 'Admin',
                        first_checkin: '10:00',
                        last_checkout: '18:00',
                        worked_hours: '8h00m',
                        unproductive_time: '8h00m',
                        inactive_time: '8h00m',
                        effective_time: '8h00m',
                        amount_to_pay: '$2000 USD'
                    }
                ]
            }

            const matchedPath = match.pathname.split('/')
            const param_value = matchedPath[index]
            const name = data[path.replaceAll(':', '')]
                .find(x => x.id === param_value)
                .name.replaceAll('_', ' ')

            if (index !== paths.length - 1) {
                breadcrumbs.push({ title: name, link: `/${param_value}` })
            } else {
                breadcrumbs.push({ title: name })
                page_title = name.replaceAll('_', ' ')
            }
        }
    })

    useEffect(() => {
        toast(`current page ${page_title}`)
    }, [page_title])

    return (
        <>
            <Row className="align-items-center">
                <Col lg="9" xs="6">
                    <ul className="navbar-nav d-xl-none">
                        <NavItem className="mobile-menu me-auto">
                            <NavLink
                                className="nav-menu-main menu-toggle hidden-xs is-active"
                                onClick={() => setMenuVisibility(true)}
                            >
                                <Menu className="ficon text-primary" />
                            </NavLink>
                        </NavItem>
                    </ul>
                    {/*}
          <div className='d-none d-lg-block'>
            {breadcrumbs.length ? <BreadCrumbs data={ breadcrumbs } title={ page_title } /> : <></>}
          </div>
          */}
                </Col>
                <Col lg="3" xs="6">
                    <ul className="nav navbar-nav align-items-center justify-content-end">
                        <IntlDropdown />
                        <NavItem className="d-none d-lg-block">
                            <NavLink
                                tag={Link}
                                to="/second-page"
                                id="settings-btn"
                            >
                                <Settings className="ficon" />
                                <UncontrolledTooltip target="settings-btn">
                                    Settings
                                </UncontrolledTooltip>
                            </NavLink>
                        </NavItem>
                        <NotificationDropdown />
                        <CustomUserDropdown />
                    </ul>
                </Col>
            </Row>
        </>
    )
}

const CustomFooter = () => {
    // ** Hooks
    const { t } = useTranslation()
    return (
        <div className="d-flex justify-content-between">
            <small>
                {t('Footer-CopyTextOne')} {new Date().getFullYear()}
                {t('Footer-CopyTextTwo')}
            </small>
            {/* <small className="footer-text">{t('Footer-CopyTextThree')}</small> */}
            {/*       <small>{t('Footer-MadeBy')}</small> */}
        </div>
    )
}

const VerticalLayout = props => {
    const API = new AdminAPI()
    const {
        data: menuData,
        isError: menuHasError,
        isLoading: menuIsLoading
    } = useQuery(['menu'], () => API.getMenu())

    //Hook for modules data storage
    const { setApplicationsModules } = useApplicationModules()

    const { t } = useTranslation()

    // const [menuData, setMenuData] = useState([])
    const [menu, setMenu] = useState([])

    // ** For ServerSide navigation
    useEffect(() => {
        if (!menuIsLoading && !menuHasError) {
            const navigation = [
                {
                    id: 'home',
                    title: `HOME`,
                    order: 0,
                    icon: <FontAwesomeIcon icon={faChartSimple} />,
                    navLink: HOME_PATH
                }
            ]
            for (const module of menuData.data) {
                const moduleMetadata = {}
                if (module?.metadata) {
                    for (const metadata of module?.metadata) {
                        moduleMetadata[metadata?.metadata] = metadata.value
                    }
                }
                navigation.push({
                    id: module.name,
                    title: module.name,
                    order: module.order,
                    icon: moduleMetadata?.icon ? (
                        <FontAwesomeIcon icon={moduleMetadata?.icon} />
                    ) : (
                        <FontAwesomeIcon icon={faHome} />
                    ),
                    navLink: moduleMetadata?.navLink
                        ? `${moduleMetadata?.navLink}`
                        : HOME_PATH
                })
            }
            setApplicationsModules(menuData.data)
            setMenu(navigation.sort((a, b) => a.order - b.order))
        }
    }, [menuData, menuHasError, menuIsLoading])

    return (
        <Layout
            menuData={menu}
            navbar={props => <CustomNavbar {...props} />}
            footer={<CustomFooter />}
            {...props}
        >
            <Outlet />
        </Layout>
    )
}

export default VerticalLayout
