import {
    JpModal,
    dateLocalizer,
    parseName,
    JpButton,
    JpAvatar,
    SweetModal
} from '@Intelli/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminAPI from '@src/services/API'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Badge, Col } from 'reactstrap'
import { downloadFile } from '@src/utility/Files'
import {
    faAngleLeft,
    faAngleRight,
    faDownload,
    faTrashCan
} from '@fortawesome/pro-solid-svg-icons'
import { useEffect, useState } from 'react'

const types = {
    PRODUCTIVE: { color: 'light-green', icon: 'person-digging' },
    UNPRODUCTIVE: { color: 'light-danger', icon: 'stopwatch' },
    NEUTRAL: { color: 'light-info', icon: 'person' }
}

const ScreenshotModal = ({
    filterForScreenshotModal,
    onClose,
    isOpen = false,
    editable = false,
    refetch = () => {},
    goToScreenshotsId,
    dataScreenshot,
    isScreenRequested
}) => {
    const [dataScreens, setDataScreens] = useState(dataScreenshot)
    const [screenshotId, setscreenshotId] = useState(dataScreens?.screenshot_id)

    const API = new AdminAPI()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const {
        data: screenshotsCarousel,
        isLoading: screenshotsCarouselIsLoading,
        refetch: screenshotsCarouselRefetch
    } = useQuery(
        ['screenshotsCarousel', screenshotId],
        () => API.screenshotsCarousel(filterForScreenshotModal, screenshotId),
        { enabled: !!screenshotId && isOpen && !isScreenRequested }
    )

    useEffect(() => {
        setDataScreens(dataScreenshot)
        setscreenshotId(dataScreenshot?.screenshot_id)
    }, [dataScreenshot, isOpen])

    const { mutate: deleteScreenshot } = useMutation(
        () => API.deleteScreenshot(dataScreens.screenshot_id),
        {
            onSuccess: () => {
                refetch()
                onClose()
            }
        }
    )
    const delScreenshot = screen => {
        SweetModal(
            'warning',
            t('views.productivity.deleteScreenshot'),
            t('views.productivity.deleteScreen'),
            t('Ok'),
            ({ isConfirmed }) => {
                if (isConfirmed) {
                    deleteScreenshot(screen || screenshot_id)
                }
            },
            { showCancelButton: true, cancelButtonText: t('CANCEL') }
        )
    }

    const handleNextScreenshot = () => {
        setDataScreens(screenshotsCarousel?.next)
        setscreenshotId(screenshotsCarousel?.next?.screenshot_id)
    }

    const handlePrevScreenshot = () => {
        setDataScreens(screenshotsCarousel?.last)
        setscreenshotId(screenshotsCarousel?.last?.screenshot_id)
    }

    return (
        <>
            <JpModal
                isOpen={isOpen}
                toggle={onClose}
                headerContent={<div></div>}
                bodyContent={
                    <div
                        className="d-flex justify-content-between align-items-center "
                        style={{ minHeight: '70vh' }}
                    >
                        <Col className="me-2 text-start">
                            {screenshotsCarousel?.last && (
                                <JpButton
                                    id="prev"
                                    type="add"
                                    tooltip
                                    tooltipTextCustom={t('modals.prevScreen')}
                                    className="px-1 rounded-circle pdfViewer__btn"
                                    options={{
                                        onClick: handlePrevScreenshot,
                                        textLess: true,
                                        iconPosition: 'center',
                                        customIcon: (
                                            <FontAwesomeIcon
                                                size="xl"
                                                icon={faAngleLeft}
                                            />
                                        )
                                    }}
                                />
                            )}
                        </Col>

                        <Col sm="10" className="overflow-hidden">
                            <div className="text-center rounded overflow-hidden ">
                                <img
                                    className="img-fluid"
                                    style={{ maxHeight: '70vh' }}
                                    src={dataScreens?.media_location}
                                />
                            </div>
                        </Col>

                        <Col className="ms-2 text-end">
                            {screenshotsCarousel?.next && (
                                <JpButton
                                    id="next"
                                    type="add"
                                    tooltip
                                    tooltipTextCustom={t('modals.nextScreen')}
                                    className="px-1 rounded-circle pdfViewer__btn"
                                    options={{
                                        onClick: handleNextScreenshot,
                                        textLess: true,
                                        iconPosition: 'center',
                                        customIcon: (
                                            <FontAwesomeIcon
                                                size="xl"
                                                icon={faAngleRight}
                                            />
                                        )
                                    }}
                                />
                            )}
                        </Col>
                    </div>
                }
                footerContent={
                    <div className="mt-1 d-flex justify-content-between align-items-center">
                        <div>
                            <div className="d-flex align-items-center gap-1">
                                {dataScreens?.app?.location ? (
                                    <JpAvatar
                                        color={`light-info`}
                                        img={dataScreens?.app?.location}
                                        imgClassName="avatar-cover"
                                    />
                                ) : (
                                    <JpAvatar
                                        color={`light-info`}
                                        content={
                                            dataScreens?.app_name
                                                ? `${dataScreens?.app_name}`
                                                : '-'
                                        }
                                        initials
                                    />
                                )}
                                <p className="m-0">{dataScreens?.app_name}</p>

                                <Badge
                                    color={types[dataScreens?.app_type]?.color}
                                    pill
                                >
                                    {t(
                                        `views.settings.productivity.${dataScreens?.app_type?.toLowerCase()}`
                                    )}
                                </Badge>
                            </div>
                            <div className="d-flex gap-1 mt-50">
                                <p className="m-0">
                                    {`${dateLocalizer.getIntlLongDate(
                                        dataScreens?.timestamp
                                    )} - ${dateLocalizer.getIntlShortTime(
                                        dataScreens?.timestamp
                                    )} `}
                                </p>
                                <p className="m-0">
                                    {parseName(
                                        dataScreens?.contractor_first_name,
                                        dataScreens?.contractor_last_name
                                    )}
                                </p>
                            </div>
                        </div>
                        <div className="d-flex gap-75">
                            {goToScreenshotsId && (
                                <>
                                    <JpButton
                                        id="add"
                                        type="add"
                                        tooltip
                                        tooltipTextCustom={t(
                                            'views.productivity.goToScreenshots'
                                        )}
                                        className="p-75"
                                        options={{
                                            onClick: () =>
                                                navigate(
                                                    `/admin/productivity/details/${goToScreenshotsId}?page=4`
                                                ),
                                            textLess: true,
                                            iconPosition: 'center',
                                            customIcon: (
                                                <FontAwesomeIcon
                                                    size="lg"
                                                    icon="fa-solid fa-image"
                                                />
                                            )
                                        }}
                                    />
                                </>
                            )}
                            {!editable ? (
                                <>
                                    <JpButton
                                        id="delete"
                                        type="delete"
                                        tooltip
                                        tooltipTextCustom={t(
                                            'views.productivity.deleteScreenshot'
                                        )}
                                        className="me-25 p-75"
                                        color="danger"
                                        options={{
                                            textLess: true,
                                            onClick: () =>
                                                delScreenshot(
                                                    dataScreens?.screenshot_id
                                                ),
                                            customIcon: (
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                    className="me-iconbtn"
                                                />
                                            )
                                        }}
                                    />
                                    <JpButton
                                        id="download"
                                        type="download"
                                        tooltip
                                        tooltipTextCustom={t(
                                            'views.productivity.downloadScreenshot'
                                        )}
                                        className="me-25 p-75"
                                        color="green"
                                        options={{
                                            textLess: true,
                                            onClick: () =>
                                                downloadFile(
                                                    dataScreens?.media_location,
                                                    `Joobpay-${dataScreens?.screenshot_id}`
                                                ),
                                            customIcon: (
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                    className="me-iconbtn"
                                                />
                                            )
                                        }}
                                    />
                                    <JpButton
                                        type="close"
                                        options={{
                                            iconless: true,
                                            onClick: onClose
                                        }}
                                    />
                                </>
                            ) : (
                                <div>
                                    <JpButton
                                        type="delete"
                                        options={{
                                            iconless: true,
                                            onClick: () => delScreenshot()
                                        }}
                                    />
                                    <JpButton type="save" className="ms-1" />
                                </div>
                            )}
                        </div>
                    </div>
                }
                options={{
                    footerOptions: {
                        className: 'd-block'
                    },
                    modalOptions: {
                        className: 'modal-xl',
                        centered: true
                    }
                }}
            />
        </>
    )
}

export default ScreenshotModal
