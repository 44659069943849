import { formatDatesUtc } from '@Intelli/utilities'
import moment from 'moment'

//Funcion echa para encontrar la fecha maxima y la fecha minima dentro del array timeline

export default function findMinMaxDates(data) {
    if (data.length === 0) return
    let minDate = new Date(data[0]?.timeline[0]?.start_datetime)
    let maxDate = new Date(data[0]?.timeline[0]?.start_datetime)

    let minHour = moment(data[0]?.timeline[0]?.start_datetime).format(
        'HH:mm:ss'
    )
    let maxHour = moment(data[0]?.timeline[0]?.start_datetime).format(
        'HH:mm:ss'
    )

    data.forEach(user => {
        user.timeline.forEach(item => {
            const startDate = new Date(item.start_datetime)
            const endDate = new Date(item.end_datetime)

            const startHour = moment(item.start_datetime).format('HH:mm:ss')

            const endHour = moment(item.end_datetime).format('HH:mm:ss')

            if (startDate < minDate) {
                minDate = startDate
            }

            if (endDate > maxDate) {
                maxDate = endDate
            }

            if (startHour < minHour) {
                minHour = startHour
            }

            if (endHour > maxHour) {
                maxHour = endHour
            }
        })
    })

    return {
        minDate: formatDatesUtc.getDateStringtoUTC(minDate),
        maxDate: formatDatesUtc.getDateStringtoUTC(maxDate),
        minHour,
        maxHour
    }
}
