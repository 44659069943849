import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useApplicationModules } from '@src/hooks/ApplicationModules.hook'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

//Note: To add params values to the breadcrumbs please use the addParamsValues action
//The addBreadcrumb action is deprecated and should not be used unless you are adding crumbs manually

const BreadcrumbsBar = ({ className }) => {
    const { path = [] } = useApplicationModules()
    const { t } = useTranslation()
    const params = useParams()
    const loc = useLocation()
    const breadcrumbs = useSelector(state => state.breadcrumbs)
    const routes = useSelector(state => state.adminRoutes)
    const { pathname } = loc
    const { id } = params

    const bc = []

    let curr_path = pathname

    //Get the params available in the current path
    const keys = Object.keys(params).map(key => `:${key}`)

    //Prepare the path for a initial match with the routes
    for (const key of keys) {
        curr_path = curr_path.replace(params[key.replace(':', '')], key)
    }

    const noShowCrum = ['profile']

    while (path.length > 0 && curr_path !== path[0].navLink) {
        //Find a route that matches the current path
        const route = routes.find(r => r === curr_path)

        //If a route is found, add it to the breadcrumbs
        if (route) {
            //If the route has a name, add it to the breadcrumbs
            if (!!route.name || path.map(p => p.navLink).includes(route)) {
                bc.push(path.find(p => p.navLink === route))
            } else {
                //If the route doesn't have a name, add the last part of the route to the breadcrumbs
                //unless it has a param, in which case add the value of the param and the name of the route

                if (
                    route
                        .substring(curr_path.lastIndexOf('/'), route.length)
                        .includes(':')
                ) {
                    //If the route has a param, add it to the breadcrumbs with the value of the param
                    // and the name of the route
                    if (
                        breadcrumbs?.params &&
                        breadcrumbs?.params[
                            route.substring(
                                curr_path.lastIndexOf('/') + 2,
                                route.length
                            )
                        ]?.value ===
                            params[
                                route.substring(
                                    curr_path.lastIndexOf('/') + 2,
                                    route.length
                                )
                            ]
                    ) {
                        //If the param has the same value as the param in the breadcrumbs, add it to the breadcrumbs
                        bc.push({
                            name: breadcrumbs?.params[
                                route.substring(
                                    curr_path.lastIndexOf('/') + 2,
                                    route.length
                                )
                            ].label,
                            last: true
                        })
                    }

                    //Get the crumb link with the params values
                    const crumb = route
                        .split('/')
                        .map(element => {
                            if (element.includes(':')) {
                                return params[element.replace(':', '')]
                            }
                            return element
                        })
                        .join('/')

                    //Add the crumb to the breadcrumbs

                    if (
                        !noShowCrum.includes(
                            route
                                .substring(0, curr_path.lastIndexOf('/'))
                                .split('/')
                                .pop()
                        )
                    ) {
                        bc.push({
                            name: `breadcrumbs.${route
                                .substring(0, curr_path.lastIndexOf('/'))
                                .split('/')
                                .pop()}`,
                            navLink: crumb
                        })
                    }
                } else {
                    //If the route doesn't have a param, add it to the breadcrumbs
                    bc.push({
                        name: `breadcrumbs.${route
                            .substring(
                                curr_path.lastIndexOf('/') + 1,
                                route.length
                            )
                            .split('/')
                            .pop()}`,
                        navLink: route.includes('id') ? '#' : route
                    })
                }
            }
        }

        //Remove the last part of the path
        curr_path = curr_path.substring(0, curr_path.lastIndexOf('/'))
    }

    //Reverse the breadcrumbs and add the first part of the path
    if (bc.length > 0 || path.length > 0) {
        bc.reverse()
        bc.unshift(path[0])
    }

    const target = (() => {
        const is_nested = path.some(available_route => {
            return pathname.includes(available_route.navLink)
        })

        if (id) {
            return breadcrumbs[id] || ''
        } else if (breadcrumbs[pathname.replace('/admin', '')]) {
            return breadcrumbs[pathname.replace('/admin', '')]
        } else if (is_nested) {
            return breadcrumbs[`/${pathname.split('/').pop()}`]
        }
    })()

    return (
        <div
            className={`content-header row align-content-center ps-0 ${className}`}
        >
            <div className="content-header-left ps-0">
                <div className="row breadcrumbs-top ">
                    <div className="breadcrumb-wrapper vs-breadcrumbs d-sm-block d-none col-12 p-0">
                        <Breadcrumb className="ps-0">
                            {bc?.map((item, index) => {
                                function isUpperCase(text) {
                                    return text === text.toUpperCase()
                                }
                                const classBread =
                                    !!item &&
                                    `text-primary ${
                                        isUpperCase(item?.name) &&
                                        'fw-bolder fs-4'
                                    }`
                                const Tag =
                                    item?.last ||
                                    item?.navLink === '#' ||
                                    item?.navLink?.includes('detail')
                                        ? 'span'
                                        : Link
                                return (
                                    <BreadcrumbItem
                                        key={index}
                                        className="d-flex aligns-items-center"
                                    >
                                        <Tag
                                            className={classBread}
                                            to={item?.navLink || '#'}
                                        >
                                            {t(item?.name, {
                                                ns: 'modules'
                                            })}
                                        </Tag>
                                    </BreadcrumbItem>
                                )
                            })}
                            {typeof target === 'object' ? (
                                target?.map((targ, index) => {
                                    const Tag = targ?.last ? 'span' : Link

                                    return (
                                        <BreadcrumbItem
                                            key={index}
                                            className="d-flex aligns-items-center"
                                        >
                                            <Tag
                                                className="text-primary"
                                                to={targ?.navLink || '#'}
                                            >
                                                {targ.last
                                                    ? targ.name
                                                    : t(
                                                          `breadcrumbs.${targ.name}`,
                                                          { ns: 'modules' }
                                                      )}
                                            </Tag>
                                        </BreadcrumbItem>
                                    )
                                })
                            ) : target ? (
                                bc.length > 0 ? (
                                    <BreadcrumbItem>
                                        <span className="text-primary">
                                            {t(target, { ns: 'modules' })}
                                        </span>
                                    </BreadcrumbItem>
                                ) : (
                                    <span className="text-primary">
                                        {t(target, { ns: 'modules' })}
                                    </span>
                                )
                            ) : null}
                        </Breadcrumb>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreadcrumbsBar
