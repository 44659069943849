import { useEffect, useState } from 'react'
import { HasPermissionModules } from '@Intelli/utilities'
import { useApplicationModules } from './ApplicationModules.hook'

const usePermissionsPayrolls = () => {
    const [hasWritePayroll, setHasWritePayroll] = useState(false)
    const [hasWriteHistoric, setHasWriteHistoric] = useState(false)

     const { modules } = useApplicationModules()

    useEffect(()=>{
        setHasWritePayroll(
            HasPermissionModules.WriteUpdate(modules, [
                'PAYROLL',
                'PAYROLL-PAYROLLS'
            ])
        )


        setHasWriteHistoric(
            HasPermissionModules.WriteUpdate(modules, [
                'PAYROLL',
                'PAYROLL-RECORDS'
            ])
        )
        
    },[])


    return {
        hasWritePayroll,
        hasWriteHistoric
    }
}

export default usePermissionsPayrolls
