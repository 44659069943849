import {
    Controller,
    FormProvider,
    useForm,
    useFormContext
} from 'react-hook-form'
import { Col, Form, FormFeedback, Input, Row } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import {
    ProfileHeader,
    SpinnerLoader,
    JpAvatarGroup,
    BlueHeaderCard,
    JpButton,
    dateLocalizer,
    JpCurrencyNumber,
    SweetModal,
    JpText,
    JpCountryFlag,
    JpLabel
} from '@Intelli/utilities'
import Avatar from '@src/@core/components/avatar'
import { parseName } from '@src/utility/Utils'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'

const InviteMessage = () => {
    const formCtx = useFormContext()
    const { t } = useTranslation()

    if (!formCtx) return <SpinnerLoader />

    const {
        control,
        getValues,
        watch,
        formState: { errors }
    } = formCtx

    return (
        <Form>
            <Controller
                name="message"
                control={control}
                render={({ field }) => (
                    <Input
                        type="textarea"
                        id="message"
                        invalid={errors.message && true}
                        rows="5"
                        placeholder={`${t('msgToContractorPlaceholder')}`}
                        style={{ maxHeight: '200px' }}
                        {...field}
                    />
                )}
            />
            <span
                className={`textarea-counter-value float-end ${
                    watch('message').length > 250 ? 'bg-warning' : 'bg-info'
                }`}
            >
                {`${getValues('message').length}/250`}
            </span>
            <FormFeedback invalid>
                {t(`form.contractors.${errors.message?.message}`, {
                    ns: 'errors'
                })}
            </FormFeedback>
        </Form>
    )
}

const Summary = ({ data, contractorData, stepper, type }) => {
    const { t } = useTranslation()
    const API = new AdminAPI()
    const navigate = useNavigate()
    const { id } = useParams()

    const {
        control,
        formState: { errors }
    } = useForm({})

    const { mutate: sendContract, isLoading: contractIsBeingSent } =
        useMutation(data => API.sendContract(data), {
            onSuccess: () => {
                SweetModal(
                    'success',
                    t('views.newContract.steps.summary.modals.title'),
                    t('views.newContract.steps.summary.modals.message'),
                    t('Ok'),
                    () => {
                        navigate('/admin/contractors?page=2')
                        localStorage.removeItem('temporalTemplate')
                    }
                )
            }
        })

    const messageSchema = yup.object().shape({
        message: yup
            .string()
            .max(250, 'MESSAGE.max')
            .required('MESSAGE.required')
    })

    const methods = useForm({
        defaultValues: {
            message: ''
        },
        resolver: yupResolver(messageSchema)
    })

    const onSubmit = data => {
        sendContract({ id, data })
    }

    const UserAvatar = () => {
        return contractorData?.photo ? (
            <Avatar
                img={contractorData?.photo?.photo}
                // content="a"
                size="xxl"
                imgHeight={'105px'}
                imgWidth={'105px'}
                color="light-primary"
                imgClassName="cover"
            />
        ) : (
            <Avatar
                content={parseName(
                    contractorData?.user?.first_name || contractorData?.email,
                    contractorData?.user?.last_name
                )}
                initials
                size="xxl"
                color="light-primary"
            />
        )
    }

    const ContractSummary = () => {
        return (
            <>
                <Row>
                    <Col md="4" sm="12">
                        <b>{t('views.newContract.steps.type.title')}</b>
                        <br />
                        {t(`views.settings.templates.types.${type?.name}`)}
                    </Col>
                    <Col md="4" sm="12">
                        <b>
                            {t(
                                'views.newContract.steps.contract.form.start_date'
                            )}
                        </b>
                        <br />
                        {dateLocalizer.getIntlLongDate(
                            data?.contractorMetadata?.start_date
                        )}
                    </Col>
                    <Col md="4" sm="12">
                        <b>
                            {t(
                                'views.newContract.steps.contract.form.end_date'
                            )}
                        </b>
                        <br />
                        {!!data?.contractorMetadata?.end_date
                            ? dateLocalizer.getIntlLongDate(
                                  data?.contractorMetadata?.end_date
                              )
                            : '-'}
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col md="4" sm="12">
                        <b>
                            {t(
                                'views.newContract.steps.contract.form.position'
                            )}
                        </b>
                        <br />
                        {data?.contractorMetadata?.position}
                    </Col>
                    <Col md="4" sm="12">
                        <b>
                            {t(
                                'views.newContract.steps.contract.form.payment_rule'
                            )}
                        </b>
                        <br />
                        {type?.name !== 'TASKS'
                            ? t(
                                  `views.newContract.steps.contract.form.payment_rules.${data?.contractorMetadata?.payment_rule_id?.label}`
                              )
                            : t(
                                  'views.newContract.steps.summary.payment_rule_tasks'
                              )}
                    </Col>
                    <Col md="4" sm="12">
                        <b>
                            {t('views.newContract.steps.contract.form.salary')}
                        </b>
                        <br />
                        <JpCurrencyNumber
                            value={data?.contractorMetadata?.salary}
                            time={type?.name === 'TIME'}
                        />
                    </Col>
                </Row>
                <Row className="mt-1">
                    <Col md="4" sm="12">
                        {data?.contractorMetadata?.teams_id.length > 0 ? (
                            <>
                                <b>
                                    {t(
                                        'views.newContract.steps.contract.form.team'
                                    )}
                                </b>
                                <JpAvatarGroup
                                    className="mt-1"
                                    data={data?.contractorMetadata?.teams_id
                                        ?.slice(0, 4)
                                        ?.map((team, index) => {
                                            const colors = [
                                                'light-warning',
                                                'light-success',
                                                'light-info',
                                                'light-primary'
                                            ]
                                            if (index === 3) {
                                                return {
                                                    content: `+${
                                                        data?.contractorMetadata
                                                            ?.teams_id - 3
                                                    }`,
                                                    id: `team-${team.value}`,
                                                    color: colors[index],
                                                    imgClassName: 'shadow-none'
                                                }
                                            }

                                            return {
                                                title: team.label,
                                                content: team.label,
                                                initials: true,
                                                color: colors[index],
                                                id: `team-${team.value}`,
                                                imgClassName: 'shadow-none'
                                            }
                                        })}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </Col>
                    <Col md="4" sm="12">
                        <JpLabel>
                            {`${t('views.settings.calendar.holidays')}`}
                        </JpLabel>
                        {data?.contractorMetadata?.holidays.value !==
                        'NOT_APPLY' ? (
                            <div className="d-flex align-items-center gap-1">
                                <JpCountryFlag
                                    countryCode={
                                        data?.contractorMetadata?.holidays
                                            ?.value
                                    }
                                />
                                <JpText
                                    type="p-text"
                                    children={`${data?.contractorMetadata?.holidays?.label}`}
                                />
                            </div>
                        ) : (
                            <JpText
                                type="p-text"
                                children={`${t('notApplicable')}`}
                            />
                        )}
                    </Col>
                </Row>
            </>
        )
    }

    // if (contractIsBeingSent) return <SpinnerLoader />

    return (
        <>
            <Row>
                <ProfileHeader
                    Avatar={<UserAvatar />}
                    Name={parseName(
                        contractorData?.user?.first_name ||
                            contractorData?.email,
                        contractorData?.user?.last_name
                    )}
                    Details={<small>{contractorData?.email}</small>}
                />
            </Row>
            <Row className="my-1">
                <Col>
                    <BlueHeaderCard
                        headerContent={
                            <h4 className="text-white m-0">
                                {t('views.newContract.steps.summary.title')}
                            </h4>
                        }
                        cardClassName="h-100"
                        bodyContent={<ContractSummary />}
                    />
                </Col>
                <Col>
                    <FormProvider {...methods}>
                        <BlueHeaderCard
                            headerContent={
                                <h4 className="text-white m-0">
                                    {t(
                                        'views.newContract.steps.summary.form.message'
                                    )}{' '}
                                    *
                                </h4>
                            }
                            cardClassName="h-100"
                            bodyContent={<InviteMessage />}
                        />
                    </FormProvider>
                </Col>
            </Row>
            <div className="d-flex justify-content-between mt-2">
                <JpButton
                    type="back"
                    options={{
                        onClick: () => stepper.previous(),
                        iconPosition: 'left'
                    }}
                />
                <JpButton
                    type="send"
                    id="send"
                    tooltip
                    tooltipTextCustom={t('sendContract')}
                    options={{
                        onClick: methods.handleSubmit(onSubmit)
                    }}
                    color="green"
                    loading={contractIsBeingSent}
                />
            </div>
        </>
    )
}

export default Summary
