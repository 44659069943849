import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap'
import { JpButtonGroup } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ProductivityDetailsMain from './productivityDetails/DetailsMain'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AppsDetail from './productivityDetails/AppsDetail'
import ScreenshotsDetail from './productivityDetails/ScreenshotsDetail'
import TimelineDetails from './productivityDetails/TimelineDetails'
import usePermissionsProductivity from '@src/hooks/usePermissionsProductivity'
import { useState } from 'react'

const ProductivityDetailsProfile = () => {
    const { t } = useTranslation()
    const permissionsProductivity = usePermissionsProductivity()

    const [currPage, setCurrPage] = useState(
        permissionsProductivity.searchFirstModuleActive
    )

    const { id } = useParams()

    const PageBody = () => {
        if (currPage === 1) {
            return <ProductivityDetailsMain contractorId={id} />
        } else if (currPage === 2) {
            return <AppsDetail />
        } else if (currPage === 3) {
            return <TimelineDetails />
        } else if (currPage === 4) {
            return (
                <ScreenshotsDetail
                    permissionWriteScreenshots={
                        permissionsProductivity.permissionWriteScreenshots
                    }
                />
            )
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <JpButtonGroup className="mb-1">
                        {permissionsProductivity.permissionDashboard && (
                            <>
                                <Button
                                    color="info"
                                    onClick={() => setCurrPage(1)}
                                    active={currPage === 1}
                                    outline={currPage !== 1}
                                    id="productivity"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-alarm-clock" />
                                </Button>
                                <UncontrolledTooltip
                                    target={'productivity'}
                                    placement="bottom"
                                >
                                    {t('Productivity')}
                                </UncontrolledTooltip>
                            </>
                        )}
                        {permissionsProductivity.permissionApps && (
                            <>
                                <Button
                                    color="info"
                                    onClick={() => setCurrPage(2)}
                                    active={currPage === 2}
                                    outline={currPage !== 2}
                                    id="apps"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-browser" />
                                </Button>
                                <UncontrolledTooltip
                                    target={'apps'}
                                    placement="bottom"
                                >
                                    {t('views.productivity.apps')}
                                </UncontrolledTooltip>
                            </>
                        )}
                        {permissionsProductivity.permissionTimeline && (
                            <>
                                <Button
                                    color="info"
                                    onClick={() => setCurrPage(3)}
                                    active={currPage === 3}
                                    outline={currPage !== 3}
                                    id="timeline"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-timeline" />
                                </Button>
                                <UncontrolledTooltip
                                    target={'timeline'}
                                    placement="bottom"
                                >
                                    {t('views.productivity.timeline')}
                                </UncontrolledTooltip>
                            </>
                        )}
                        {permissionsProductivity.permissionScreenshots && (
                            <>
                                <Button
                                    color="info"
                                    onClick={() => setCurrPage(4)}
                                    active={currPage === 4}
                                    outline={currPage !== 4}
                                    id="screenshots"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-image" />
                                </Button>
                                <UncontrolledTooltip
                                    target={'screenshots'}
                                    placement="bottom"
                                >
                                    {t('views.productivity.screenshots')}
                                </UncontrolledTooltip>
                            </>
                        )}
                    </JpButtonGroup>
                </Col>
            </Row>
            <PageBody />
        </div>
    )
}

export default ProductivityDetailsProfile
