import {
    JpTableV1,
    JpCurrencyNumber,
    JpAvatarGroup,
    dateLocalizer,
    JpText,
    JpDatePicker,
    parseMinutesToHours,
    SpinnerLoader,
    percentage,
    JpTableTitleName,
    JpAvatar
} from '@Intelli/utilities'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'
import moment from 'moment-timezone'
import { useQuery } from '@tanstack/react-query'
import AdminAPI from '@src/services/API'
import ProductivityGraphs from './ProductivityGraphs'
import useSocket from '@src/hooks/useSocket'
import disabledRefetchSocket from '@src/utility/disabledRefetchSocket'
import hoursStates from '@src/constants/states/hours/hoursState'
import { useDispatch } from 'react-redux'
import { addBreadcrumbs } from '@src/redux/breadcrumbs'
import { parseName } from '@src/utility/Utils'

const TabActions = ({
    start_date,
    end_date,
    setEndDate,
    setStartDate,
    setCurrentPage
}) => {
    return (
        <Row className="align-items-center justify-content-start">
            <Col
                sm="4"
                md="2"
                lg="2"
                xl="2"
                className="d-none d-xl-flex table-filters px-0 ms-1"
            >
                <JpDatePicker
                    id="prodStartDate"
                    value={start_date}
                    setPicker={date => {
                        setCurrentPage(0)
                        setStartDate(date.format('YYYY-MM-DD'))
                    }}
                    className={'enabled-joobpay'}
                    maxDate={moment().format('YYYY-MM-DD')}
                />
                <JpDatePicker
                    id="prodEndDate"
                    value={end_date}
                    minDate={moment(start_date).format('YYYY-MM-DD')}
                    maxDate={moment().format('YYYY-MM-DD')}
                    setPicker={date => {
                        setCurrentPage(0)
                        setEndDate(date.format('YYYY-MM-DD'))
                    }}
                    className={'enabled-joobpay ms-1'}
                />
            </Col>
        </Row>
    )
}

const ProductivityVsTimeDays = () => {
    const [start_date, setStartDate] = useState(
        moment().subtract(15, 'day').format('YYYY-MM-DD')
    )
    const [end_date, setEndDate] = useState(moment().format('YYYY-MM-DD'))
    const [currentPage, setCurrentPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [queries, setQueries] = useState('')
    const [sortBy, setSortBy] = useState('date__desc')
    const [isOpen, setIsOpen] = useState(false)

    const API = new AdminAPI()
    const { t } = useTranslation()
    const { id } = useParams()
    const dispatch = useDispatch()

    const { data: dataProfile } = useQuery(
        ['profile', id],
        () => API.getContractor(id),
        {
            onSuccess: data => {
                dispatch(
                    addBreadcrumbs({
                        id,
                        value: parseName(
                            data.user.first_name || data.email,
                            data.user.last_name
                        )
                    })
                )
            }
        }
    )

    const {
        data: productivityData,
        isLoading: productivityIsLoading,
        refetch: productivityDataRefetch
    } = useQuery(
        [
            'productivity-data-days',
            currentPage,
            limit,
            start_date,
            end_date,
            queries,
            sortBy,
            isOpen,
            id
        ],
        () =>
            API.getHours({
                offset: currentPage,
                limit,
                date__between: `${start_date},${end_date}`,
                query: queries,
                order: sortBy,
                has_productivity: true,
                query: queries,
                contractor_id: id
            }),
        {
            keepPreviousData: true
        }
    )

    useSocket(
        disabledRefetchSocket(end_date)
            ? {
                  [`/time/contractors/${id}/workday`]: {
                      fn: value => {
                          productivityDataRefetch()
                      }
                  }
              }
            : {},
        [end_date]
    )

    const tabCols = [
        {
            sortable: false,
            width: '20px',
            cell: row => (
                <div className="d-flex align-items-end">
                    <JpAvatar
                        color={`light-primary`}
                        content={row?.date && `${moment(row?.date).date()}`}
                        imgClassName="avatar-cover cursor-default"
                    />
                </div>
            )
        },
        {
            name: `${t('views.documents.documentsTable.date')}`,
            sortable: 'date',
            minWidth: '120px',
            cell: row => (
                <div className="d-flex align-items-center justify-content-center user-info">
                    {row?.date ? (
                        <Link
                            to={`/admin/time/productivity/${row?.contractor?.id}/days/details?date=${row?.date}`}
                            className="d-block fw-bold text-truncate "
                        >
                            {dateLocalizer.getIntlLongDate(row?.date)}
                        </Link>
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            name: `${t('views.time.firstMarking')}`,
            sortable: 'clock_in',
            minWidth: '120px',
            cell: row => (
                <div className="d-flex align-items-center justify-content-center">
                    {row?.clock_in ? (
                        <JpText
                            type="span-table-date"
                            children={dateLocalizer.getIntlShortTime(
                                row?.clock_in
                            )}
                        />
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            name: t('views.time.lastMarking'),
            sortable: 'clock_out',
            cell: row => (
                <div className="d-flex align-items-center justify-content-center">
                    {!!row?.clock_out ? (
                        <JpText
                            type="span-table-date"
                            children={dateLocalizer.getIntlShortTime(
                                row?.clock_out
                            )}
                        />
                    ) : (
                        '-'
                    )}
                </div>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('hours')}
                    tooltipText={t('views.time.graphs.hoursWorked')}
                />
            ),
            sortable: 'work_time',
            cell: row => (
                <>
                    <JpText
                        type="span-table-date"
                        children={
                            row?.work_time
                                ? parseMinutesToHours(row?.work_time)
                                : '-'
                        }
                    />
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.unproductive')}
                    tooltipText={t('views.time.unproductiveTime')}
                />
            ),
            sortable: false,
            cell: row => (
                <>
                    <JpText
                        type="span-table-date"
                        id={`unproductive-${row?.contractor_day_id}`}
                        children={
                            row?.unproductive_time
                                ? `${parseMinutesToHours(
                                      row?.unproductive_time
                                  )}`
                                : '-'
                        }
                    />
                    {/* <UncontrolledTooltip
                        target={`unproductive-${row?.contractor_day_id}`}
                    >
                        {percentage(row?.work_time, row?.unproductive_time)}%
                    </UncontrolledTooltip> */}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('effective')}
                    tooltipText={t('views.time.effectiveHours')}
                />
            ),
            sortable: false,
            cell: row => (
                <>
                    <JpText
                        type="span-table-date"
                        id={`effective-${row?.clock_in_id}`}
                        children={
                            row?.effective_time
                                ? `${parseMinutesToHours(row?.effective_time)}`
                                : '-'
                        }
                    />
                    {/* <UncontrolledTooltip
                        target={`effective-${row?.clock_in_id}`}
                    >
                        {percentage(row?.work_time, row?.effective_time)}%
                    </UncontrolledTooltip> */}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.payments.unpaid.totalAmount')}
                    tooltipText={t('views.payments.detail.valueToPay')}
                />
            ),
            minWidth: '170px',
            sortable: 'effective_fee',
            cell: row => (
                <>
                    {row?.effective_fee &&
                    ['CLASSIC', 'TIME'].includes(row?.contractor?.contract_type
                        ?.name) ? (
                        <>
                            <JpCurrencyNumber value={row?.effective_fee} />
                        </>
                    ) : (
                        '-'
                    )}
                </>
            )
        },
        {
            name: (
                <JpTableTitleName
                    text={t('views.productivity.state')}
                    tooltipText={t('views.time.workStatus')}
                />
            ),
            sortable: false,
            cell: (row, indexRow) => {
                return (
                    <>
                        {row?.state?.length === 0 && '-'}

                        <JpAvatarGroup
                            key={row?.contractor_day_id}
                            data={row?.state
                                ?.slice(0, 4)
                                .sort((a, b) => b.priority - a.priority)
                                .map((state, index) => {
                                    const color =
                                        hoursStates[state?.value].color
                                    const icon =
                                        hoursStates[state?.value].iconAvatar
                                    if (index === 3) {
                                        return {
                                            content: `+${
                                                row?.state?.length - 3
                                            }`,
                                            id: `state-${indexRow}-${index}`,
                                            color: color,
                                            imgClassName: 'shadow-none'
                                        }
                                    }
                                    return {
                                        title: t(
                                            `views.time.states.${state?.value}`
                                        ),
                                        icon: <i className={icon}></i>,
                                        color: color,
                                        id: `state-${indexRow}-${index}`,
                                        imgClassName: 'shadow-none'
                                    }
                                })}
                        />
                    </>
                )
            }
        }
    ]

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    if (productivityIsLoading) return <SpinnerLoader />

    return (
        <>
            <ProductivityGraphs start_date={start_date} end_date={end_date} />
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={productivityData?.data}
                total={productivityData?.count}
                loading={productivityIsLoading}
                offset={currentPage}
                search={false}
                actions={
                    <TabActions
                        start_date={start_date}
                        setStartDate={setStartDate}
                        setCurrentPage={setCurrentPage}
                        end_date={end_date}
                        setEndDate={setEndDate}
                    />
                }
            />
        </>
    )
}

export default ProductivityVsTimeDays
