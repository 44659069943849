import React, { useState } from 'react'
import {
    BlueHeaderCard,
    JpCurrencyNumber,
    JpText,
    dateLocalizer,
    JpTableV1,
    JpAvatar,
    SpinnerLoader,
    JpButton
} from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { Badge, Col, Row } from 'reactstrap'
import { useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import AdminAPI from '@src/services/API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar } from '@fortawesome/pro-solid-svg-icons'
import { downloadFile } from '@src/utility/Files'
import { useSelector } from 'react-redux'
import usePermissionsInvoices from '@src/hooks/usePermissionsInvoices'

export const statesDispersion = {
    PAID: {
        color: 'success'
    },
    PART_PAID: {
        color: 'yellow'
    },
    UNPAID: {
        color: 'danger'
    },
    RETURNED: {
        color: 'purple'
    }
}

const InvoicesDetail = () => {
    const { t } = useTranslation()
    const API = new AdminAPI()
    const { id } = useParams()
    const navigate = useNavigate()
    const { userAdmin } = useSelector(state => state.backofficeUser)
    const { hasWritePermission } = usePermissionsInvoices()
    const [currentPage, setCurrentPage] = useState(0)
    const [limit, setLimit] = useState(10)
    const [queries, setQueries] = useState('')
    const [sortBy, setSortBy] = useState('name__desc')

    const {
        data: invoicesHeaderDetail,
        isError: invoicesHeaderDetailHasError,
        isLoading: invoicesHeaderDetailIsLoading
    } = useQuery(['invoicesHeaderDetail', id], () =>
        API.getInvoicesDetailHeader(id)
    )

    const {
        data: invoicesDetailTable,
        isError: invoicesDetailTableHasError,
        isLoading: invoicesDetailTableIsLoading
    } = useQuery(
        ['invoicesDetailTable', id, currentPage, queries, limit, sortBy],
        () =>
            API.getInvoicesDetailTable({
                query: queries,
                offset: currentPage,
                limit,
                order: sortBy,
                invoice_id: id
            }),
        { keepPreviousData: true }
    )

    const handlePageChange = ({ page, limit, query, sort }) => {
        setCurrentPage(page)
        setLimit(limit)
        setQueries(query)
        if (sort) setSortBy(sort)
    }

    const showActionPay = () => {
        return (
            invoicesHeaderDetail?.status !== 'PAID' &&
            hasWritePermission &&
            userAdmin.legal_representative?.verified === 'VERIFIED'
        )
    }

    const TabActions = () => {
        return (
            <>
                {showActionPay() && (
                    <JpButton
                        type="pay"
                        options={{ onClick: () => navigate('payable') }}
                    />
                )}
                <JpButton
                    className="ms-1"
                    type="download"
                    options={{
                        onClick: () =>
                            downloadFile(
                                invoicesHeaderDetail?.location?.location,
                                invoicesHeaderDetail?.location?.name
                            )
                    }}
                />
            </>
        )
    }

    const stateColors = {
        SUCCESS: {
            color: 'success'
        },
        PENDING: {
            color: 'yellow'
        },
        REJECTED: {
            color: 'danger'
        },
        RETURNED: {
            color: 'purple'
        },
        REQUIRES_ACTION: {
            color: 'light-yellow'
        }
    }

    const tabCols = [
        {
            id: 'avatar_column',
            name: '',
            sortable: false,
            compact: true,
            right: true,
            maxWidth: '50px',
            minWidth: '20px',
            cell: row => (
                <JpAvatar
                    icon={<FontAwesomeIcon icon={faFileInvoiceDollar} />}
                    color={stateColors[row?.state]?.color}
                />
            )
        },
        {
            name: t('views.invoices.service'),
            sortable: 'name',
            minWidth: '200px',
            compact: true,
            cell: row => {
                return (
                    <p className="m-0">
                        {row?.name}
                        {row?.code && (
                            <span className="ms-50">{row?.code}</span>
                        )}
                    </p>
                )
            }
        },
        {
            name: t('views.invoices.date'),
            sortable: 'date',
            allowOverflow: false,
            wrap: true,
            cell: row => {
                return (
                    <>
                        {row?.date ? (
                            <JpText type={'span-table-date'}>
                                {dateLocalizer.getIntlLongDate(
                                    row?.date
                                )}
                            </JpText>
                        ) : (
                            '-'
                        )}
                    </>
                )
            }
        },
        {
            name: t('views.invoices.count'),
            sortable: 'count',
            allowOverflow: false,
            wrap: true,
            cell: row => (
                <JpText className={'ms-1'} type={'span-table-text'}>
                    {row?.count.toString()}
                </JpText>
            )
        },
        {
            name: t('views.invoices.unitValue'),
            sortable: 'unit_value',
            cell: row => {
                return (
                    <>
                        {row?.unit_value ? (
                            <JpCurrencyNumber value={row?.unit_value} />
                        ) : (
                            '-'
                        )}
                    </>
                )
            }
        },
        {
            name: t('views.invoices.totalValue'),
            sortable: 'total_value',
            wrap: false,
            cell: row =>
                row?.total_value ? (
                    <JpCurrencyNumber value={row?.total_value} />
                ) : (
                    '-'
                )
        }
    ]

    if (invoicesHeaderDetailHasError || invoicesHeaderDetailIsLoading) {
        return <SpinnerLoader />
    }

    return (
        <>
            <BlueHeaderCard
                headerContent={
                    <h4 className="text-white m-0">
                        {t('views.invoices.invoicedDetail')}
                    </h4>
                }
                bodyClassName=""
                bodyContent={
                    <Row>
                        <Col sm="4">
                            <Row className="mb-50">
                                <Col>
                                    <b className="fw-bolder text-primary me-75">
                                        {t('views.invoices.invoice')}:
                                    </b>
                                </Col>
                                <Col>
                                    <JpText
                                        type={'span-table-date'}
                                        children={`#${invoicesHeaderDetail?.number}`}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-50">
                                <Col>
                                    <b className="fw-bolder text-primary me-75">
                                        {t('views.invoices.state')}:
                                    </b>
                                </Col>
                                <Col>
                                    <Badge
                                        className="text-capitalize"
                                        color={
                                            statesDispersion[
                                                invoicesHeaderDetail?.status
                                            ]?.color
                                        }
                                    >
                                        {t(
                                            `views.invoices.${invoicesHeaderDetail?.status}`
                                        )}
                                    </Badge>
                                </Col>
                            </Row>

                           
                        </Col>
                        <Col sm="4">
                            <Row className="mb-50">
                                <Col>
                                    <b className="fw-bolder text-primary me-75">
                                        {t('views.invoices.amount')}:
                                    </b>
                                </Col>
                                <Col>
                                    <JpCurrencyNumber
                                        value={
                                            invoicesHeaderDetail?.total_amount
                                        }
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-50">
                                <Col>
                                    <b className="fw-bolder text-primary me-75">
                                        {t('views.invoices.balance')}:
                                    </b>
                                </Col>
                                <Col>
                                    <JpCurrencyNumber
                                        value={
                                            invoicesHeaderDetail?.balance_payable
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            />
            <JpTableV1
                cols={tabCols}
                fetcher={handlePageChange}
                data={invoicesDetailTable?.data}
                total={invoicesDetailTable?.count}
                loading={
                    invoicesDetailTableHasError || invoicesDetailTableIsLoading
                }
                actions={<TabActions />}
                tableClassName="mt-1"
                options={{
                    actionsClassName: 'text-end me-1 mt-1 mt-md-0'
                }}
            />
        </>
    )
}

export default InvoicesDetail
