import { useEffect, useState } from 'react'
import { Row, Col, Button, UncontrolledTooltip } from 'reactstrap'
import { JpButtonGroup } from '@Intelli/utilities'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PersonProjectsTable from './PersonProjectsTable'
import PersonProjectsProductivity from './PersonProjectsProductivity'
import PersonProjectsTime from './PersonProjectsTime'
import { useQuery } from '@tanstack/react-query'
import { addParamsValues } from '@src/redux/breadcrumbs'
import { useDispatch } from 'react-redux'
import { parseName } from '@src/utility/Utils'
import AdminAPI from '@src/services/API'
import { projectIcon } from '@src/constants/icons/projectIcon'

const PersonProjectsTabs = () => {
    const [params, setSearchParams] = useSearchParams()
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(
        params.get('projectPage') ? parseInt(params.get('projectPage')) : 1
    )
    const isProfile = window.location.href.includes('profile')

    const API = new AdminAPI()
    const { contractor_id, id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if (params.get('projectPage')) {
            setCurrentPage(parseInt(params.get('projectPage')))
        }
    }, [params])

    const { data: dataProfile, isLoading } = useQuery(
        ['profileData', contractor_id, id],
        () => API.getContractor(contractor_id || id),
        {
            onSuccess: data => {
                dispatch(
                    addParamsValues({
                        contractor_id: {
                            value: contractor_id,
                            label: parseName(
                                data.user.first_name || data.email,
                                data.user.last_name
                            )
                        }
                    })
                )
            },
        }
    )

    if(isLoading) return <></>

    const PageBody = () => {
        if (currentPage === 1) {
            return <PersonProjectsTable />
        }
        if (currentPage === 2) {
            return <PersonProjectsTime />
        }

        if (currentPage === 3) {
            return <PersonProjectsProductivity />
        }
    }

    const onTabChange = index => {
        setSearchParams(prev => {
            return {
                ...(prev.get('page') ? { page: prev.get('page') } : {}),
                projectPage: index
            }
        })
    }

    return (
        <div>
            <Row>
                <Col>
                    <JpButtonGroup className="mb-2">
                        <Button
                            className="d-flex align-items-center"
                            color="info"
                            onClick={() => onTabChange(1)}
                            active={currentPage === 1}
                            outline={currentPage !== 1}
                            id="projects_tab"
                        >
                            <FontAwesomeIcon
                                className="m-0"
                                icon={projectIcon}
                            />
                            {!isProfile && (
                                <span className="ms-50">{t('Projects')}</span>
                            )}
                            {isProfile && (
                                <UncontrolledTooltip
                                    target={'projects_tab'}
                                    placement="bottom"
                                >
                                    {t('Projects')}
                                </UncontrolledTooltip>
                            )}
                        </Button>

                        {dataProfile?.time?.active && <Button
                            className="d-flex align-items-center"
                            color="info"
                            onClick={() => onTabChange(2)}
                            active={currentPage === 2}
                            outline={currentPage !== 2}
                            id="hours_tab"
                        >
                            <FontAwesomeIcon
                                className="m-0"
                                icon="fa-solid fa-clock-three"
                            />
                            {!isProfile && (
                                <span className="ms-50">{t('hours')}</span>
                            )}
                            {isProfile && (
                                <UncontrolledTooltip
                                    target={'hours_tab'}
                                    placement="bottom"
                                >
                                    {t('hours')}
                                </UncontrolledTooltip>
                            )}
                        </Button>}
                       {dataProfile?.productivity?.active && <Button
                            className="d-flex align-items-center"
                            id="productivity_tab"
                            color="info"
                            onClick={() => {
                                if (currentPage !== 3) {
                                    onTabChange(3)
                                }
                            }}
                            active={currentPage === 3}
                            outline={currentPage !== 3}
                        >
                            <FontAwesomeIcon
                                className="m-0"
                                icon="fa-solid fa-arrow-trend-up"
                            />
                            {!isProfile && (
                                <span className="ms-50">
                                    {t('Productivity')}
                                </span>
                            )}
                            {isProfile && (
                                <UncontrolledTooltip
                                    target={'productivity_tab'}
                                    placement="bottom"
                                >
                                    {t('Productivity')}
                                </UncontrolledTooltip>
                            )}
                        </Button>}
                    </JpButtonGroup>
                </Col>
            </Row>
            <PageBody />
        </div>
    )
}

export default PersonProjectsTabs
