import {
    JpText,
    dateLocalizer,
    JpAvatar,
    SpinnerLoader,
    JpCurrencyNumber
} from '@Intelli/utilities'
import useSocket from '@src/hooks/useSocket'
import AdminAPI from '@src/services/API'
import { colorPayrolls } from '@src/views/payrolls/Historical'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Col, Row, UncontrolledTooltip } from 'reactstrap'

const DetailsPayrolls = () => {
    const { t } = useTranslation()

    const API = new AdminAPI()
    const { id } = useParams()

    const {
        data: getPaidPayrollsDetails,
        isError: getPaidPayrollsDetailsHasError,
        isLoading: getPaidPayrollsDetailsIsLoading,
        refetch: getPaidPayrollsDetailsRefetch
    } = useQuery(['getPaidPayrollsDetails'], () =>
        API.getPaidPayrollsDetails(id)
    )

    useSocket({
        [`/payroll/transactions/${id}`]: {
            fn: value => {
                getPaidPayrollsDetailsRefetch()
            }
        }
    })

    if (getPaidPayrollsDetailsIsLoading) return <SpinnerLoader />
    return (
        <Row>
            <Col xs="12" md={3}>
                <div className="d-flex justify-content-between ">
                    <b>{t('views.historicalPayments.paidUsers') + ':'}</b>
                    <JpText type="span-table-text">{`${getPaidPayrollsDetails?.count_users_paid}`}</JpText>
                </div>

                <div className="d-flex justify-content-between ">
                    <b className="me-75">
                        {t('views.historicalPayments.transaction') + ':'}
                    </b>
                    <JpText type="span-table-text">
                        {`${getPaidPayrollsDetails?.name}`}
                    </JpText>
                </div>

                <div className="d-flex justify-content-between ">
                    <b>{t('views.historicalPayments.paymentDate') + ':'}</b>
                    <JpText type="span-table-text">
                        {dateLocalizer.getIntlLongDate(
                            getPaidPayrollsDetails?.created_at
                        )}
                    </JpText>
                </div>
            </Col>

            <Col xs="12" md={3} className="ps-md-5">
                <div className="d-flex justify-content-between">
                    <b>{t('views.historicalPayments.value') + ':'}</b>
                    <p className="m-0">
                        <JpCurrencyNumber
                            value={getPaidPayrollsDetails?.payroll_balance}
                        />
                    </p>
                </div>
                <div className="d-flex justify-content-between">
                    <b>{t('views.historicalPayments.comission') + ':'}</b>
                    <p className="m-0">
                        <JpCurrencyNumber
                            value={getPaidPayrollsDetails?.commission_balance}
                        />
                    </p>
                </div>
                <div className="d-flex justify-content-between">
                    <b>{t('views.historicalPayments.totalValue') + ':'}</b>
                    <p className="m-0">
                        <JpCurrencyNumber
                            value={getPaidPayrollsDetails?.balance}
                        />
                    </p>
                </div>
            </Col>
            <Col xs="12" md={3} className="ps-md-5">
                <div className="d-flex justify-content-between">
                    <b>{t('views.historicalPayments.method') + ':'}</b>

                    {getPaidPayrollsDetails?.payment_method ? (
                        <>
                            <JpText  type="span-table-text">
                                {`${getPaidPayrollsDetails?.payment_method}`}
                            </JpText>

                        </>
                    ) : (
                        '-'
                    )}
                </div>
            </Col>

            <Col xs="12" md={3} className="d-flex justify-content-md-end ">
                <div className="d-flex align-items-center">
                    <b>
                        {t(
                            `views.historicalPayments.${getPaidPayrollsDetails.state}`
                        )}
                    </b>
                    <div>
                        <JpAvatar
                            size="lg"
                            className="ms-1"
                            icon={
                                colorPayrolls[getPaidPayrollsDetails?.state]
                                    ?.icon
                            }
                            color={
                                colorPayrolls[getPaidPayrollsDetails?.state]
                                    ?.color
                            }
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default DetailsPayrolls
